import React, { FC, useEffect, useState } from "react";
import { TermsWrapper, TextWrapper } from "./Terms.styled";
import { StructuredText } from "react-datocms";
import { getTermsData } from "cms/helpers";

interface Terms {
  value: any;
}

const Terms: FC = () => {
  const [text, setText] = useState<Terms | null>(null);
  useEffect(() => {
    const getTerms = async () => {
      let response = await getTermsData();
      setText(response.term.text);
    };
    getTerms();
  }, []);
  return (
    <TermsWrapper>
      <TextWrapper>
        <StructuredText data={text?.value} />
      </TextWrapper>
    </TermsWrapper>
  );
};

export default Terms;
