import React from "react"
import { MarketingSectionLabelWrapper } from "./MarketingSectionLabel.styled"
type Props = {
  text: string
  fontSize?: string
  fontWeight?: number
}

const MarketingSectionLabel = (props: Props) => {
  return (
    <MarketingSectionLabelWrapper
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
    >
      {props.text}
    </MarketingSectionLabelWrapper>
  )
}

export default MarketingSectionLabel
