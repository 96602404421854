import React, { FC, useEffect, useRef } from "react";
import {
  SelectedPriority,
  DropdownContainer,
  OptionsContainer,
  Option,
} from "./PriorityDropdown.styled";
import { useState } from "react";
import { Priority } from "types";

// Props interface for the component
interface PriorityDropdownProps {
  initialPriority: Priority;
  onPriorityChange: (newPriority: Priority) => void;
}
// Add this near the top of your file, outside the component

const PriorityDropdown: FC<PriorityDropdownProps> = ({
  initialPriority,
  onPriorityChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [priority, setPriority] = useState<Priority>(initialPriority);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handlePriorityChange = (newPriority: Priority) => {
    setPriority(newPriority);
    onPriorityChange(newPriority);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mouseover", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseover", handleMouseLeave);
    };
  }, []);

  return (
    <DropdownContainer className="priority-dropdown" ref={dropdownRef}>
      <SelectedPriority onClick={() => setIsOpen(!isOpen)}>
        {priority}
      </SelectedPriority>
      <OptionsContainer isOpen={isOpen}>
        {["High", "Medium", "Low"].map((option) => (
          <Option
            priority={option}
            key={option}
            onClick={() => handlePriorityChange(option as Priority)}
          >
            {option}
          </Option>
        ))}
      </OptionsContainer>
    </DropdownContainer>
  );
};

export default PriorityDropdown;
