import React from "react";

const Keyboard = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.625 25.9375H9.375C8.4375 25.9375 7.75 25.9 7.1625 25.8125C3.0125 25.3625 2.1875 22.875 2.1875 18.75V11.25C2.1875 7.125 3.0125 4.6375 7.2 4.175C7.75 4.1 8.4375 4.0625 9.375 4.0625H20.625C21.5625 4.0625 22.25 4.1 22.8375 4.1875C26.9875 4.6375 27.8125 7.125 27.8125 11.25V18.75C27.8125 22.875 26.9875 25.3625 22.8 25.825C22.25 25.9 21.5625 25.9375 20.625 25.9375ZM9.375 5.9375C8.525 5.9375 7.925 5.975 7.4375 6.0375C4.9 6.325 4.0625 7.1125 4.0625 11.25V18.75C4.0625 22.8875 4.9 23.675 7.4 23.9625C7.925 24.0375 8.525 24.0625 9.375 24.0625H20.625C21.475 24.0625 22.075 24.025 22.5625 23.9625C25.1 23.6875 25.9375 22.8875 25.9375 18.75V11.25C25.9375 7.1125 25.1 6.325 22.6 6.0375C22.075 5.9625 21.475 5.9375 20.625 5.9375H9.375Z"
      fill="#EEF7F2"
    />
    <path
      d="M21.25 13.4375H16.875C16.3625 13.4375 15.9375 13.0125 15.9375 12.5C15.9375 11.9875 16.3625 11.5625 16.875 11.5625H21.25C21.7625 11.5625 22.1875 11.9875 22.1875 12.5C22.1875 13.0125 21.7625 13.4375 21.25 13.4375Z"
      fill="#EEF7F2"
    />
    <path
      d="M12.625 13.75C11.9375 13.75 11.375 13.1875 11.375 12.5C11.375 11.8125 11.925 11.25 12.625 11.25H12.6375C13.325 11.25 13.8875 11.8125 13.8875 12.5C13.8875 13.1875 13.325 13.75 12.625 13.75Z"
      fill="#EEF7F2"
    />
    <path
      d="M8.875 13.75C8.1875 13.75 7.625 13.1875 7.625 12.5C7.625 11.8125 8.175 11.25 8.875 11.25C9.5625 11.25 10.125 11.8125 10.125 12.5C10.125 13.1875 9.575 13.75 8.875 13.75Z"
      fill="#EEF7F2"
    />
    <path
      d="M21.25 20.3125H8.77495C8.26245 20.3125 7.82495 19.8875 7.82495 19.375C7.82495 18.8625 8.23745 18.4375 8.74995 18.4375H21.25C21.7625 18.4375 22.1875 18.8625 22.1875 19.375C22.1875 19.8875 21.7625 20.3125 21.25 20.3125Z"
      fill="#EEF7F2"
    />
  </svg>
);

export default Keyboard;
