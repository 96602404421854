import React from "react";

const Chalice = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1875 24.1875C14.675 24.1875 14.25 23.7625 14.25 23.25V20.625C14.25 20.1125 14.675 19.6875 15.1875 19.6875C15.7 19.6875 16.125 20.1125 16.125 20.625V23.25C16.125 23.7625 15.7 24.1875 15.1875 24.1875Z"
      fill="#EEF7F2"
    />
    <path
      d="M22.375 28.4375H8V26.25C8 24.35 9.5375 22.8125 11.4375 22.8125H18.9375C20.8375 22.8125 22.375 24.35 22.375 26.25V28.4375ZM9.875 26.5625H20.5V26.25C20.5 25.3875 19.8 24.6875 18.9375 24.6875H11.4375C10.575 24.6875 9.875 25.3875 9.875 26.25V26.5625Z"
      fill="#EEF7F2"
    />
    <path
      d="M22.6875 28.4375H7.6875C7.175 28.4375 6.75 28.0125 6.75 27.5C6.75 26.9875 7.175 26.5625 7.6875 26.5625H22.6875C23.2 26.5625 23.625 26.9875 23.625 27.5C23.625 28.0125 23.2 28.4375 22.6875 28.4375Z"
      fill="#EEF7F2"
    />
    <path
      d="M23.0374 15.5499C22.7749 15.5499 22.5124 15.4375 22.3249 15.225C22.0874 14.95 22.0249 14.5625 22.1749 14.2375C22.5999 13.2625 22.8124 12.225 22.8124 11.1375V7.38745C22.8124 6.94995 22.7374 6.52495 22.5874 6.07495C22.5749 6.03745 22.5624 5.98745 22.5499 5.93745C22.5124 5.74995 22.4999 5.56245 22.4999 5.38745C22.4999 4.87495 22.9249 4.44995 23.4374 4.44995H24.1874C26.4249 4.44995 28.2499 6.32495 28.2499 8.63745C28.2499 10.55 27.4624 12.4375 26.0999 13.8C26.0749 13.825 25.9999 13.8875 25.9874 13.9C25.2499 14.5125 24.4124 15.2 23.2874 15.5125C23.1999 15.5375 23.1249 15.5499 23.0374 15.5499ZM24.5999 6.36245C24.6624 6.69995 24.6874 7.04995 24.6874 7.38745V11.1375C24.6874 11.65 24.6499 12.1375 24.5749 12.6375C24.6499 12.575 24.7124 12.525 24.7874 12.4625C25.7874 11.4625 26.3749 10.0625 26.3749 8.63745C26.3749 7.51245 25.6124 6.56245 24.5999 6.36245Z"
      fill="#EEF7F2"
    />
    <path
      d="M6.975 15.5C6.875 15.5 6.7875 15.4875 6.6875 15.45C5.6625 15.125 4.7 14.525 3.9 13.725C2.4625 12.1375 1.75 10.4 1.75 8.5625C1.75 6.2875 3.5375 4.5 5.8125 4.5H6.625C6.9375 4.5 7.2375 4.6625 7.4125 4.925C7.5875 5.1875 7.6125 5.525 7.4875 5.8125C7.2875 6.2625 7.1875 6.775 7.1875 7.3125V11.0625C7.1875 12.1375 7.4 13.1875 7.8375 14.1875C7.9875 14.525 7.9125 14.9125 7.675 15.1875C7.4875 15.3875 7.2375 15.5 6.975 15.5ZM5.375 6.4125C4.3625 6.6125 3.625 7.4875 3.625 8.5625C3.625 9.925 4.175 11.2375 5.2625 12.4375C5.3125 12.5 5.375 12.55 5.4375 12.6C5.35 12.0875 5.3125 11.575 5.3125 11.0625V7.3125C5.3125 7.0125 5.3375 6.7125 5.375 6.4125Z"
      fill="#EEF7F2"
    />
    <path
      d="M15 20.9375C9.6625 20.9375 5.3125 16.5875 5.3125 11.25V7.5C5.3125 4.225 7.975 1.5625 11.25 1.5625H18.75C22.025 1.5625 24.6875 4.225 24.6875 7.5V11.25C24.6875 16.5875 20.3375 20.9375 15 20.9375ZM11.25 3.4375C9.0125 3.4375 7.1875 5.2625 7.1875 7.5V11.25C7.1875 15.5625 10.6875 19.0625 15 19.0625C19.3125 19.0625 22.8125 15.5625 22.8125 11.25V7.5C22.8125 5.2625 20.9875 3.4375 18.75 3.4375H11.25Z"
      fill="#EEF7F2"
    />
  </svg>
);

export default Chalice;
