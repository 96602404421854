import React from "react";

const Arrow = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/outline/arrow-down">
      <g id="arrow-down">
        <path
          id="Vector"
          d="M8.33888 11.6999C7.87221 11.6999 7.40555 11.5199 7.05221 11.1666L2.70555 6.81993C2.51221 6.62659 2.51221 6.3066 2.70555 6.11326C2.89888 5.91993 3.21888 5.91993 3.41221 6.11326L7.75888 10.4599C8.07888 10.7799 8.59888 10.7799 8.91888 10.4599L13.2655 6.11326C13.4589 5.91993 13.7789 5.91993 13.9722 6.11326C14.1655 6.3066 14.1655 6.62659 13.9722 6.81993L9.62555 11.1666C9.27221 11.5199 8.80555 11.6999 8.33888 11.6999Z"
          fill="#DEE2D4"
        />
      </g>
    </g>
  </svg>
);

export default Arrow;
