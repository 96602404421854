import React, { FC } from 'react';
import { ResetPasswordWrapper } from './ResetPassword.styled';

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = () => (
 <ResetPasswordWrapper>
    ResetPassword Component
 </ResetPasswordWrapper>
);

export default ResetPassword;
