import React from "react"
import MarketingSectionLabel from "../../MarketingSectionLabel/MarketingSectionLabel"
import { TailoredSectionWrapper } from "./TailoredSection.styled"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StructuredText } from "react-datocms"

interface DatoImage {
  url: string
}

interface HomePage {
  realTimeHeader?: any
  carouselImages?: DatoImage[]
}

type Props = {
  scrollToFeatures: Function
  data: HomePage
}

const TailoredSection = (props: Props) => {
  let settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    pauseOnHover: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <TailoredSectionWrapper>
      <MarketingSectionLabel text="TAILORED TO YOUR METHODOLOGY" />
      <div className="row">
        <div className="section-left">
          {props.data.realTimeHeader && (
            <StructuredText data={props.data.realTimeHeader.value} />
          )}
        </div>
        <div className="section-right">
          {/* <button
            onClick={() => props.scrollToFeatures()}
            className="white-border-button"
          >
            See Features
          </button> */}
        </div>
      </div>

      <Slider {...settings}>
        {props.data.carouselImages?.map((image) => {
          return (
            <div className="carrousel-image-wrapper">
              <img src={image.url} style={{ width: "93%" }} />
            </div>
          )
        })}
      </Slider>
    </TailoredSectionWrapper>
  )
}

export default TailoredSection
