import React from "react"

const Youtube = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2232 5.52399C17.1543 5.25464 17.0224 5.00553 16.8384 4.79719C16.6543 4.58885 16.4233 4.42727 16.1645 4.32577C13.6599 3.35842 9.67365 3.36719 9.45447 3.36719C9.23528 3.36719 5.249 3.35842 2.74442 4.32577C2.48561 4.42727 2.25464 4.58885 2.07056 4.79719C1.88649 5.00553 1.75459 5.25464 1.68575 5.52399C1.49652 6.25315 1.27148 7.58581 1.27148 9.79668C1.27148 12.0075 1.49652 13.3402 1.68575 14.0694C1.75449 14.3388 1.88634 14.5881 2.07042 14.7966C2.2545 15.005 2.48552 15.1667 2.74442 15.2683C5.14379 16.194 8.89919 16.2262 9.40625 16.2262H9.50269C10.0097 16.2262 13.7673 16.194 16.1645 15.2683C16.4234 15.1667 16.6544 15.005 16.8385 14.7966C17.0226 14.5881 17.1544 14.3388 17.2232 14.0694C17.4124 13.3387 17.6374 12.0075 17.6374 9.79668C17.6374 7.58581 17.4124 6.25315 17.2232 5.52399ZM11.9547 10.04L8.44767 12.378C8.40364 12.4073 8.35247 12.4242 8.29961 12.4268C8.24675 12.4293 8.19419 12.4175 8.14753 12.3925C8.10088 12.3675 8.06188 12.3304 8.0347 12.2849C8.00752 12.2395 7.99319 12.1876 7.99322 12.1347V7.45868C7.99319 7.40576 8.00752 7.35382 8.0347 7.30841C8.06188 7.263 8.10088 7.22582 8.14753 7.20084C8.19419 7.17586 8.24675 7.16402 8.29961 7.16658C8.35247 7.16915 8.40364 7.18601 8.44767 7.21538L11.9547 9.55338C11.9947 9.58006 12.0276 9.61623 12.0504 9.65867C12.0731 9.70112 12.085 9.74852 12.085 9.79668C12.085 9.84483 12.0731 9.89224 12.0504 9.93468C12.0276 9.97713 11.9947 10.0133 11.9547 10.04Z"
        fill="#98A2B3"
      />
    </svg>
  )
}

export default Youtube
