import { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dealEvaluationPost } from "api/helpers";
import { AddCallWrapper, AddCallForm } from "./AddCall.styled";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import {
  InputWrapper,
  Input,
  Label,
  CalendarInput,
  TranscriptInput,
} from "../AddDeal/AddDeal.styled";

interface AddCallProps {
  setModal: Function;
  dealId: any;
  dealName: any
}

const AddCall: FC<AddCallProps> = ({ setModal, dealId, dealName }) => {
  let dispatch = useDispatch();
  const [dealStage, setDealStage] = useState("");
  const [callDate, setCallDate] = useState("");
  const [transcript, setTranscript] = useState("");
  const navigate = useNavigate();

  const handleDealSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    let data = {
      transcript: transcript,
      deal_stage: dealStage,
      deal_id: dealId,
      call_date: callDate,
    };
    let response = await dealEvaluationPost(data);
    dispatch(endLoading());
    setModal(false)
    navigate("/app/deals");
  };

  return (
    <AddCallWrapper>
      <h1>Add Call</h1>
      <h3>For {dealName} </h3>
      <AddCallForm onSubmit={(event) => handleDealSubmit(event)}>
        <InputWrapper>
          <Label>Deal Stage</Label>
          <Input
            type="text"
            value={dealStage}
            onChange={(e) => setDealStage(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Call Date</Label>
          <CalendarInput
            type="date"
            id="calendar"
            name="calendar"
            value={callDate}
            onChange={(e) => setCallDate(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Transcript</Label>
          <TranscriptInput
            value={transcript}
            onChange={(e) => setTranscript(e.target.value)}
          />
        </InputWrapper>
        <button className="submit-button" type="submit">
          Add Call
        </button>
      </AddCallForm>
    </AddCallWrapper>
  );
};

export default AddCall;
