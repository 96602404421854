import React, { FC } from 'react';
import { AboutUsWrapper } from './AboutUs.styled';

interface AboutUsProps {}

const AboutUs: FC<AboutUsProps> = () => (
 <AboutUsWrapper>
    AboutUs Component
 </AboutUsWrapper>
);

export default AboutUs;
