import React, { FC, useEffect, useState } from "react";
import {
  getDealsQuick,
  getIncomingCalls,
  getUserHubspotDeals,
  getUserOpportunities,
  putIncomingCalls,
} from "api/helpers";
import moment from "moment-timezone";
import {
  addDays,
  startOfWeek,
  format,
  addWeeks,
  differenceInCalendarDays,
} from "date-fns";
import {
  CalendarWrapper,
  WeekNavigation,
  DayButton,
  CallsContainer,
  CallItem,
  Time,
  CallInfo,
  Host,
  HostInitial,
  WeekToggle,
  ToggleButton,
  CalendarLoader,
} from "./Calendar.styled";
import Arrow from "static/svgs/Arrow";
import { CalendarCall, HubspotDeal, Opportunity, QuickDeal, User } from "types";
import { ToggleSwitch } from "shared/shared_components/components";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DropDown } from "shared/shared_styled_comps/forms.styled";
import Edit from "static/svgs/Edit";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

interface CalendarProps {}

const Calendar: FC<CalendarProps> = () => {
  const navigate = useNavigate();
  const user: User = useSelector((state: any) => state.auth.user);

  const [callsLoading, setCallsLoading] = useState(false);
  const [deals, setDeals] = useState<QuickDeal[]>([]);
  const [orgDeals, setOrgDeals] = useState<QuickDeal[]>([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [weeklyCalls, setWeeklyCalls] = useState<
    Record<string, CalendarCall[]>
  >({});

  //Salesforce
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [currentOpportunityId, setCurrentOpportunityId] = useState<string>();

  //Hubspot
  const [currentHubspotId, setCurrentHubspotId] = useState<string>();
  const [hubspotDeals, setHubpostDeals] = useState<HubspotDeal[]>([]);

  // Calculate the start of the current week based on today's date
  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 0 });

  // Calculate the start of the week based on the weekOffset
  const startOfDisplayedWeek = addWeeks(startOfCurrentWeek, weekOffset);

  // Generate the dates for the current week
  const weekDates = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfDisplayedWeek, i)
  );

  // Find today's date index within the current week
  const todayIndex = differenceInCalendarDays(new Date(), startOfDisplayedWeek);

  // Initialize selectedDay with todayIndex
  const [selectedDay, setSelectedDay] = useState(
    todayIndex >= 0 && todayIndex < 7 ? todayIndex : 0
  );

  const handlePrevWeek = () => setWeekOffset(weekOffset - 1);
  const handleNextWeek = () => setWeekOffset(weekOffset + 1);

  // Function to organize meetings by date
  const organizeMeetingsByDate = (meetings: CalendarCall[]) => {
    const meetingsByDate: Record<string, CalendarCall[]> = {};

    meetings.forEach((meeting) => {
      // Convert meeting_time to a Date object using UTC time
      const meetingTime = moment.parseZone(meeting.meeting_time);

      const dateStr = meetingTime.format("YYYY-MM-DD");

      if (!meetingsByDate[dateStr]) {
        meetingsByDate[dateStr] = [];
      }

      meetingsByDate[dateStr].push(meeting);
    });

    // Sort the meetings by time within each date
    for (const date in meetingsByDate) {
      meetingsByDate[date].sort((a, b) => {
        const timeA = new Date(a.meeting_time).getTime();
        const timeB = new Date(b.meeting_time).getTime();
        return timeA - timeB; // Sort in ascending order
      });
    }

    return meetingsByDate;
  };

  const handleDealChange = (
    callIndex: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newDealId = event.target.value;
    const newDeal = newDealId === "New Deal";

    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      deal_id: newDealId,
      new_deal: newDeal,
      editing: true,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });

    // We now update calls only on the check click
    // putIncomingCalls({
    //   incoming_call_id: updatedCalls[callIndex].incoming_call_id,
    //   deal_id: newDealId,
    // });
  };

  const updateWebsite = (
    callIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const website = event.target.value;

    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      website: website,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });
  };

  const submitDealChange = (callIndex: number, website: string) => {
    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      website: website,
      editing: false,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });

    // Optionally update the backend here if needed
    putIncomingCalls({
      incoming_call_id: updatedCalls[callIndex].incoming_call_id,
      website: website,
      deal_id: updatedCalls[callIndex].deal_id,
      opportunity_id: updatedCalls[callIndex].opportunity_id,
      hubspot_deal_id: updatedCalls[callIndex].hubspot_deal_id,
    });
  };

  const handleEdit = (callIndex: number) => {
    const updatedCalls = [...callsForSelectedDay];
    updatedCalls[callIndex] = {
      ...updatedCalls[callIndex],
      editing: true,
    };

    setWeeklyCalls({
      ...weeklyCalls,
      [selectedDateStr]: updatedCalls,
    });
  };
  function callIsPast(meeting_time: string): boolean {
    const meetingTime: Date = new Date(meeting_time); // Convert the meeting_time to a Date object
    const currentTime: Date = new Date(); // Get the current date and time

    // Return true if the meeting time is in the past, otherwise false
    return meetingTime < currentTime;
  }

  const handleToggle = (
    dayIndex: number,
    callIndex: number,
    call: CalendarCall
  ) => {
    const selectedDateStr = format(weekDates[dayIndex], "yyyy-MM-dd");
    const updatedCalls = [...(weeklyCalls[selectedDateStr] || [])];

    if (updatedCalls[callIndex]) {
      let current_record = updatedCalls[callIndex].record;
      // Toggle the 'record' property
      updatedCalls[callIndex] = {
        ...updatedCalls[callIndex],
        record: !current_record,
      };

      // Update the state with the modified calls
      setWeeklyCalls({
        ...weeklyCalls,
        [selectedDateStr]: updatedCalls,
      });

      // Update the backend
      putIncomingCalls({
        incoming_call_id: call.incoming_call_id,
        record: !current_record,
      });
    }
  };

  useEffect(() => {
    const fetchOpportunities = async () => {
      let response = await getUserOpportunities();
      setOpportunities(response.reverse());
    };
    const fetchHubspotDeals = async () => {
      let response = await getUserHubspotDeals();
      setHubpostDeals(response);
    };
    const getCalls = async () => {
      setCallsLoading(true);
      let response = await getIncomingCalls(weekOffset);
      if (response?.data?.meetings) {
        const organizedMeetings = organizeMeetingsByDate(
          response.data.meetings
        );
        console.log("orged meetings: ", organizedMeetings);
        setWeeklyCalls(organizedMeetings);
        setCallsLoading(false);
      }
    };
    const loadDeals = async () => {
      let response = await getDealsQuick();
      setDeals(response);
    };
    const loadOrgDeals = async () => {
      let response = await getDealsQuick(true);
      setOrgDeals(response);
    };
    let callsToMake: Array<Promise<void>>;
    if (user.organization_info?.salesforce_token) {
      callsToMake = [
        fetchOpportunities(),
        getCalls(),
        loadDeals(),
        loadOrgDeals(),
      ];
    } else if (user.organization_info?.hubspot_token) {
      callsToMake = [
        fetchHubspotDeals(),
        getCalls(),
        loadDeals(),
        loadOrgDeals(),
      ];
    } else {
      callsToMake = [getCalls(), loadDeals(), loadOrgDeals()];
    }
    const promiseAll = async () => {
      await Promise.all(callsToMake);
    };
    if (user && user.google_token && user.google_token !== "") {
      promiseAll();
    }
  }, [user, weekOffset]);

  const dealIdToName = (dealId: string) => {
    if (orgDeals) {
      const deal = orgDeals.find((d) => d.id === dealId);
      if (deal) {
        return deal.name;
      }
    }
    return "";
  };

  const handleHubSpotDealSelect = (
    deal: HubspotDeal | null,
    callIndex: number
  ) => {
    const selectedDateStr = format(weekDates[selectedDay], "yyyy-MM-dd");
    const updatedCalls = [...(weeklyCalls[selectedDateStr] || [])];

    if (updatedCalls[callIndex]) {
      updatedCalls[callIndex] = {
        ...updatedCalls[callIndex],
        hubspot_deal_id: deal ? deal.id : undefined,
        editing: true,
      };

      setWeeklyCalls({
        ...weeklyCalls,
        [selectedDateStr]: updatedCalls,
      });
    }
  };

  const handleOpportunitySelect = (
    opportunity: Opportunity | null,
    callIndex: number
  ) => {
    const selectedDateStr = format(weekDates[selectedDay], "yyyy-MM-dd");
    const updatedCalls = [...(weeklyCalls[selectedDateStr] || [])];

    if (updatedCalls[callIndex]) {
      updatedCalls[callIndex] = {
        ...updatedCalls[callIndex],
        opportunity_id: opportunity ? opportunity.Id : undefined,
        editing: true,
      };

      setWeeklyCalls({
        ...weeklyCalls,
        [selectedDateStr]: updatedCalls,
      });
    }
  };

  const selectedDateStr = format(weekDates[selectedDay], "yyyy-MM-dd");
  const callsForSelectedDay = weeklyCalls[selectedDateStr] || [];

  return (
    <CalendarWrapper>
      <h2>My calendar</h2>
      <WeekToggle>
        <ToggleButton onClick={handlePrevWeek}>
          <Arrow />
        </ToggleButton>
        <div className="week-title">{`Week of ${format(
          startOfDisplayedWeek,
          "MMMM d, yyyy"
        )}`}</div>
        <ToggleButton className="right" onClick={handleNextWeek}>
          <Arrow />
        </ToggleButton>
      </WeekToggle>

      <WeekNavigation>
        {weekDates.map((date, index) => (
          <DayButton
            key={index}
            selected={selectedDay === index}
            onClick={() => setSelectedDay(index)}
          >
            {format(date, "EEEE, MMM d")}
          </DayButton>
        ))}
      </WeekNavigation>

      {callsLoading ? (
        <CalendarLoader />
      ) : (
        <CallsContainer>
          {user && user.google_token && user.google_token !== "" ? (
            callsForSelectedDay.length > 0 ? (
              callsForSelectedDay.map((call, index) => (
                <CallItem key={index}>
                  <Time>{format(new Date(call.meeting_time), "h:mm a")}</Time>
                  <CallInfo>
                    <div>{call.meeting_name}</div>
                    <div>{call.host}</div>
                  </CallInfo>
                  {call.deal_id && call.score_id ? (
                    <div className="deal-details">
                      <div className="deal-name">
                        {dealIdToName(call.deal_id)}
                      </div>
                      <GreenButton
                        width={"120px"}
                        height={36}
                        fontSize={12}
                        onClick={() => navigate(`/app/deals/${call.deal_id}`)}
                      >
                        Deal Details
                      </GreenButton>
                    </div>
                  ) : (
                    <div className="deal-selections">
                      {callIsPast(call.meeting_time) &&
                      (call.record && (!call.transcript_id || call.transcript_id === "None")) ? (
                        <div className="deal-selections">
                          <div style={{ color: "black", marginRight: "10px" }}>No transcript</div>
                        </div>
                      ) : call.record &&
                        call.has_bot_authority &&
                        !call.score_id ? (
                        <>
                          <div className="dropdown-wrapper">
                            {user.organization_info?.salesforce_token &&
                              opportunities &&
                              opportunities.length > 0 && (
                                <div className="opportunity-section">
                                  <div className="opportunity-wrapper">
                                    <SearchableDropdown<Opportunity>
                                      items={opportunities}
                                      displayProperty="Name"
                                      idProperty="Id"
                                      onSelect={(opportunity) =>
                                        handleOpportunitySelect(
                                          opportunity,
                                          index
                                        )
                                      }
                                      placeholder="Search opportunities..."
                                      selectedItem={
                                        opportunities.find(
                                          (o) => o.Id === call.opportunity_id
                                        ) || null
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            {user.organization_info?.hubspot_token &&
                              hubspotDeals &&
                              hubspotDeals.length > 0 && (
                                <div className="hubspot-deal-section">
                                  <div className="hubspot-deal-wrapper">
                                    <SearchableDropdown<HubspotDeal>
                                      items={hubspotDeals}
                                      displayProperty={(deal) =>
                                        deal.properties.dealname
                                      }
                                      idProperty="id"
                                      onSelect={(deal) =>
                                        handleHubSpotDealSelect(deal, index)
                                      }
                                      placeholder="Search HubSpot deals..."
                                      selectedItem={
                                        hubspotDeals.find(
                                          (d) => d.id === call.hubspot_deal_id
                                        ) || null
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            {!user.organization_info?.hubspot_token &&
                              !user.organization_info?.salesforce_token && (
                                <DropDown
                                  value={
                                    call.new_deal
                                      ? "New Deal"
                                      : call.deal_id || ""
                                  }
                                  onChange={(event) =>
                                    handleDealChange(index, event)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Select Deal
                                  </option>
                                  <option value="New Deal">New Deal</option>
                                  {deals &&
                                    deals.map(
                                      (deal: QuickDeal, index: number) => (
                                        <option key={index} value={deal.id}>
                                          {deal.name}
                                        </option>
                                      )
                                    )}
                                </DropDown>
                              )}
                          </div>
                          {/* {(call.deal_id === "New Deal" || call.new_deal) && (
                            <>
                              {!call.editing ? (
                                <div className="website-wrapper">
                                  <p>{call.website || ""}</p>
                                </div>
                              ) : (
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    placeholder="www.companywebsite.com"
                                    className={!call.website ? "empty" : ""}
                                    value={call.website || ""}
                                    onChange={(e) => updateWebsite(index, e)}
                                  />
                                </div>
                              )}
                            </>
                          )} */}
                          {!call.editing ? (
                            <div
                              className="edit-wrapper"
                              onClick={() => handleEdit(index)}
                            >
                              <Edit />
                            </div>
                          ) : (
                            <div
                              className="checkmark"
                              onClick={(e) =>
                                submitDealChange(index, call.website || "")
                              }
                            >
                              &#x2713;
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {call.deal_id && (
                            <div className="deal-name">
                              {dealIdToName(call.deal_id)}
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className={
                          callIsPast(call.meeting_time)
                            ? "switch-wrapper disabled"
                            : "switch-wrapper"
                        }
                      >
                        <p>Record:</p>
                        <ToggleSwitch
                          checked={call.record}
                          onChange={() =>
                            handleToggle(selectedDay, index, call)
                          }
                        />
                      </div>
                    </div>
                  )}
                  <Host>
                    <HostInitial>
                      {call?.host?.charAt(0).toLocaleUpperCase()}
                    </HostInitial>
                    <p>{call.host}</p>
                  </Host>
                </CallItem>
              ))
            ) : (
              <CallItem>
                <Time></Time>
                <CallInfo>
                  <div className="no-meetings">
                    No meetings scheduled on this day.
                  </div>
                </CallInfo>
                <Host>
                  <HostInitial>NA</HostInitial>
                </Host>
              </CallItem>
            )
          ) : (
            <CallItem>
              <Time></Time>
              <CallInfo>
                <div className="no-meetings">No calendar connected</div>
              </CallInfo>
              <div className="deal-details">
                <GreenButton
                  width={"160px"}
                  height={36}
                  fontSize={12}
                  onClick={() => navigate(`/app/settings/integrations`)}
                >
                  Connect Calendar
                </GreenButton>
              </div>
            </CallItem>
          )}
        </CallsContainer>
      )}
    </CalendarWrapper>
  );
};
export default Calendar;
