import { colors } from 'colors';
import styled from 'styled-components';


export const SearchBarWrapper = styled.div`
  width: 100%;
  height: 36px;
  position: relative;
  .svg-wrapper {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      width: 100%;
      height: 100%
    }
  }
  .search-input {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    border: none;
    box-shadow: 0px 4.801233291625977px 14.403700828552246px -2.4006166458129883px #00000014;
    background-color: ${colors.white};
    font-size: 14px;
    font-weight: 300;
    line-height: 12px;
    text-align: left;
    padding-left: 45px;
  }
`;
