import styled from "styled-components"
import { colors } from "colors"

interface MarketingSectionLabelWrapperProps {
  fontSize?: string
  fontWeight?: number
}

export const MarketingSectionLabelWrapper = styled.label<MarketingSectionLabelWrapperProps>`
  background-color: #111218;
  font-size: ${(props) =>
    props.fontSize && props?.fontSize ? `${props.fontSize}px` : "12px"};
  font-weight: ${(props) =>
    props.fontWeight && props?.fontWeight ? `${props.fontWeight}` : ""};
  line-height: 15px;
  padding: 7px 15px;
  border-radius: 5px;
  max-width: fit-content;
  color: ${colors.mint};
`
