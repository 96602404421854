import React from "react"

const TwitterBlack = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8106 5.67325L16.626 7.85781C16.1848 12.9736 11.8704 16.9497 6.7071 16.9497C5.64624 16.9497 4.77168 16.7816 4.10755 16.4499C3.572 16.1818 3.35281 15.8947 3.29801 15.8128C3.24915 15.7396 3.21748 15.6562 3.20537 15.569C3.19326 15.4817 3.20102 15.3929 3.22807 15.3091C3.25511 15.2252 3.30075 15.1486 3.36158 15.0849C3.4224 15.0212 3.49683 14.9721 3.5793 14.9412C3.5983 14.9339 5.35034 14.261 6.46308 12.9802C5.84598 12.4728 5.30729 11.877 4.86447 11.2121C3.9585 9.86702 2.94439 7.53049 3.2571 4.03884C3.26701 3.92789 3.30842 3.82209 3.37645 3.73388C3.44448 3.64568 3.53629 3.57875 3.64108 3.54098C3.74588 3.50321 3.85928 3.49617 3.96794 3.52069C4.07659 3.54521 4.17598 3.60026 4.2544 3.67937C4.27997 3.70495 6.68592 6.09774 9.62741 6.87366V6.42871C9.62629 5.96213 9.71854 5.50004 9.89872 5.06966C10.0789 4.63927 10.3434 4.24929 10.6766 3.92267C11.0002 3.59954 11.3852 3.34449 11.809 3.17257C12.2327 3.00066 12.6866 2.91536 13.1439 2.92172C13.7573 2.92777 14.3587 3.09243 14.8896 3.39969C15.4206 3.70696 15.8629 4.14637 16.1738 4.67521H18.3971C18.5127 4.67512 18.6258 4.70935 18.7221 4.77357C18.8183 4.83779 18.8932 4.92911 18.9375 5.03597C18.9818 5.14283 18.9934 5.26043 18.9708 5.37387C18.9482 5.48731 18.8924 5.5915 18.8106 5.67325Z"
        fill="#2F2F2F"
      />
    </svg>
  )
}

export default TwitterBlack
