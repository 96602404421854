import React, { useEffect, useState } from "react"
import { FourZeroFourWrapper } from "./FourZeroFour.styled"
import Robot from "static/svgs/Robot"
import { GreenButton } from "shared/shared_styled_comps/components.styled"
import { useNavigate } from "react-router-dom"
type Props = {}

const FourZeroFour = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState<number | null>(null)
  const navigate = useNavigate()
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== null && window.innerWidth !== undefined) {
        setWindowWidth(window?.innerWidth)
      }
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleNavigateHome = () => {
    navigate("/")
  }
  return (
    <FourZeroFourWrapper>
      <div className="inner-wrapper">
        <div className="robot-wrapper">
          <Robot />
          <h1>404 Not Found</h1>
          <GreenButton
            width={"fit-content"}
            height={`fit-content`}
            fontSize={25}
            paddingTop={15}
            paddingBottom={15}
            paddingleft={40}
            paddingright={40}
            onClick={() => handleNavigateHome()}
          >
            Go Back Home
          </GreenButton>
        </div>
      </div>
    </FourZeroFourWrapper>
  )
}

export default FourZeroFour
