import React from "react";
import { ToggleSwitchWrapper } from "shared/shared_styled_comps/components.styled";

interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
}) => {
  return (
    <ToggleSwitchWrapper onClick={onChange}>
      <div className={`switch ${checked ? "on" : "off"}`} />
    </ToggleSwitchWrapper>
  );
};
