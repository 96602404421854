import React from "react"

const Plus = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M19.8503 11.5926C19.8503 11.7768 19.7771 11.9534 19.6469 12.0837C19.5166 12.2139 19.34 12.2871 19.1558 12.2871H12.2114V19.2315C12.2114 19.4157 12.1382 19.5923 12.008 19.7225C11.8777 19.8528 11.7011 19.9259 11.5169 19.9259C11.3327 19.9259 11.1561 19.8528 11.0259 19.7225C10.8956 19.5923 10.8225 19.4157 10.8225 19.2315V12.2871H3.87804C3.69386 12.2871 3.51723 12.2139 3.38699 12.0837C3.25676 11.9534 3.18359 11.7768 3.18359 11.5926C3.18359 11.4084 3.25676 11.2318 3.38699 11.1016C3.51723 10.9713 3.69386 10.8982 3.87804 10.8982H10.8225V3.95372C10.8225 3.76954 10.8956 3.59291 11.0259 3.46268C11.1561 3.33244 11.3327 3.25928 11.5169 3.25928C11.7011 3.25928 11.8777 3.33244 12.008 3.46268C12.1382 3.59291 12.2114 3.76954 12.2114 3.95372V10.8982H19.1558C19.34 10.8982 19.5166 10.9713 19.6469 11.1016C19.7771 11.2318 19.8503 11.4084 19.8503 11.5926Z"
          fill="#98A2B3"
        />
      </g>
    </svg>
  )
}

export default Plus
