import { colors } from "colors";
import styled from "styled-components";

interface UserRowProps {
  isCurrentUser?: boolean;
}

export const UserRowWrapper = styled.div<UserRowProps>`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  ${(props) => props.isCurrentUser && `background-color: ${colors.darkGray};`}
  .close-wrapper {
    cursor: pointer;
  }
  .profile-icon {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    margin-right: 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .space {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
  }
  .svg-wrapper {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      path {
        stroke: ${colors.white};
      }
    }
  }
  .active {
    width: 35%;
  }
  .email {
    width: 27%;
  }
  .name {
    width: 20%;
  }
  select {
    width: 100px;
  }
`;
