import styled from "styled-components"
import { colors } from "colors"

export const FourZeroFourWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  height: 100vh;

  width: 100vw;
  .inner-wrapper {
    max-width: 1800px;
    .robot-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        margin-bottom: 30px;
      }
      h1 {
        color: ${colors.mainGreen};
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: 516px) {
    height: calc(100vh - 374px);
  }
`
