import React, { useRef, useEffect, useState } from "react";
import { MarketingBlogPostPageWrapper } from "./MarketingBlog.styled";
import ArrowsPointLeft from "static/svgs/ArrowsPointLeft";
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection";
import BlackInstagram from "static/svgs/BlackInstagram";
import LinkedinBlack from "static/svgs/LinkedinBlack";
import TwitterBlack from "static/svgs/TwitterBlack";
import TwitterLabel from "static/svgs/TwitterLabel";
import LinkedinLabel from "static/svgs/LinkedinLabel";
import FacebookLabel from "static/svgs/FacebookLabel";
import RedditLabel from "static/svgs/RedditLabel";
import { useParams } from "react-router-dom";
import { StructuredText } from "react-datocms";
import { Helmet } from "react-helmet";
import { BlogPost } from "marketingTypes";

type Props = {};

interface Block {
  id: string;
  image: {
    url: string;
    alt?: string;
  };
}

interface Content {
  value: any; // or more specific type from DatoCMS
  blocks: Block[];
}

type Record = {
  id: string;
  image?: {
    url: string;
    alt?: string;
  };
};

interface RenderBlockProps {
  record: Record;
}

interface ImageStyles extends React.CSSProperties {
  width: string;
  maxWidth: string;
  height: string;
  margin: string;
  display: string;
}

const MarketingBlogPost = (props: Props) => {
  const descriptionTopRef = useRef<HTMLHeadingElement>(null);
  const [topRef, setTopRef] = useState<number | null>(null);
  const [post, setPost] = useState<BlogPost | null>(null);
  const [structuredData, setStructuredData] = useState<object | {}>({});
  const { postId } = useParams();

  useEffect(() => {
    async function getPost() {
      try {
        const response = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
          },
          body: JSON.stringify({
            query: `
              query BlogPost($postId: ItemId!) {
                blogPost(filter: {id: {eq: $postId}}) {
                  title
                  id
                  description
                  date
                  blogPostAuthor {
                    authorImage {
                      url
                    }
                    authorName
                    facebookLink
                    instagramLink
                    linkedinLink
                    redditLink
                    twitterLink
                  }
                  content {
                    value
                    blocks {
                      id
                      image {
                        url
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              postId: postId,
            },
          }),
        });

        const json = await response.json();

        // Add debug logging
        console.log("PostId:", postId);
        console.log("API Response:", json);

        if (json.errors) {
          console.error("GraphQL Errors:", json.errors);
          return;
        }

        if (!json.data?.blogPost) {
          console.error("No blog post found");
          return;
        }

        setPost(json.data.blogPost);
        setStructuredData({
          "@context": "https://schema.org",
          "@type": "Article",
          headline: json.data.blogPost.title,
          description: json.data.blogPost.description,
          image: "https://www.datocms-assets.com/127817/1716136774-logo.png",
          datePublished: new Date(json.data.blogPost.date).toISOString(),
          author: {
            "@type": "Person",
            name: json.data.blogPost.blogPostAuthor.authorName,
            url: json.data.blogPost.blogPostAuthor.linkedinLink,
          },
          publisher: {
            "@type": "Organization",
            name: "Leadoff.ai",
            url: "https://leadoff.ai/",
          },
        });
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    }

    if (postId) {
      getPost();
    } else {
      console.error("No postId provided");
    }
  }, [postId]);

  const renderBlock = ({ record }: any) => {
    switch (record.__typename) {
      case "ImageBlockRecord":
        return (
          <div className="blog-image">
            <img src={record.image.url} alt={record.image.alt || ""} />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (post?.content) {
      console.log("Content:", post.content);
    }
  }, [post]);

  useEffect(() => {
    const handleResize = () => {
      if (descriptionTopRef.current) {
        setTopRef(descriptionTopRef.current.offsetTop);
      }
    };

    // Initial width calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MarketingBlogPostPageWrapper topRef={topRef}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="inner-wrapper">
        <div className="heading-wrapper">
          <div className="heading-left">
            <h1>
              Leadoff <span>Blog</span>
            </h1>
            <h4 ref={descriptionTopRef}>
              Navigating Challenges and Coaching to Success in the Sales World
            </h4>
          </div>
          <div className="heading-right">
            <ArrowsPointLeft />
          </div>
        </div>
        <hr />
        <div className="content-wrapper">
          <h2>{post?.title}</h2>
          <div className="author">
            <div className="inner">
              <p>by: &nbsp;</p>
              <div className="info-wrapper">
                <p>{post?.blogPostAuthor.authorName}</p>
              </div>
              <div className="image-wrapper">
                <img src={post?.blogPostAuthor?.authorImage?.url} />
              </div>
            </div>
          </div>
          {post?.content && (
            <StructuredText
              data={post.content} // Pass the content directly
              renderBlock={({ record }: RenderBlockProps) => {
                if (record.image) {
                  const imageStyles: ImageStyles = {
                    width: "100%",
                    maxWidth: "400px",
                    height: "auto",
                    margin: "2rem auto",
                    display: "block",
                  };

                  return (
                    <div className="blog-image">
                      <img
                        src={record.image.url}
                        alt={record.image.alt || ""}
                        style={imageStyles}
                      />
                    </div>
                  );
                }
                return null;
              }}
            />
          )}
          <div className="author-section">
            <div className="left">
              <p>Author</p>
              <div className="inner">
                <div className="image-wrapper">
                  <img src={post?.blogPostAuthor?.authorImage?.url} />
                </div>
                <div className="info-wrapper">
                  <p>{post?.blogPostAuthor?.authorName}</p>
                  <div className="socials-wrapper">
                    <a target="_blank" href={post?.blogPostAuthor?.twitterLink}>
                      <TwitterBlack />
                    </a>
                    <a
                      target="_blank"
                      href={post?.blogPostAuthor?.linkedinLink}
                    >
                      <LinkedinBlack />
                    </a>
                    <a
                      target="_blank"
                      href={post?.blogPostAuthor?.instagramLink}
                    >
                      <BlackInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="right">
              <p>Share this article</p>
              <div className="socials-wrapper">
                <TwitterLabel />
                <FacebookLabel />
                <RedditLabel />
                <LinkedinLabel />
              </div>
            </div>
          </div>
        </div>
        <StepUpSection />
      </div>
    </MarketingBlogPostPageWrapper>
  );
};

export default MarketingBlogPost;
