import { FC, useState, useEffect } from "react";
import {
  CallModalWrapper,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  ActionSelect,
  Checkbox,
} from "./CallModal.styled";
import {
  getDealsQuick,
  getIncomingCalls,
  postMultipleEvaluations,
} from "api/helpers";
import { useDispatch } from "react-redux";
import { QuickDeal, IncomingCall } from "types";
import {
  startLoading,
  endLoading,
  setError,
  setCallModal,
  setAddDealModal,
} from "state/appActions";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import {
  CheckboxInput,
  CheckboxLabel,
} from "components/DealPage/DealPage.styled";
import CheckWithCircle from "static/svgs/CheckWithCircle";

interface CallModalProps {}

const CallModal: FC<CallModalProps> = () => {
  const [deals, setDeals] = useState<QuickDeal[]>([]);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [calls, setCalls] = useState<IncomingCall[]>([]);
  let dispatch = useDispatch();

  const fetchDealsData = async () => {
    dispatch(startLoading());
    let response = await getDealsQuick();
    let response2 = await getIncomingCalls();
    dispatch(endLoading());
    // Redirect to re-authenticate with zoom
    if (typeof response2 === "string") {
      window.location.assign(response2);
    } else {
      // Ensure to on ly display 10 at a time
      setDeals(response.reverse());
      setCalls(response2?.data?.meetings?.reverse());
    }
  };

  useEffect(() => {
    fetchDealsData();
  }, []);

  const handleCheckboxChange = (index: number) => {
    const updatedCalls = calls.map((call, i) =>
      i === index ? { ...call, selected: !call.selected } : call
    );
    setCalls(updatedCalls);
  };

  const handleWebsiteChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedCalls = calls.map((call, i) =>
      i === index ? { ...call, website: event.target.value } : call
    );
    setCalls(updatedCalls);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    let updatedCalls = undefined
    if (event.target.value === "New Deal") {
      updatedCalls = calls.map((call, i) =>
        i === index ? { ...call, deal_id: event.target.value, new_deal: true } : call
      );
    } else {
      updatedCalls = calls.map((call, i) =>
        i === index ? { ...call, deal_id: event.target.value } : call
      );
    }
    setCalls(updatedCalls);
  };

  const handleSubmit = () => {
    // Filter out non selected calls
    // Remove deal_id for new calls to generater a new one
    // Validate new deals have a website
    const filteredCalls = [];
    let runEvals = true

    for (const item of calls) {
      if (item.selected && !item.deal_id) {
        setError(
          "Please select an existing or new deal from the dropdown for each selected call."
        );
        runEvals = false;
        break;
      }
      const modifiedItem =
        item.deal_id === "New Deal" ? { ...item, deal_id: "", deal_name: item.meeting_name, url: item.website } : item;

      if (modifiedItem.selected) {
        filteredCalls.push(modifiedItem);
      }
    }
    console.log("filteredCalls: ", filteredCalls);
    if (runEvals) {
      postMultipleEvaluations({ evaluations: filteredCalls });
      setPage(1);
    }
  };

  switch (page) {
    case 0:
      return (
        <CallModalWrapper>
          <h2>Recent Call List</h2>
          <p>Select your calls below to add them to the appropriate Deal.</p>
          <p>Check off a single call or several to perform a bulk action.</p>
          <div className="table-wrapper">
            <TableContainer>
              <TableHeader>
                <TableHeaderCell>Call Title</TableHeaderCell>
                <TableHeaderCell>Date</TableHeaderCell>
                <TableHeaderCell>Action</TableHeaderCell>
              </TableHeader>
              {calls?.map((call: IncomingCall, index: number) => 
                call.transcript_id && call.transcript_id !== "" && call.transcript_id !== "None" ? (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex">
                        <CheckboxInput
                          type="checkbox"
                          checked={call.selected}
                          onChange={() => handleCheckboxChange(index)}
                          id={call.meeting_name}
                        />
                        <CheckboxLabel
                          className="box"
                          htmlFor={call.meeting_name}
                        >
                          <CheckWithCircle />
                        </CheckboxLabel>
                        <p>{call.meeting_name}</p>
                      </div>
                      {call.deal_id && call.deal_id === "New Deal" && (
                        <div className="label-wrapper">
                          <label>
                            Company Website
                            <input
                              type="text"
                              value={call.website}
                              onChange={(e) => handleWebsiteChange(e, index)}
                              placeholder="www.leadoff.ai"
                            />
                          </label>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{call.meeting_time}</TableCell>
                    {call.selected ? (
                      <TableCell rowSpan={calls.length}>
                        <ActionSelect
                          onChange={(e) => handleSelectChange(e, index)}
                        >
                          <option>Choose a deal</option>
                          <option value="New Deal">New Deal</option>
                          {deals?.map((deal: QuickDeal, index: number) => (
                            <option value={deal.id} key={index}>
                              {deal.name}
                            </option>
                          ))}
                        </ActionSelect>
                      </TableCell>
                    ) : (
                      <TableCell rowSpan={calls.length}></TableCell>
                    )}
                  </TableRow>
                ) : null
              )}
            </TableContainer>
          </div>
          <GradientButton
            onClick={() => handleSubmit()}
            width="50%"
            height="60px"
            fontSize={14}
            top={50}
            disabled={false}
          >
            <GradientText>Analyze Calls</GradientText>
          </GradientButton>
          {error && <p className="error">{error}</p>}
        </CallModalWrapper>
      );
    case 1:
      return (
        <CallModalWrapper
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <h2>Your calls are processing!</h2>
          <p>Feel free to close out of this modal.</p>
          <p>
            You will recieve an email once each deal processes, and a refresh of
            the deals page will show them once complete!
          </p>
          <GradientButton
            onClick={() => {
              dispatch(setAddDealModal(false));
              dispatch(setCallModal(false));
            }}
            width="50%"
            height="60px"
            fontSize={14}
            top={50}
            disabled={false}
          >
            <GradientText>Close modal</GradientText>
          </GradientButton>
        </CallModalWrapper>
      );
    default:
      return <></>;
  }
};

export default CallModal;
