import { colors } from "colors"
import styled from "styled-components"

export const MarketingTeamPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.black};

  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 211px 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 68px;
      font-weight: 700;
    }
    h2 {
      font-size: 53px;
      color: white;
    }
    .team-header {
      color: ${colors.green};
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
    }
    .heading-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1080px;
      margin-bottom: 100px;
      h2 {
        margin-bottom: 40px;
      }
      .team-subheader {
        color: ${colors.lightGray};
      }
    }
    .team-members-wrapper {
      width: 100%;
      max-width: 1315px;
      display: flex;
      justify-content: center;
      .team-members-inner-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  @media (min-width: 694px) {
    .inner-wrapper {
      h2 {
        font-size: 53px;
      }
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 211px 78px 0 78px;
    }
  }
`
export const TeamMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 15px 0;
  padding-top: 50px;
  padding-bottom: 50px;
  flex: 0 1 100%;
  background-color: ${colors.deepGray};
  min-width: 300px;
  .headshot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    overflow: hidden;
    margin-bottom: 50px;
    img {
      height: 200px;
      width: 200px;
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .position {
      color: ${colors.green};
      font-weight: 700;
    }
    h4 {
      font-weight: 700;
    }
    svg {
      margin-bottom: 1em;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
      .email-wrapper {
        width: fit-content;
        height: fit-content;
        padding: 10px 25px 10px 25px;
        background-color: #2f2f2f;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover {
          opacity: 0.7;
        }
        .email {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 710px) {
    flex: 0 1 calc(50% - 30px);
    margin: 15px;
  }
  @media (min-width: 1146px) {
    flex: 0 1 calc(33% - 30px);
  }
`
