import styled from "styled-components";
import { colors } from 'colors';

const TypingWrapper = styled.div`
  .typing-indicator circle {
    animation: bounce .7s infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-2px);
    }
  }

  /* Individual dot animation delays */
  .dot-1 {
    animation-delay: 0s !important;
  }

  .dot-2 {
    animation-delay: 0.15s !important;
  }

  .dot-3 {
    animation-delay: 0.28s !important;
  }
`;

const TypingIndicator = () => {
  const numberOfDots = 3;
  return (
    <TypingWrapper>
      <svg width={50} height={20} className="typing-indicator">
        {[...Array(numberOfDots)].map((_, index) => (
          <circle
            key={index}
            cx={5+(8*index)}
            cy={10}
            r={3}
            fill={colors.mainGreen} // Change color as needed
            className={`dot-${index + 1}`} // Add individual class for animation
          />
        ))}
      </svg>
    </TypingWrapper>
  );
};

export default TypingIndicator;
