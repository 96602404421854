import React, { FC, useEffect } from "react"
import { PageWrapperWrapper } from "./PageWrapper.styled"
import { setPageTitle } from "state/appActions"
import { useDispatch } from "react-redux"

interface PageWrapperProps {
  children: React.ReactNode
  icon?: React.ReactNode
  pageTitle: string
  className?: string
}

const PageWrapper: FC<PageWrapperProps> = ({
  pageTitle,
  children,
  className,
}) => {
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle(pageTitle))
  }, [pageTitle])

  return (
    <PageWrapperWrapper className={`side-bar-enabled ${className}`}>
      <div className="content">{children}</div>
    </PageWrapperWrapper>
  )
}

export default PageWrapper
