import React from "react"

import { FAQWrapper } from "./MarketingFAQsPage.styled"
type Props = {
  icon?: string
  question: string
  answer: string
}

const FAQ = (props: Props) => {
  return (
    <FAQWrapper>
      <div className="icon"></div>
      <p className="question">{props.question}</p>
      <p className="answer">{props.answer}</p>
    </FAQWrapper>
  )
}

export default FAQ
