import React from "react"

const BlackInstagram = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1289 1.75293H6.11493C5.03015 1.75409 3.99014 2.18553 3.22309 2.95258C2.45604 3.71963 2.0246 4.75965 2.02344 5.84442V12.8584C2.0246 13.9432 2.45604 14.9832 3.22309 15.7502C3.99014 16.5173 5.03015 16.9487 6.11493 16.9499H13.1289C14.2137 16.9487 15.2537 16.5173 16.0208 15.7502C16.7878 14.9832 17.2192 13.9432 17.2204 12.8584V5.84442C17.2192 4.75965 16.7878 3.71963 16.0208 2.95258C15.2537 2.18553 14.2137 1.75409 13.1289 1.75293ZM9.62192 12.8584C8.9283 12.8584 8.25026 12.6527 7.67354 12.2674C7.09682 11.882 6.64732 11.3343 6.38188 10.6935C6.11645 10.0527 6.047 9.34752 6.18231 8.66723C6.31763 7.98694 6.65164 7.36206 7.1421 6.87159C7.63256 6.38113 8.25745 6.04712 8.93774 5.91181C9.61803 5.77649 10.3232 5.84594 10.964 6.11137C11.6048 6.37681 12.1525 6.82631 12.5379 7.40303C12.9232 7.97975 13.1289 8.6578 13.1289 9.35141C13.1279 10.2812 12.7582 11.1727 12.1007 11.8302C11.4432 12.4876 10.5517 12.8574 9.62192 12.8584ZM14.0057 5.84442C13.8323 5.84442 13.6627 5.793 13.5186 5.69666C13.3744 5.60032 13.262 5.46339 13.1957 5.30319C13.1293 5.14298 13.1119 4.9667 13.1458 4.79663C13.1796 4.62655 13.2631 4.47033 13.3857 4.34772C13.5083 4.2251 13.6645 4.1416 13.8346 4.10777C14.0047 4.07394 14.181 4.0913 14.3412 4.15766C14.5014 4.22402 14.6383 4.3364 14.7347 4.48058C14.831 4.62476 14.8824 4.79427 14.8824 4.96767C14.8824 5.2002 14.79 5.42321 14.6256 5.58763C14.4612 5.75205 14.2382 5.84442 14.0057 5.84442ZM11.9599 9.35141C11.9599 9.81382 11.8228 10.2659 11.5659 10.6503C11.309 11.0348 10.9438 11.3345 10.5166 11.5114C10.0894 11.6884 9.61933 11.7347 9.1658 11.6445C8.71227 11.5543 8.29568 11.3316 7.96871 11.0046C7.64173 10.6777 7.41906 10.2611 7.32885 9.80753C7.23864 9.35401 7.28494 8.88391 7.46189 8.4567C7.63885 8.02949 7.93852 7.66434 8.323 7.40744C8.70748 7.15054 9.15951 7.01342 9.62192 7.01342C10.242 7.01342 10.8367 7.25974 11.2751 7.6982C11.7136 8.13666 11.9599 8.73134 11.9599 9.35141Z"
        fill="#2F2F2F"
      />
    </svg>
  )
}

export default BlackInstagram
