import { FC, useState } from "react";
import {
  CompanyBreakdownWrapper,
  DealCoachCard,
  ContactCard,
} from "./CompanyBreakdown.styled";
import Cycle from "static/svgs/Cycle";
import Sparkle from "static/svgs/Sparkle";
import moment from "moment";
import { Company } from "types";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import { postPrecallPrep } from "api/helpers";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "state/appActions";

interface CompanyBreakdownProps {
  company?: Company | null;
  dealID: string;
  handleMessageSend: Function;
}

const CompanyBreakdown: FC<CompanyBreakdownProps> = ({
  company,
  handleMessageSend,
  dealID,
}) => {
  const [website, setWebsite] = useState("");
  let dispatch = useDispatch();

  const handlePrecall = async () => {
    if (website && dealID) {
      dispatch(startLoading());
      let response = await postPrecallPrep({
        url: website,
        save: true,
        deal_id: dealID,
      });
      dispatch(endLoading());
      if (response) {
        window.location.reload();
      }
    }
  };

  if (company) {
    return (
      <CompanyBreakdownWrapper>
        <h1>
          {company?.company_name}
          <span>{company?.hq_location}</span>
        </h1>
        <div>
          <p className="summary">{company?.summary}</p>
          {company.product_offering && (
            <p>
              <strong>Product Offering:</strong> {company.product_offering}
            </p>
          )}
          {company.products_and_customer && (
            <p>
              <strong>Top Products:</strong>
              <br />{" "}
              {company.products_and_customer.map((product, index) => (
                <span key={index}>
                  {product}
                  {index !== company.products_and_customer.length - 1 && <br />}
                </span>
              ))}
            </p>
          )}
        </div>
        {/* <div className="regen-wrapper">
          {company?.timestamp && (
            <p>Pull Date: {moment(company.timestamp).format("MMM DD, YYYY")}</p>
          )}
          {company?.website && <p>Website: {company.website}</p>}
          <button>
            <Cycle />
            Regenerate
          </button>
        </div> */}
        {/* <div className="regen-wrapper">
          <DealCoachCard
            onClick={() =>
              handleMessageSend(
                `Guide me on how to align our solution to meet ${company.company_name}'s goals.`
              )
            }
          >
            <div className="flex">
              <Sparkle />
              <p>ASK YOUR DEAL COACH!</p>
            </div>
            <p>
              Guide me on how to align our solution to meet{" "}
              {company?.company_name}'s goals.
            </p>
          </DealCoachCard>
          <DealCoachCard inverse onClick={() => handleMessageSend("")}>
            <div className="flex">
              <Sparkle />
              <p>GET HELP WITH SOMETHING ELSE?</p>
            </div>
            <p>Take me to my deal coach</p>
          </DealCoachCard>
        </div> */}
        <h1>Company Details</h1>
        <div className="bottom-wrapper">
          {company?.leadership_team?.length > 0 && (
            <ContactCard>
              <h3>{company?.leadership_team[0].name}</h3>
              {company?.leadership_team[0].title && (
                <p>{company?.leadership_team[0].title}</p>
              )}
              <p className="website">{company?.leadership_team[0].position}</p>
            </ContactCard>
          )}
          <div className="info">
            <p>
              <strong>Industry: </strong>
              {company.industry}
            </p>
            {company.company_size && (
              <p>
                <strong>Size: </strong>
                {company.company_size}
              </p>
            )}
            {company.revenue && (
              <p>
                <strong>Revenue: </strong>
                {company.revenue}
              </p>
            )}
            {company?.primary_competitors && (
              <p>
                <strong>Primary Competitors: </strong>
                {company.primary_competitors.map((comp, index) => (
                  <span key={index}>
                    {comp}
                    {index < company.primary_competitors.length - 1 && ","}{" "}
                  </span>
                ))}
              </p>
            )}
          </div>
        </div>
      </CompanyBreakdownWrapper>
    );
  } else {
    return (
      <CompanyBreakdownWrapper>
        <div className="submit-wrapper">
          <h2>No Company Data Found</h2>
          <h4>Enter a url and submit to run the Company Analysis</h4>
          <input
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            type="text"
          />
          <GreenButton
            width={"120px"}
            height={28}
            fontSize={12}
            onClick={() => handlePrecall()}
          >
            Submit Website
          </GreenButton>
        </div>
      </CompanyBreakdownWrapper>
    );
  }
};

export default CompanyBreakdown;
