import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendAmplitudeData } from 'amplitude';

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    sendAmplitudeData('Page View', {
      path: location.pathname,
      search: location.search,
    });
  }, [location]);

  return null;
};

export default TrackPageViews;