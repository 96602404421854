import React, { FC, useRef, useState, useEffect } from "react";
import {
  Avatar,
  AvatarWrapper,
  MessageWrapper,
  SpeakerName,
  Timestamp,
  TranscriptLine,
  TranscriptWrapper,
} from "./Transcript.styled";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import { GreenButton } from "shared/shared_styled_comps/components.styled";

interface TranscriptProps {
  transcript?: string;
  video_url?: string;
}

interface Transcript {
  start: number;
  end: number;
  speaker: string;
  content: string;
}

// Define an array of 10 random colors
const avatarColors = [
  "#E8A6A6",
  "#D6B497",
  "#A6D8A6",
  "#A6CDE8",
  "#F1A6E8",
  "#D1A6F1",
  "#A6F1E8",
  "#F1E8A6",
  "#C6F1A6",
  "#F1C0A6",
];

// Function to darken a hex color
function darkenColor(hex: string, percent: number): string {
  const num = parseInt(hex.slice(1), 16); // Convert hex to decimal
  const r = (num >> 16) - percent; // Adjust red
  const g = ((num >> 8) & 0x00ff) - percent; // Adjust green
  const b = (num & 0x0000ff) - percent; // Adjust blue

  // Clamp values to ensure they don't go below 0
  const newR = Math.max(0, r);
  const newG = Math.max(0, g);
  const newB = Math.max(0, b);

  // Convert back to hex and return
  return `#${((newR << 16) | (newG << 8) | newB)
    .toString(16)
    .padStart(6, "0")}`;
}

// Function to get a consistent color for each speaker
function getColorForSpeaker(speaker: string): string {
  // Use a simple hash function to get a consistent index based on the speaker's name
  let hash = 0;
  for (let i = 0; i < speaker.length; i++) {
    hash = speaker.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Return a color from the array using the hash
  const colorIndex = Math.abs(hash % avatarColors.length);
  return avatarColors[colorIndex];
}

const Transcript: FC<TranscriptProps> = ({ transcript, video_url }) => {
  const textContainerRef = useRef<HTMLDivElement | null>(null);
  const [highlightedText, setHighlightedText] = useState<string>("");

  const [parsedTranscript, setParsedTranscript] = useState<Transcript[]>([]);
  const activeLineRef = useRef<HTMLDivElement | null>(null);

  function parseTime(time: string): number {
    const [minutes, seconds] = time.split(":").map(parseFloat);
    return minutes * 60 + seconds;
  }

  function parseTranscript(input: string): Transcript[] {
    const linePattern =
      /\[(\d{2}:\d{2}\.\d{2})\] ([^:]+): (.+?) \[(\d{2}:\d{2}\.\d{2})\]/g;
    const lines: Transcript[] = [];
    let match: RegExpExecArray | null;

    while ((match = linePattern.exec(input)) !== null) {
      const [_, start, speaker, content, end] = match;
      lines.push({
        start: parseTime(start),
        end: parseTime(end),
        speaker,
        content,
      });
    }

    const combined: Transcript[] = [];
    let current: Transcript | null = null;

    for (const line of lines) {
      if (current && current.speaker === line.speaker) {
        current.content += " " + line.content;
        current.end = line.end;
      } else {
        if (current) combined.push(current);
        current = { ...line };
      }
    }
    if (current) combined.push(current);
    return combined;
  }

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (transcript) {
      const parsed = parseTranscript(transcript);
      setParsedTranscript(parsed);
    }
  }, [transcript]);

  // Function to jump to a specific time in the video
  const jumpTo = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time; // Set video to specific timestamp
    }
  };

  // Scroll to the active line based on video time
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handleTimeUpdate = () => {
      const currentTime = videoElement.currentTime;

      const activeLine = parsedTranscript.find(
        (line) => currentTime >= line.start && currentTime <= line.end
      );

      if (activeLine && textContainerRef.current) {
        // Use an id that starts with a letter and replaces '.' with '_'
        const transcriptElement = textContainerRef.current.querySelector(
          `#line-${activeLine.start.toFixed(2).replace(".", "_")}`
        );
        // Remove the "highlighted" class from the previously highlighted line
        if (
          activeLineRef.current &&
          activeLineRef.current !== transcriptElement
        ) {
          activeLineRef.current.classList.remove("scroll-highlight");
        }
        if (transcriptElement && transcriptElement !== activeLineRef.current) {
          transcriptElement.classList.add("scroll-highlight");
          activeLineRef.current = transcriptElement as HTMLDivElement;
          transcriptElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    };

    videoElement.addEventListener("timeupdate", handleTimeUpdate);
    return () =>
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
  }, [parsedTranscript]);

  const highlightText = (searchString: string) => {
    if (!textContainerRef.current) return;

    const container = textContainerRef.current;

    // Function to recursively find text nodes and highlight them
    const recursiveHighlight = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeText = node.textContent || "";

        // Check if the node text contains the search string (partial match allowed)
        const searchIndex = nodeText
          .toLowerCase()
          .indexOf(searchString.toLowerCase());

        if (searchIndex !== -1) {
          // Split the text node into three parts: before, match, after
          const beforeMatch = nodeText.substring(0, searchIndex);
          const match = nodeText.substring(
            searchIndex,
            searchIndex + searchString.length
          );
          const afterMatch = nodeText.substring(
            searchIndex + searchString.length
          );

          // Replace the original text node with three new nodes: text-before, highlighted span, text-after
          const highlightedSpan = document.createElement("span");
          highlightedSpan.className = "highlight";
          highlightedSpan.textContent = match;

          const fragment = document.createDocumentFragment();
          if (beforeMatch)
            fragment.appendChild(document.createTextNode(beforeMatch));
          fragment.appendChild(highlightedSpan);
          if (afterMatch)
            fragment.appendChild(document.createTextNode(afterMatch));

          node.parentNode?.replaceChild(fragment, node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.hasChildNodes()) {
        node.childNodes.forEach((child) => recursiveHighlight(child));
      }
    };

    // Remove any previous highlights
    const removePreviousHighlights = () => {
      const highlightedElements = container.querySelectorAll(".highlight");
      highlightedElements.forEach((el) => {
        const parent = el.parentNode;
        if (parent) {
          parent.replaceChild(
            document.createTextNode(el.textContent || ""),
            el
          );
          parent.normalize(); // Merge text nodes
        }
      });
    };

    // Remove previous highlights before applying a new one
    removePreviousHighlights();

    // Apply highlights for the search string
    recursiveHighlight(container);

    // Scroll to the first occurrence of the highlighted element
    const firstHighlight = container.querySelector(".highlight");
    if (firstHighlight) {
      firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    // If remainingSeconds is less than 10, prepend it with a 0 (e.g., 1:05 instead of 1:5)
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  return (
    <TranscriptWrapper video_url={video_url}>
      {video_url && (
        <div className="video-wrapper">
          <video ref={videoRef} src={video_url} controls />
        </div>
      )}
      <div className="search-wrapper">
        <SearchBar
          value={highlightedText}
          updateFunction={setHighlightedText}
          submitFunction={() => highlightText(highlightedText)}
        />
        <GreenButton
          onClick={() => highlightText(highlightedText)}
          width="125px"
          height={"30"}
          fontSize={12}
        >
          Search Transcript
        </GreenButton>
      </div>
      {transcript && video_url && (
        <div ref={textContainerRef} className="transcript-content">
          {parsedTranscript.map((line) => (
            <TranscriptLine
              key={line.start}
              id={`line-${line.start.toFixed(2).replace(".", "_")}`}
            >
              <AvatarWrapper>
                <Avatar
                  color={getColorForSpeaker(line.speaker)}
                  textColor={darkenColor(getColorForSpeaker(line.speaker), 80)}
                >
                  {line.speaker[0]}
                </Avatar>
              </AvatarWrapper>
              <MessageWrapper>
                <SpeakerName>
                  {line.speaker}
                  <Timestamp>
                    {formatTime(line.start)} - {formatTime(line.end)}
                  </Timestamp>
                </SpeakerName>
                <div className="message-content-wrapper">{line.content}</div>
              </MessageWrapper>
            </TranscriptLine>
          ))}
        </div>
      )}
      {transcript && !video_url && (
        <div
          ref={textContainerRef}
          dangerouslySetInnerHTML={{
            __html: transcript,
          }}
          className="transcript-content"
        />
      )}
    </TranscriptWrapper>
  );
};

export default Transcript;
