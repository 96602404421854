import styled, { keyframes } from "styled-components";
import { BarChart } from "recharts";
import { colors } from "colors";

export const ChartContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #4b4b4b;
  padding: 20px;
  min-height: 500px;
  height: 500px;
  color: white;
  margin-top: 20px;
  border-radius: 5px; // Added rounded edges
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // Added drop shadow
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* Add these new styles for the hover effect */
  .recharts-tooltip-cursor {
    fill: rgba(200, 200, 200, 0.1);
  }
`;

export const FilterSelect = styled.select`
  padding: 4px 8px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: ${colors.black};
  font-size: 14px;
  color: ${colors.white};
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const ComparisonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 25px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const FilterButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #555;
  }
`;

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

export const NoContentMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-size: 30px;
`;

export const SkeletonBar = styled.rect`
  fill: #333333;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

export const StyledBarChart = styled(BarChart)`
  margin: 0;
  width: 100%;
  .recharts-cartesian-axis-tick-value {
    fill: white;
  }
`;
