import React, { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DealNotesWrapper, StyledQuillWrapper } from "./DealNotes.styled";
import { postDealNotes, getDealNotes } from "api/helpers";
import { debounce } from 'lodash';

interface DealNotesProps {
  isOpen: boolean;
  onClose: () => void;
  dealId: string;
}

const DealNotes: React.FC<DealNotesProps> = ({ isOpen, onClose, dealId }) => {
  const [notes, setNotes] = useState("");
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'unsaved'>('saved');

  useEffect(() => {
    const fetchNotes = async () => {
      const existingNotes = await getDealNotes(dealId);
      setNotes(existingNotes.notes);
    };
    if (dealId) {
      fetchNotes();
    }
  }, [dealId]);

  const debouncedSave = useCallback(
    debounce(async (content: string) => {
      try {
        setSaveStatus('saving');
        await postDealNotes(dealId, { notes: content });
        setSaveStatus('saved');
      } catch (error) {
        console.error("Error saving notes:", error);
        setSaveStatus('unsaved');
      }
    }, 1000),
    [dealId]
  );

  const handleNotesChange = (content: string) => {
    setNotes(content);
    setSaveStatus('unsaved');
    debouncedSave(content);
  };

  const handleClose = async () => {
    debouncedSave.flush(); // Ensure any pending changes are saved
    onClose();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      debouncedSave.flush(); // Execute any pending debounced saves
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      debouncedSave.cancel(); // Cancel any pending debounced saves
    };
  }, [debouncedSave]);

  return (
    <DealNotesWrapper isOpen={isOpen}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Deal Notes</h2>
        <div>
          <span style={{ 
            fontSize: '0.9em', 
            color: saveStatus === 'saved' ? '#4CAF50' : 
                   saveStatus === 'saving' ? '#FFA500' : '#FF0000',
            marginRight: '1rem',
            position: 'absolute',
            top: '30px',
            right: '95px'
          }}>
            {saveStatus === 'saved' ? '✓ Saved' : 
             saveStatus === 'saving' ? 'Saving...' : 'Unsaved'}
          </span>
          <button className="close-button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
      <StyledQuillWrapper>
        <ReactQuill
          value={notes}
          onChange={handleNotesChange}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline", "strike"],
              [{ align: [] }],
              ["link"],
              ["clean"],
            ],
          }}
        />
      </StyledQuillWrapper>
    </DealNotesWrapper>
  );
};

export default DealNotes;