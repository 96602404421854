import React from "react"

const ArrowsPointLeft = () => (
  <svg
    width="315"
    height="432"
    viewBox="0 0 315 432"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.1">
      <path
        d="M17.745 210.739C17.7523 209.59 18.1828 208.445 19.0953 207.544L56.0214 171.087C57.7856 169.345 60.6871 169.364 62.4288 171.128C64.1706 172.892 64.152 175.793 62.3878 177.535L28.6859 210.809L61.9596 244.511C63.7013 246.275 63.6828 249.176 61.9186 250.918C60.1544 252.66 57.2529 252.641 55.5112 250.877L19.0543 213.951C18.1534 213.038 17.7377 211.887 17.745 210.739Z"
        fill="url(#paint0_linear_1049_18375)"
      />
      <path
        d="M18.7689 210.744C18.7848 208.266 20.8531 206.224 23.3314 206.24L125.064 206.89C127.542 206.906 129.585 208.975 129.569 211.453C129.553 213.931 127.484 215.973 125.006 215.958L23.2735 215.307C20.7951 215.291 18.7531 213.223 18.7689 210.744Z"
        fill="url(#paint1_linear_1049_18375)"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M207.718 314.259C207.725 313.11 208.155 311.964 209.068 311.063L245.994 274.607C247.758 272.865 250.66 272.883 252.401 274.648C254.143 276.412 254.125 279.313 252.361 281.055L218.659 314.329L251.932 348.031C253.674 349.795 253.655 352.696 251.891 354.438C250.127 356.18 247.226 356.161 245.484 354.397L209.027 317.471C208.126 316.558 207.71 315.407 207.718 314.259Z"
        fill="url(#paint2_linear_1049_18375)"
      />
      <path
        d="M208.742 314.264C208.757 311.786 210.826 309.744 213.304 309.76L315.037 310.41C317.515 310.426 319.557 312.494 319.541 314.973C319.525 317.451 317.457 319.493 314.979 319.477L213.246 318.827C210.768 318.811 208.726 316.743 208.742 314.264Z"
        fill="url(#paint3_linear_1049_18375)"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M127.343 272.503C127.35 271.354 127.78 270.208 128.693 269.307L165.619 232.851C167.383 231.109 170.285 231.127 172.026 232.892C173.768 234.656 173.75 237.557 171.986 239.299L138.284 272.573L171.557 306.274C173.299 308.039 173.28 310.94 171.516 312.682C169.752 314.424 166.851 314.405 165.109 312.641L128.652 275.715C127.751 274.802 127.335 273.651 127.343 272.503Z"
        fill="url(#paint4_linear_1049_18375)"
      />
      <path
        d="M128.367 272.508C128.382 270.03 130.451 267.988 132.929 268.004L234.662 268.654C237.14 268.67 239.182 270.738 239.166 273.217C239.15 275.695 237.082 277.737 234.604 277.721L132.871 277.071C130.393 277.055 128.351 274.987 128.367 272.508Z"
        fill="url(#paint5_linear_1049_18375)"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M286.147 358.357C286.155 357.209 286.585 356.063 287.498 355.162L324.424 318.705C326.188 316.963 329.089 316.982 330.831 318.746C332.573 320.51 332.554 323.412 330.79 325.153L297.088 358.427L330.362 392.129C332.104 393.893 332.085 396.795 330.321 398.536C328.557 400.278 325.655 400.26 323.914 398.495L287.457 361.569C286.556 360.657 286.14 359.506 286.147 358.357Z"
        fill="url(#paint6_linear_1049_18375)"
      />
      <path
        d="M287.171 358.363C287.187 355.884 289.255 353.842 291.734 353.858L393.466 354.509C395.945 354.524 397.987 356.593 397.971 359.071C397.955 361.549 395.887 363.592 393.408 363.576L291.676 362.925C289.197 362.909 287.155 360.841 287.171 358.363Z"
        fill="url(#paint7_linear_1049_18375)"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M286.192 272.387C286.2 271.239 286.63 270.093 287.543 269.192L324.469 232.735C326.233 230.994 329.134 231.012 330.876 232.776C332.618 234.54 332.599 237.442 330.835 239.184L297.133 272.457L330.407 306.159C332.149 307.923 332.13 310.825 330.366 312.567C328.602 314.308 325.7 314.29 323.958 312.526L287.502 275.6C286.601 274.687 286.185 273.536 286.192 272.387Z"
        fill="url(#paint8_linear_1049_18375)"
      />
      <path
        d="M287.216 272.393C287.232 269.914 289.3 267.872 291.779 267.888L393.511 268.539C395.99 268.555 398.032 270.623 398.016 273.101C398 275.58 395.932 277.622 393.453 277.606L291.721 276.955C289.242 276.939 287.2 274.871 287.216 272.393Z"
        fill="url(#paint9_linear_1049_18375)"
      />
    </g>
    <g opacity="0.5" clip-path="url(#clip0_1049_18375)">
      <path
        d="M293.745 72.7609C293.752 71.6124 294.183 70.4666 295.095 69.5657L332.021 33.1089C333.786 31.3671 336.687 31.3856 338.429 33.1498C340.171 34.914 340.152 37.8155 338.388 39.5572L304.686 72.8308L337.96 106.533C339.701 108.297 339.683 111.198 337.919 112.94C336.154 114.682 333.253 114.663 331.511 112.899L295.054 75.9731C294.153 75.0606 293.738 73.9094 293.745 72.7609Z"
        fill="url(#paint10_linear_1049_18375)"
      />
      <path
        d="M294.769 72.7664C294.785 70.2881 296.853 68.246 299.331 68.2619L401.064 68.9124C403.542 68.9283 405.585 70.9966 405.569 73.4749C405.553 75.9533 403.484 77.9953 401.006 77.9795L299.273 77.329C296.795 77.3131 294.753 75.2448 294.769 72.7664Z"
        fill="url(#paint11_linear_1049_18375)"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M133.349 168.453C133.356 167.304 133.786 166.159 134.699 165.258L171.625 128.801C173.389 127.059 176.291 127.078 178.032 128.842C179.774 130.606 179.756 133.507 177.991 135.249L144.289 168.523L177.563 202.225C179.305 203.989 179.286 206.89 177.522 208.632C175.758 210.374 172.856 210.355 171.115 208.591L134.658 171.665C133.757 170.753 133.341 169.601 133.349 168.453Z"
        fill="url(#paint12_linear_1049_18375)"
      />
      <path
        d="M134.372 168.458C134.388 165.98 136.457 163.938 138.935 163.954L240.668 164.604C243.146 164.62 245.188 166.689 245.172 169.167C245.156 171.645 243.088 173.687 240.61 173.672L138.877 173.021C136.399 173.005 134.357 170.937 134.372 168.458Z"
        fill="url(#paint13_linear_1049_18375)"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M211.778 212.551C211.786 211.403 212.216 210.257 213.129 209.356L250.055 172.899C251.819 171.158 254.72 171.176 256.462 172.94C258.204 174.705 258.185 177.606 256.421 179.348L222.719 212.621L255.993 246.323C257.735 248.087 257.716 250.989 255.952 252.731C254.188 254.472 251.286 254.454 249.544 252.69L213.088 215.764C212.187 214.851 211.771 213.7 211.778 212.551Z"
        fill="url(#paint14_linear_1049_18375)"
      />
      <path
        d="M212.802 212.557C212.818 210.079 214.886 208.037 217.365 208.053L319.097 208.703C321.576 208.719 323.618 210.787 323.602 213.266C323.586 215.744 321.518 217.786 319.039 217.77L217.307 217.12C214.828 217.104 212.786 215.035 212.802 212.557Z"
        fill="url(#paint15_linear_1049_18375)"
      />
    </g>
    <g opacity="0.5" clip-path="url(#clip1_1049_18375)">
      <path
        d="M211.823 126.582C211.83 125.433 212.261 124.287 213.173 123.386L250.1 86.9295C251.864 85.1878 254.765 85.2063 256.507 86.9705C258.249 88.7347 258.23 91.6361 256.466 93.3779L222.764 126.652L256.038 160.353C257.779 162.118 257.761 165.019 255.997 166.761C254.233 168.503 251.331 168.484 249.589 166.72L213.132 129.794C212.232 128.881 211.816 127.73 211.823 126.582Z"
        fill="url(#paint16_linear_1049_18375)"
      />
      <path
        d="M212.847 126.587C212.863 124.109 214.931 122.067 217.41 122.083L319.142 122.733C321.621 122.749 323.663 124.817 323.647 127.296C323.631 129.774 321.563 131.816 319.084 131.8L217.352 131.15C214.873 131.134 212.831 129.065 212.847 126.587Z"
        fill="url(#paint17_linear_1049_18375)"
      />
      <g opacity="0.5">
        <path
          d="M283.915 167.681C283.922 166.532 284.353 165.386 285.265 164.485L322.191 128.029C323.956 126.287 326.857 126.305 328.599 128.07C330.341 129.834 330.322 132.735 328.558 134.477L294.856 167.751L328.129 201.453C329.871 203.217 329.853 206.118 328.089 207.86C326.324 209.602 323.423 209.583 321.681 207.819L285.224 170.893C284.323 169.98 283.908 168.829 283.915 167.681Z"
          fill="url(#paint18_linear_1049_18375)"
        />
        <path
          d="M284.939 167.686C284.955 165.208 287.023 163.166 289.501 163.182L391.234 163.832C393.712 163.848 395.754 165.917 395.739 168.395C395.723 170.873 393.654 172.915 391.176 172.899L289.443 172.249C286.965 172.233 284.923 170.165 284.939 167.686Z"
          fill="url(#paint19_linear_1049_18375)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1049_18375"
        x1="63.4884"
        y1="211.031"
        x2="17.745"
        y2="210.739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1049_18375"
        x1="129.569"
        y1="211.453"
        x2="18.7689"
        y2="210.744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1049_18375"
        x1="253.461"
        y1="314.551"
        x2="207.718"
        y2="314.259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1049_18375"
        x1="319.541"
        y1="314.973"
        x2="208.742"
        y2="314.264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1049_18375"
        x1="173.086"
        y1="272.795"
        x2="127.343"
        y2="272.503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1049_18375"
        x1="239.166"
        y1="273.217"
        x2="128.367"
        y2="272.508"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1049_18375"
        x1="331.891"
        y1="358.65"
        x2="286.147"
        y2="358.357"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1049_18375"
        x1="397.971"
        y1="359.071"
        x2="287.171"
        y2="358.363"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1049_18375"
        x1="331.936"
        y1="272.68"
        x2="286.192"
        y2="272.387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1049_18375"
        x1="398.016"
        y1="273.101"
        x2="287.216"
        y2="272.393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1049_18375"
        x1="339.488"
        y1="73.0534"
        x2="293.745"
        y2="72.7609"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1049_18375"
        x1="405.569"
        y1="73.4749"
        x2="294.769"
        y2="72.7664"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1049_18375"
        x1="179.092"
        y1="168.745"
        x2="133.349"
        y2="168.453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1049_18375"
        x1="245.172"
        y1="169.167"
        x2="134.372"
        y2="168.458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_1049_18375"
        x1="257.522"
        y1="212.844"
        x2="211.778"
        y2="212.551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_1049_18375"
        x1="323.602"
        y1="213.266"
        x2="212.802"
        y2="212.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1049_18375"
        x1="257.567"
        y1="126.874"
        x2="211.823"
        y2="126.582"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_1049_18375"
        x1="323.647"
        y1="127.296"
        x2="212.847"
        y2="126.587"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_1049_18375"
        x1="329.658"
        y1="167.973"
        x2="283.915"
        y2="167.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_1049_18375"
        x1="395.739"
        y1="168.395"
        x2="284.939"
        y2="167.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7CDF4D" />
        <stop offset="1" stopColor="#4CABCA" />
      </linearGradient>
      <clipPath id="clip0_1049_18375">
        <rect
          width="145.076"
          height="145.076"
          fill="white"
          transform="translate(422.654 1.04346) rotate(90.3664)"
        />
      </clipPath>
      <clipPath id="clip1_1049_18375">
        <rect
          width="145.076"
          height="145.076"
          fill="white"
          transform="translate(340.732 54.8641) rotate(90.3664)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowsPointLeft
