import { colors } from 'colors';
import styled from 'styled-components';

// Styled components
export const DropdownContainer = styled.div`
  position: relative;
  width: 90px;
`;

export const SelectedPriority = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${colors.black};
  color: ${colors.white};
  font-size: 14px;
`;

export const OptionsContainer = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: white;
  z-index: 1000;
  color: ${colors.black};
  font-size: 14px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

export const Option = styled.li<{ priority: string }>`
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${props => {
    switch (props.priority.toLowerCase()) {
      case 'high':
        return '#ff00005e' || '#ffcccc'; // Light red
      case 'medium':
        return '#ffa5005e' || '#ffffcc'; // Light yellow
      case 'low':
        return '#caff0063' || '#ccffcc'; // Light green
      default:
        return 'transparent';
    }
  }};

  &:hover {
    filter: brightness(0.9);
  }
`;