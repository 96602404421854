import { colors } from "colors";
import styled from "styled-components";

export const ResourcesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const LeftWrapper = styled.div`
  width: 33%;
  padding: 20px;
  height: 100%;
  .search-bar-wrapper {
    width: 100%;
    .search-input {
      width: 80%;
    }
  }
  .resources {
    border-right: 1px solid ${colors.middleGray};
    width: 100%;
    overflow-x: hidden;
    padding-top: 20px;
    h4 {
      margin-bottom: 40px;
    }
    .resource-scroll {
      overflow-y: auto;
      padding-bottom: 20px;
      height: 500px;
    }
  }
`;

export const RightWrapper = styled.div`
  width: 67%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 40px;
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .resource-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .resources {
    width: 100%;
  }
  button {
    background-color: ${colors.lightGray};
    color: ${colors.black};
    padding: 12px 6px;
    border-radius: 3px;
    width: 100px;
    height: 35px;
    border: none;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const File = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  width: 310px;
  margin-bottom: 20px;
  &:hover {
    background-color: ${colors.middleGray};
    border-radius: 5px;
    box-shadow: 0px 3px 18px 0px #0000001a, 0px 7px 33px 0px #00000017,
      0px 7px 7px 0px #0000000d, 0px 51px 20px 0px #00000003,
      0px 1px 2px 0px #00000000;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
  .info-wrapper {
    p {
      max-height: 65px;
    }
    .extension {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: ${colors.lightGray};
      margin: 0;
      margin-bottom: 10px;
    }
    .small {
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.lightGray};
      margin: 0;
    }
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    background-color: ${colors.white};
    border-radius: 8px;
    margin-right: 15px;
    svg {
      height: 28px;
      width: 28px;
      path {
        fill: ${colors.red};
      }
    }
  }
`;

export const FullFile = styled.div`
  width: 295px;
  height: 305px;
  border-radius: 8px;
  margin-right: 25px;
  margin-bottom: 25px;
  .gray {
    width: 100%;
    height: 20%;
    min-height: 20%;
    background-color: ${colors.lightGray};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      cursor: pointer;
      width: 25px;
      height: 25px;
      padding-right: 15px;
      path {
        stroke: ${colors.middleGray}
      }
    }
  }
  .bottom {
    background-color: ${colors.black};
    height: 80%;
    padding: 0 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding-top: 50px;
  }
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.02);
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
  .info-wrapper {
    .extension {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: ${colors.lightGray};
      margin: 0;
      margin-bottom: 10px;
    }
    .small {
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.lightGray};
      margin: 0;
    }
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    width: 56px;
    height: 56px;
    background-color: ${colors.white};
    border-radius: 8px;
    margin-right: 15px;
    position: absolute;
    top: -25px;
    left: 20px;
    svg {
      height: 28px;
      width: 28px;
      path {
        fill: ${colors.red};
      }
    }
  }
`;
