import React from "react";

const Slack = () => {
  return (
    <svg
      width="105"
      height="105"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rect"
        x="0.585938"
        y="0.0681152"
        width="104.147"
        height="104.147"
        rx="31.244"
        fill="#22242F"
      />
      <path
        d="M79.999 49.5373C83.5936 49.5373 86.5077 46.6233 86.5077 43.0287C86.5077 39.4341 83.5934 36.5201 79.9988 36.5201C76.4039 36.5201 73.4893 39.4343 73.4893 43.0293V49.5373H79.999ZM61.7728 49.5373C65.3677 49.5373 68.282 46.6231 68.282 43.0281V24.8036C68.282 21.2087 65.3677 18.2944 61.7728 18.2944C58.1779 18.2944 55.2637 21.2087 55.2637 24.8036V43.0281C55.2637 46.623 58.1779 49.5373 61.7728 49.5373Z"
        fill="#2EB67D"
      />
      <path
        d="M25.3213 54.7457C21.7267 54.7457 18.8127 57.6597 18.8127 61.2543C18.8127 64.8489 21.7269 67.7629 25.3215 67.7629C28.9165 67.7629 31.831 64.8486 31.831 61.2537V54.7457H25.3213ZM43.5475 54.7457C39.9526 54.7457 37.0383 57.6599 37.0383 61.2548V79.4794C37.0383 83.0743 39.9526 85.9885 43.5475 85.9885C47.1424 85.9885 50.0566 83.0743 50.0566 79.4794V61.2548C50.0566 57.6599 47.1424 54.7457 43.5475 54.7457Z"
        fill="#E01E5A"
      />
      <path
        d="M55.2649 79.4805C55.2649 83.0751 58.1789 85.9891 61.7735 85.9891C65.3682 85.9891 68.2822 83.0748 68.2822 79.4802C68.2822 75.8853 65.3679 72.9708 61.773 72.9708L55.2649 72.9708L55.2649 79.4805ZM55.2649 61.2543C55.2649 64.8492 58.1792 67.7634 61.7741 67.7634L79.9986 67.7634C83.5936 67.7634 86.5078 64.8492 86.5078 61.2543C86.5078 57.6594 83.5936 54.7451 79.9986 54.7451L61.7741 54.7451C58.1792 54.7451 55.2649 57.6594 55.2649 61.2543Z"
        fill="#ECB22E"
      />
      <path
        d="M50.0554 24.8025C50.0554 21.2079 47.1414 18.2939 43.5468 18.2939C39.9522 18.2939 37.0382 21.2081 37.0382 24.8027C37.0382 28.3977 39.9524 31.3122 43.5473 31.3122L50.0554 31.3122L50.0554 24.8025ZM50.0554 43.0287C50.0554 39.4338 47.1411 36.5195 43.5462 36.5195L25.3217 36.5195C21.7268 36.5195 18.8125 39.4338 18.8125 43.0287C18.8125 46.6236 21.7268 49.5378 25.3217 49.5378L43.5462 49.5378C47.1411 49.5378 50.0554 46.6236 50.0554 43.0287Z"
        fill="#36C5F0"
      />
    </svg>
  );
};

export default Slack;
