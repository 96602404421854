const queryCMS = async (query: string) => {
  try {
    const response = await fetch("https://graphql.datocms.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
        ...(process.env.REACT_APP_ENV !== "production" && {
          "X-Include-Drafts": "true",
        }),
      },
      body: JSON.stringify({
        query,
      }),
    });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const getFAQs = async () => {
  let response = await queryCMS(`
  {
    allFaqs {
      question
      answer
      icon {
        url
      }
    }
  }
`);
  return response;
};

export const getHomepageData = async () => {
  let response = await queryCMS(`
  {
    homePage {
      headerButtonText
      headerSubtext
      headerText {
        value
      }
      saveTimeHeader {
        value
      }
      realTimeHeader {
        value
      }
      saveTimeSubHeader
      personalizedHeader {
        value
      }
      personalizedSubheader
      personalizedButtonText
      personalizedImage {
        url
      }
      carouselImages {
        url
      }
      heroImage {
        url
      }
    }
  }
`);
  return response;
};

export const getPrivacyData = async () => {
  let response = await queryCMS(`
  {
    privacy {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getZoomScopesData = async () => {
  let response = await queryCMS(`
  {
    privacy {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getZoomInstructionsData = async () => {
  let response = await queryCMS(`
  {
    privacy {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getDPAData = async () => {
  let response = await queryCMS(`
  {
    dpa {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getSLAData = async () => {
  let response = await queryCMS(`
  {
    sla {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getTermsData = async () => {
  let response = await queryCMS(`
  {
    term {
      text {
        value
      }
    }
  }
`);
  return response;
};

export const getRepSolutionsData = async () => {
  let response = await queryCMS(`
{
	solutionsSalesRep {
    title
    sections {
      title
      description {
        value
      }
      image {
        url
      }
    }
  }
}
`);
  return response;
};

export const getLeaderSolutionsData = async () => {
  let response = await queryCMS(`
{
	solutionsSalesLeader {
    title
    sections {
      title
      description {
        value
      }
      image {
        url
      }
    }
  }
}
`);
  return response;
};

export const getEnablementSolutionsData = async () => {
  let response = await queryCMS(`
{
	solutionsSalesEnablement {
    title
    sections {
      title
      description {
        value
      }
      image {
        url
      }
    }
  }
}
`);
  return response;
};

export const getSalesAutomationData = async () => {
  let response = await queryCMS(`
{
	salesAutomation {
    title
    sections {
      title
      description {
        value
      }
      image {
        url
      }
    }
  }
}
`);
  return response;
};

export const getWinningBehaviorsData = async () => {
  let response = await queryCMS(`
{
	winningBehavior {
    title
    introParagraph
    sections {
      title
      description {
        value
      }
      image {
        url
      }
    }
  }
}
`);
  return response;
};

