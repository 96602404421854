import React from "react";

const Dollar = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Side Bar/vuesax/outline/Property 311">
      <g id="vuesax/outline/dollar-circle">
        <g id="dollar-circle">
          <path
            id="Vector"
            d="M17.5617 22.2746H14.4242C12.3742 22.2746 10.7117 20.5496 10.7117 18.4246C10.7117 17.9121 11.1367 17.4871 11.6492 17.4871C12.1617 17.4871 12.5867 17.9121 12.5867 18.4246C12.5867 19.5121 13.4117 20.3996 14.4242 20.3996H17.5617C18.3742 20.3996 19.0492 19.6746 19.0492 18.7996C19.0492 17.7121 18.6617 17.4996 18.0242 17.2746L12.9867 15.4996C12.0117 15.1621 10.6992 14.4371 10.6992 12.1996C10.6992 10.2746 12.2117 8.72461 14.0617 8.72461H17.1992C19.2492 8.72461 20.9117 10.4496 20.9117 12.5746C20.9117 13.0871 20.4867 13.5121 19.9742 13.5121C19.4617 13.5121 19.0367 13.0871 19.0367 12.5746C19.0367 11.4871 18.2117 10.5996 17.1992 10.5996H14.0617C13.2492 10.5996 12.5742 11.3246 12.5742 12.1996C12.5742 13.2871 12.9617 13.4996 13.5992 13.7246L18.6367 15.4996C19.6117 15.8371 20.9242 16.5621 20.9242 18.7996C20.9117 20.7121 19.4117 22.2746 17.5617 22.2746Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M15.8125 23.9375C15.3 23.9375 14.875 23.5125 14.875 23V8C14.875 7.4875 15.3 7.0625 15.8125 7.0625C16.325 7.0625 16.75 7.4875 16.75 8V23C16.75 23.5125 16.325 23.9375 15.8125 23.9375Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M15.8125 28.9375C8.4 28.9375 2.375 22.9125 2.375 15.5C2.375 8.0875 8.4 2.0625 15.8125 2.0625C23.225 2.0625 29.25 8.0875 29.25 15.5C29.25 22.9125 23.225 28.9375 15.8125 28.9375ZM15.8125 3.9375C9.4375 3.9375 4.25 9.125 4.25 15.5C4.25 21.875 9.4375 27.0625 15.8125 27.0625C22.1875 27.0625 27.375 21.875 27.375 15.5C27.375 9.125 22.1875 3.9375 15.8125 3.9375Z"
            fill="white"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Dollar;
