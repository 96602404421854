import React, { FC } from 'react';
import { LogoScrollerWrapper, ScrollingContent, Logo } from './LogoScroller.styled';

interface LogoScrollerProps {
  logos: string[];
}

const LogoScroller: FC<LogoScrollerProps> = ({ logos }) => {
  // Create an array that repeats the logos enough times for smooth scrolling
  const duplicatedLogos = [...logos, ...logos, ...logos]; // Triple the logos

  return (
    <LogoScrollerWrapper>
      <ScrollingContent>
        {duplicatedLogos.map((logo, index) => (
          <Logo key={index}>
            <img src={logo} alt={`Partner logo ${index + 1}`} />
          </Logo>
        ))}
      </ScrollingContent>
    </LogoScrollerWrapper>
  );
};

export default LogoScroller;