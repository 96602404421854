import React, { useState, useEffect } from "react"
import Minus from "static/svgs/Minus"
import Plus from "static/svgs/Plus"
import { FAQSectionWrapper, FAQAccordionWrapper } from "./FAQSection.styled"
import { getFAQs } from "cms/helpers"
type Props = {}

const FAQSection = (props: Props) => {
  const dummyFAQs = [
    {
      question: "Here's a question",
      answer: "Here's an answer",
    },
    {
      question: "Here's another question",
      answer: "Here's another answer",
    },
  ]
  type FAQ = {
    icon?: string
    question: string
    answer: string
  }
  const [FAQs, setFAQs] = useState<FAQ[]>([])
  const [selectedFAQ, setSelectedFAQ] = useState<number | null>(null)

  const handleSelectFAQ = (index: number) => {
    if (selectedFAQ !== index) {
      setSelectedFAQ(index)
    } else {
      setSelectedFAQ(null)
    }
  }

  useEffect(() => {
    const getfaqs = async () => {
      let data = await getFAQs()
      setFAQs(data.allFaqs.reverse())
    }
    getfaqs()
  }, [])

  return (
    <FAQSectionWrapper>
      <div className="faq-inner-wrapper">
        <h3>Your Questions Answered</h3>
        <h4>
          Dive into the frequently asked questions about integrating Leadoff.ai
          into your daily sales routines and maximizing your sales potential.
          Get the answers you need to unlock the full power of Leadoff.ai and
          take your sales game to the next level.
        </h4>
        <FAQAccordionWrapper>
          {FAQs?.map((faq, index) => {
            return (
              <button onClick={() => handleSelectFAQ(index)} className="faq">
                <div className="title-wrapper">
                  {selectedFAQ === index ? <Minus /> : <Plus />}{" "}
                  <h3>{faq.question}</h3>
                </div>
                {selectedFAQ === index && (
                  <div className="answer-wrapper">
                    <h4>{faq.answer}</h4>
                  </div>
                )}
              </button>
            )
          })}
        </FAQAccordionWrapper>
      </div>
    </FAQSectionWrapper>
  )
}

export default FAQSection
