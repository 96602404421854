import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading, setLoadingText } from "state/appActions";
import { ShareWrapper } from "./Share.styled";
import {
  AddDealForm,
  InputWrapper,
  Input,
  Label,
  TranscriptInput,
} from "components/AddDeal/AddDeal.styled";
import {
  GradientText,
  GradientButton,
} from "shared/shared_styled_comps/components.styled";
import { postDealShare } from "api/helpers";

interface ShareProps {
  setModal: Function;
  currentDealId?: string;
}

const ShareModal: FC<ShareProps> = ({ setModal, currentDealId }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("View my deal on Leadoff.ai");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const user = useSelector((state: any) => state.auth.user);
  let dispatch = useDispatch();

  const getEmailDomain = (email: string) => {
    return email.substring(email.lastIndexOf("@") + 1);
  };
  
  const validateEmailDomain = (userEmail: string, emailToValidate: string) => {
    const userDomain = getEmailDomain(userEmail);
    const emailDomain = getEmailDomain(emailToValidate);
  
    return userDomain === emailDomain;
  };

  const handleEmailSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data = {
      first_name: firstName,
      last_name: lastName,
      message,
      to_email: email,
    };
    if (!firstName || !lastName || !email) {
      setError("Please fill out all required fields.");
    } else if (!validateEmailDomain(user.email, email)) {
      setError("For security purposes, you can only share deals with someone on your same email domain.");
    } else {
      dispatch(startLoading());
      let response = await postDealShare(data, currentDealId);
      dispatch(endLoading());
      if (response?.status === "success") {
        setSuccess(true);
      } else {
        setError(
          "An unknown error occured sending the email. Please refresh the page and try again."
        );
      }
    }
  };

  return (
    <ShareWrapper className={success ? "success" : ""}>
      {success ? (
        <>
          <h2>Message sent!</h2>
          <h4>
            Have your colleague check their email shortly for a link to view
            your deal. They will need to signup or login to view.
          </h4>
        </>
      ) : (
        <>
          <h2>Share</h2>
          {error && <p className="error">{error}</p>}
          <AddDealForm onSubmit={(event) => handleEmailSend(event)}>
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>First Name*</Label>
                <Input
                  type="text"
                  placeholder="Full name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>Last Name*</Label>
                <Input
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </InputWrapper>
            </div>
            <InputWrapper>
              <Label>Company Email*</Label>
              <Input
                type="text"
                placeholder="yourmanager@yourcompany.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputWrapper>
            {/* <InputWrapper>
              <Label>Subject</Label>
              <Input
                type="text"
                placeholder=""
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </InputWrapper> */}
            <InputWrapper>
              <Label>Additional Message</Label>
              <TranscriptInput
                placeholder="Add any additional messaging here."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </InputWrapper>
            <GradientButton
              type="submit"
              width="80%"
              height="40px"
              fontSize={14}
              top={50}
            >
              <GradientText>Send message</GradientText>
            </GradientButton>
          </AddDealForm>
        </>
      )}
    </ShareWrapper>
  );
};

export default ShareModal;
