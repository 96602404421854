import { FC, useState } from "react";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
  Select,
} from "shared/shared_styled_comps/forms.styled";
import { SignupFormData } from "types";
import { Link } from "react-router-dom";
import { SignupForm, InnerFormWrapper } from "./Signup.styled";
import ToolTip from "components/ToolTip/ToolTip";

interface SignupForm2Props {
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  formData: SignupFormData;
  setStep: Function;
  step: number;
  handleSignup: Function;
  selectChangeHandler: Function;
}

const SignupForm2: FC<SignupForm2Props> = ({
  handleSignup,
  step,
  formData,
  handleInputChange,
  setStep,
  selectChangeHandler,
}) => {
  const [error, setError] = useState("");
  const [showOther, setShowOther] = useState(false);
  const [showLinkedinTool, setShowLinkedinTool] = useState(false);

  const validateLinkedIn = (url: string) => {
    var urlRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
    return urlRegex.test(url);
  };

  const handleCompletion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // if (formData.linkedin_url && validateLinkedIn(formData.linkedin_url)) {
    handleSignup();
    // } else {
    //   setError("Please add a valid linked URL.");
    // }
  };

  const industryHandler = (e: any) => {
    if (e.target.value === "Other") {
      setShowOther(true);
      selectChangeHandler(e);
    } else {
      selectChangeHandler(e);
    }
  };

  return (
    <InnerFormWrapper>
      <h1>Sign up</h1>
      <h2>One more thing...</h2>
      <SignupForm>
        {!formData.organization_id && (
          <LoginLabel>
            What company do you work for?
            <LoginInput
              type="text"
              name="organization_name"
              value={formData.organization_name}
              placeholder="Leadoff.ai"
              onChange={handleInputChange}
            />
          </LoginLabel>
        )}
        <LoginLabel>
          What's your linkedin URL?
          <div className="">
            <LoginInput
              type="text"
              name="linkedin_url"
              value={formData.linkedin_url}
              placeholder="https://www.linkedin.com/in/<your_username>/"
              onChange={handleInputChange}
            />
            <p className="subtext">
              Please share the URL to your LinkedIn profile so that Leadoff can
              support your growth as a rep, regardless of where you work. Our
              goal is to help you build a portfolio of sales successes and
              effective behaviors. This URL will serve as a unique identifier,
              allowing us to recognize you across accounts and provide tailored
              coaching and assistance throughout your career. Rest assured,
              while we track your past activity, we will never share deal data
              across different company accounts.
            </p>
            {/* <div
              className="tool-tip"
              onMouseEnter={() => setShowLinkedinTool(true)}
              onMouseLeave={() => setShowLinkedinTool(false)}
            >
              i
              {showLinkedinTool && (
                <ToolTip
                  pointSide="top"
                  text={
                    "Please share the URL to your LinkedIn profile so that Leadoff can support your growth as a rep, regardless of where you work. Our goal is to help you build a portfolio of sales successes and effective behaviors. This URL will serve as a unique identifier, allowing us to recognize you across accounts and provide tailored coaching and assistance throughout your career. Rest assured, while we track your past activity, we will never share deal data across different company accounts."
                  }
                ></ToolTip>
              )}
            </div> */}
          </div>
        </LoginLabel>
        <LoginLabel>
          What's your role?
          <Select
            name="role"
            value={formData.role}
            onChange={(e) => selectChangeHandler(e)}
          >
            <option value={""}>Select a role</option>
            <option value={"BDR/SDR"}>BDR/SDR</option>
            <option value={"SMB Account Executive"}>
              SMB Account Executive
            </option>
            <option value={"Mid-Market Account Executive"}>
              Mid-Market Account Executive
            </option>
            <option value={"Enterprise Account Executive"}>
              Enterprise Account Executive
            </option>
            <option value={"Global and Strategic Account Executive"}>
              Global and Strategic Account Executive
            </option>
            <option value={"Sales Manager"}>Sales Manager</option>
            <option value={"Sales Leader"}>Sales Leader</option>
            <option value={"Customer Success Manager"}>
              Customer Success Manager
            </option>
            <option value={"Sales/Revenue Enablement"}>
              Sales/Revenue Enablement
            </option>
            <option value={"Sales Engineer/Solutions Consultant"}>
              Sales Engineer/Solutions Consultant
            </option>
            <option value={"Other"}>Other</option>
          </Select>
        </LoginLabel>
        {/* <LoginLabel>
          What's your industry?
          <Select
            name="industry"
            value={formData.industry}
            onChange={(e) => industryHandler(e)}
          >
            <option value={""}>Select an industry</option>
            <option value={"Communications"}>Communications</option>
            <option value={"Media"}>Media</option>
            <option value={"Professional Services"}>
              Professional Services
            </option>
            <option value={"High Tech"}>High Tech</option>
            <option value={"Retail/Ecommerce"}>Retail/Ecommerce</option>
            <option value={"Financial Services"}>Financial Services</option>
            <option value={"Consumer Goods and Technology"}>
              Consumer Goods and Technology
            </option>
            <option value={"Travel & Hospitality"}>Travel & Hospitality</option>
            <option value={"Healthcare"}>Healthcare</option>
            <option value={"Education"}>Education</option>
            <option value={"Other"}>Other</option>
          </Select>
          {showOther && <LoginLabel>
            Elaborate:
            <LoginInput
              type="text"
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
            />
          </LoginLabel>}
        </LoginLabel> */}
        {!formData.organization_id && (
          <LoginLabel>
            Select a sales methodology
            <Select
              name="default_method_name"
              value={formData.default_method_name}
              onChange={(e) => selectChangeHandler(e)}
            >
              <option value={"MEDDPICC"}>MEDDPICC</option>
              <option value={"MEDDIC"}>MEDDIC</option>
              <option value={"BANT"}>BANT</option>
            </Select>
          </LoginLabel>
        )}
        <label className="terms">
          <input
            type="checkbox"
            name="termsChecked"
            checked={formData.termsChecked}
            onChange={handleInputChange}
          />
          <strong>I agree to the Main Services Agreement.</strong> By signing up
          for&nbsp;
          <Link to="/">Leadoff.ai</Link>I agree to Leadoff’s&nbsp;
          <Link to="/terms">General Terms and Conditions</Link> and&nbsp;
          <Link to="/privacy">Privacy Policy.</Link>
        </label>
        <div className="button-text-wrapper">
          <p>Step {step}/2</p>
          <div className="buttons-wrapper">
            <div className="button-wrapper">
              <SquareButton inverse onClick={() => setStep(1)}>
                Back
              </SquareButton>
            </div>
            <div className="button-wrapper">
              <SquareButton onClick={(e) => handleCompletion(e)}>
                Submit
              </SquareButton>
            </div>
          </div>
        </div>
        {error && <p className="error">{error}</p>}
      </SignupForm>
      <div className="logos-wrapper"></div>
    </InnerFormWrapper>
  );
};

export default SignupForm2;
