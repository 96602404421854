import { RefObject } from "react"
import styled from "styled-components"

interface SideBarWrapperProps {
  completedIntroTour: boolean
  ref: any
}

export const SideBarWrapper = styled.div<SideBarWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  z-index: 1000;
  background-color: #333; /* Dark gray color */
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-top: 40px;
  .large {
    display: none;
  }
  svg {
    width: 30px;
    height: 30px;
  }
  h4 {
    display: none;
  }
  .button-wrapper {
    background-color: transparent;
  }

  &:hover {
    width: 277px; /* Change width on hover */
    h4 {
      display: block;
    }
    .outer-circle {
      width: 175px;
      border: none;
      background-color: white;
      border-radius: 222px;
      justify-content: flex-start;
      padding-left: 10px;
    }
    .inner-circle {
      margin-right: 7px;
    }
    .large {
      display: block;
      svg {
        width: 172px;
        height: 70px;
        path {
          fill: white;
        }
      }
    }
    .top {
      margin-bottom: 17px !important;
    }
    .button-wrapper.selected {
      position: relative;
      background-color: #9b9090 !important;
      padding: 15px 0;
      border-radius: 50px 0 0 50px;
      width: 90%;
      margin: 0 auto;
      margin-right: 0;
      margin-bottom: 15px;
    }
    .small {
      display: none;
    }
  }
`

interface SideBarButton {
  large?: boolean
  selected?: boolean
}

export const SideBarButton = styled.button<SideBarButton>`
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  color: white;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: space-between;
  ${(props) =>
    props.large &&
    `
    margin-bottom: 50px;
    svg {
      width: 46px;
      height: 46px;
    }
  `}
  .outer-circle {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.selected
        ? `
        border: 2px solid white;
        h4 {
          color: black;
        }
    `
        : `
        background-color: transparent !important;
        &:hover{
          background-color: white !important;
          svg {
            path {
              fill: #333;
              stroke: #333;
            }
            rect {
              stroke: #333;
            }
          }
          color: black;
        }
    `}
  }
  .inner-circle {
    width: 39px;
    height: 39px;
    border-radius: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.selected &&
      `
        background: linear-gradient(180deg, #7CDF4D 0%, #4CABCA 100%);
        svg {
          path {
            fill: #333;
            stroke: #333;
          }
        }
    `}
  }
`

interface SideBarButton {
  large?: boolean
  selected?: boolean
}

export const ButtonWrapper = styled.div<SideBarButton>`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  position: relative;
  ${(props) =>
    props.selected &&
    `
        background-color: #9b9090;
      `}
`

export const BottomButtons = styled.div`
  height: fit-content;
`

export const AddDealWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(120, 120, 120, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(4px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const AddDealModal = styled.div``
