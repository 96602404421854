import React from "react";

const Documents = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Side Bar/vuesax/outline/Deal13">
        <g id="vuesax/outline/note-2">
          <g id="note-2">
            <path
              id="Vector"
              d="M19.7621 25.2989C19.4371 25.2989 19.0996 25.2864 18.7371 25.2489C18.0871 25.1989 17.3496 25.0739 16.5871 24.8864L14.4871 24.3864C8.7246 23.0239 6.8371 19.9739 8.1871 14.2239L9.4121 8.98637C9.6871 7.79887 10.0121 6.83637 10.4121 6.03637C12.5621 1.59887 16.6746 1.99887 19.5996 2.68637L21.6871 3.17387C24.6121 3.86137 26.4621 4.94887 27.4996 6.61137C28.5246 8.27387 28.6871 10.4114 27.9996 13.3364L26.7746 18.5614C25.6996 23.1364 23.4621 25.2989 19.7621 25.2989ZM16.3996 4.13637C14.3121 4.13637 12.9871 4.99887 12.0996 6.84887C11.7746 7.52387 11.4871 8.36137 11.2371 9.41137L10.0121 14.6489C8.8996 19.3739 10.1871 21.4364 14.9121 22.5614L17.0121 23.0614C17.6871 23.2239 18.3246 23.3239 18.8996 23.3739C22.2996 23.7114 23.9871 22.2239 24.9371 18.1364L26.1621 12.9114C26.7246 10.4989 26.6496 8.81137 25.8996 7.59887C25.1496 6.38637 23.6746 5.56137 21.2496 4.99887L19.1621 4.51137C18.1246 4.26137 17.1996 4.13637 16.3996 4.13637Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M10.4123 27.8863C7.19982 27.8863 5.14982 25.9613 3.83732 21.8988L2.23732 16.9613C0.46232 11.4613 2.04982 8.36132 7.52482 6.58632L9.49982 5.94882C10.1498 5.74882 10.6373 5.61132 11.0748 5.53632C11.4373 5.46132 11.7873 5.59882 11.9998 5.88632C12.2123 6.17382 12.2498 6.54882 12.0998 6.87382C11.7748 7.53632 11.4873 8.37382 11.2498 9.42382L10.0248 14.6613C8.91232 19.3863 10.1998 21.4488 14.9248 22.5738L17.0248 23.0738C17.6998 23.2363 18.3373 23.3363 18.9123 23.3863C19.3123 23.4238 19.6373 23.6988 19.7498 24.0863C19.8498 24.4738 19.6998 24.8738 19.3748 25.0988C18.5498 25.6613 17.5123 26.1363 16.1998 26.5613L14.2248 27.2113C12.7873 27.6613 11.5373 27.8863 10.4123 27.8863ZM9.72482 7.84882L8.11232 8.37382C3.64982 9.81132 2.58732 11.9113 4.02482 16.3863L5.62482 21.3238C7.07482 25.7863 9.17482 26.8613 13.6373 25.4238L15.6123 24.7738C15.6873 24.7488 15.7498 24.7238 15.8248 24.6988L14.4998 24.3863C8.73732 23.0238 6.84982 19.9738 8.19982 14.2238L9.42482 8.98632C9.51232 8.58632 9.61232 8.19882 9.72482 7.84882Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M21.8627 13.2113C21.7877 13.2113 21.7127 13.1988 21.6252 13.1863L15.5627 11.6488C15.0627 11.5238 14.7627 11.0113 14.8877 10.5113C15.0127 10.0113 15.5252 9.71132 16.0252 9.83632L22.0877 11.3738C22.5877 11.4988 22.8877 12.0113 22.7627 12.5113C22.6627 12.9238 22.2752 13.2113 21.8627 13.2113Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M18.2004 17.4363C18.1254 17.4363 18.0504 17.4238 17.9629 17.4113L14.3254 16.4863C13.8254 16.3613 13.5254 15.8488 13.6504 15.3488C13.7754 14.8488 14.2879 14.5488 14.7879 14.6738L18.4254 15.5988C18.9254 15.7238 19.2254 16.2363 19.1004 16.7363C19.0004 17.1613 18.6254 17.4363 18.2004 17.4363Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Documents;
