import { FC, useState, useEffect, useRef } from "react";
import {
  NotificationsWrapper,
  NotificationWrapper,
} from "./Notifications.styled";
import Notification from "static/svgs/Notification";
import { Notifications as NotificationType } from "types";
import { getNotifications, putNotification } from "api/helpers";
import { useNavigate } from "react-router-dom";
import Dollar from "static/svgs/Dollar";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import Keyboard from "static/svgs/Keyboard";

interface NotificationsProps {}

const Notifications: FC<NotificationsProps> = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      const response = await getNotifications();
      setNotifications(response.notification_list);
      const unread = response?.notification_list?.filter(
        (notification: NotificationType) => !notification.read
      ).length;
      setUnreadCount(unread);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 10000); // Poll every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // Prevents the event from bubbling up to the parent
    setOpen(!open);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleRead = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: NotificationType
  ) => {
    e.stopPropagation();

    if (!notification.read) {
      setNotifications((prevNotifications) =>
        prevNotifications.map((inner_notification) =>
          inner_notification._id === notification._id
            ? { ...inner_notification, read: true }
            : inner_notification
        )
      );
      await putNotification(notification._id);
      setUnreadCount((prevUnreadCount) => prevUnreadCount - 1);
    }
    if (notification.deal_id) {
      setOpen(false);
      navigate(`/app/deals/${notification.deal_id}`);
    }
  };

  const setSymlbol = (type: string) => {
    if (type === "Deal") {
      return <Dollar />;
    }
    if (type === "Comment") {
      return <Keyboard />;
    }
   return <LeadoffRobot />;
  };

  return (
    <NotificationsWrapper>
      <div className="notification-icon" onClick={handleOpen}>
        <Notification />
        {unreadCount > 0 && (
          <div className="alert">{unreadCount > 9 ? "9+" : unreadCount}</div>
        )}
      </div>
      {open && (
        <div ref={dropdownRef} className="notifications-dropdown">
          {notifications?.length === 0 ? <h3>No notifications</h3> :<h3>Notifications</h3>}
          {notifications &&
            notifications.map((notification) => (
              <NotificationWrapper
                read={notification.read}
                onClick={(e) => handleRead(e, notification)}
              >
                {setSymlbol(notification.type)}
                {notification.message}
              </NotificationWrapper>
            ))}
        </div>
      )}
    </NotificationsWrapper>
  );
};

export default Notifications;
