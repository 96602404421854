import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  form {
    max-width: 500px;
  }
`;
