import styled from "styled-components";

export const ImpersonateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  form {
    max-width: 500px;
    padding-top: 20%;
  }
`;
