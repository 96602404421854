import styled from "styled-components";
import { colors } from "colors";

interface FloatingWrapperProps {
  width: string;
  height: string;
}

export const FloatingWrapper = styled.div<FloatingWrapperProps>`
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  box-shadow: ${colors.boxShadow3};
  border: 1px solid ${colors.middleGray};
  overflow: scroll;
  padding: 20px 30px;
  padding: 30px 20px;
  overflow: hidden;
  border-radius: 3px;
`;

interface GreenButtonProps {
  width: string;
  height: number | string;
  fontSize: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingleft?: number;
  paddingright?: number;
}

export const GreenButton = styled.button<GreenButtonProps>`
  font-weight: 700;
  border-radius: 2px;
  background-color: ${colors.green};
  color: ${colors.white};
  cursor: pointer;
  position: relative;
  border: none;
  ${(props) => props.height && `height: ${props.height}px;`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop}px;`}
  ${(props) =>
    props.paddingBottom && `padding-bottom: ${props.paddingBottom}px;`}
  ${(props) => props.paddingleft && `padding-left: ${props.paddingleft}px;`}
  ${(props) => props.paddingright && `padding-right: ${props.paddingright}px;`}
  transition: color 0.3s ease, background-color 0.3s ease;
  &:hover {
    background-color: ${colors.white};
    color: ${colors.green};
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

interface GradientButtonProps {
  width: string;
  height: string;
  fontSize: number;
  top?: number;
  left?: string;
  topReal?: number;
}

export const GradientButton = styled.button<GradientButtonProps>`
  border: none;
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
  ${(props) => props.fontSize && `line-height: ${props.fontSize}px;`}
  background-color: ${colors.black};
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  ${(props) => props.top && `bottom: ${props.top}px;`}
  ${(props) => props.topReal && `top: ${props.topReal}px;`}
  ${(props) => (props.left ? `left: ${props.left};` : "left: 50%;")}
  transform: translate(-50%, 0);
  &:hover {
    color: ${colors.black};
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 2px; /* control the border thickness */
    background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  &:hover {
    background-color: ${colors.white};
  }
`;

export const GradientText = styled.div`
  color: transparent; /* Make text color transparent */
  font-weight: 600;
  background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
  -webkit-background-clip: text; /* Apply gradient as text color for WebKit browsers */
  background-clip: text; /* Apply gradient as text color for other browsers */
`;

export const ModalWraper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(120, 120, 120, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(4px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const MarketingPageWrapper = styled.div`
  height: fit-content;
  min-height: 100vh;
  background-repeat: repeat-y;
  background: radial-gradient(
    85.75% 59.31% at 91.83% 28.24%,
    rgb(0 191 99 / 64%) 1%,
    #2f2f2f 97.39%
  );
`;

export const ToggleSwitchWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  .switch {
    width: 50px;
    height: 25px;
    background: #ddd;
    border-radius: 25px;
    position: relative;
    transition: background 0.3s;
  }

  .switch.on {
    background: ${colors.mainGreen};
  }

  .switch::before {
    content: "";
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    transition: transform 0.3s;
  }

  .switch.on::before {
    transform: translateX(25px);
  }
`;
