import { colors } from "colors"
import styled from "styled-components"

interface StepUpSectionWrapperProps {
  h2Color?: string
}

export const StepUpSectionWrapper = styled.div<StepUpSectionWrapperProps>`
  padding-bottom: 200px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    48.75% 74.31% at 46.83% 72.24%,
    rgba(0, 191, 99, 0.3) 1%,
    #2f2f2f 97.39%
  );
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    svg {
      margin-bottom: 30px;
    }
    h2 {
      color: ${(props) =>
        props.h2Color && props?.h2Color ? props.h2Color : "white"};
      text-align: center;
      margin-bottom: 30px;
    }
  }
`
