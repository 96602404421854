import axios from "axios";
import globalRouter from "globalRouter";
import { Store } from "redux";
import { endLoading, setError } from "state/appActions";
import type { AxiosRequestConfig } from "axios";

export let axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export const setupAxiosInstance = (store: Store) => {
  axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
  });

  axios.defaults.withCredentials = true;

  axiosInstance.interceptors.request.use(
    (config) => {
      // Do not overwrite form content type
      if (config.headers["Content-Type"] !== "multipart/form-data") {
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401) {
        if (
          error?.response?.data?.message?.includes("Token Expired") ||
          error?.response?.data?.message?.includes("Signature has expired")
        ) {
          store.dispatch(endLoading());
          if (globalRouter.navigate)
            globalRouter.navigate("/login?sessiontimeout=true");
          return;
        } else {
          return error;
        }
      } else if (error.response && error.response.status === 503) {
        store.dispatch(endLoading());
        if (globalRouter.navigate) globalRouter.navigate("/maintenance");
      } else if (error?.response?.data?.status === "error") {
        store.dispatch(endLoading());
      }
    }
  );
};
