import React, { FC } from "react";
import { PaginateWrapper, Page } from "./Paginate.styled";

interface PaginateProps {
  pages: number;
  activePage: number;
  totalRecords: number;
  pageValue: number;
  setActivePage: Function;
}

const Paginate: FC<PaginateProps> = ({
  pages,
  activePage,
  totalRecords,
  pageValue,
  setActivePage,
}) => {
  let pagesArray = new Array(pages).fill(null);
  const pageChangeHandler = (newPage: number) => {
    // Prevent users from going below or above the max/min
    if (newPage > pages) {
      newPage = pages
    } else if (newPage < 1) {
      newPage = 1
    }
    setActivePage(newPage);
  }
  return (
    <PaginateWrapper>
      <div className="breakdown-wrapper">
        Showing{" "}
        {`${(activePage - 1) * pageValue + 1} - ${(activePage * pageValue > totalRecords) ? totalRecords : activePage * pageValue}`} of{" "}
        {totalRecords}
      </div>
      <div className="pages-wrapper">
        <Page index={-1} activePage={activePage} onClick={() => pageChangeHandler(activePage-1)}>{`<`}</Page>
        {pagesArray.map((value: number, index: number) => (
          <Page index={index} activePage={activePage} onClick={() => pageChangeHandler(index + 1)}>
            {index + 1}
          </Page>
        ))}
        <Page index={-1} activePage={activePage} onClick={() => pageChangeHandler(activePage+1)}>{`>`}</Page>
      </div>
    </PaginateWrapper>
  );
};

export default Paginate;
