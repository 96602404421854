import React from "react"

const Import = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.49 14.6812V21.5325C23.49 22.0517 23.2838 22.5496 22.9167 22.9167C22.5496 23.2838 22.0517 23.49 21.5325 23.49H1.9575C1.43834 23.49 0.940441 23.2838 0.573338 22.9167C0.206236 22.5496 0 22.0517 0 21.5325V14.6812C0 14.4217 0.103118 14.1727 0.286669 13.9892C0.470221 13.8056 0.719169 13.7025 0.97875 13.7025C1.23833 13.7025 1.48728 13.8056 1.67083 13.9892C1.85438 14.1727 1.9575 14.4217 1.9575 14.6812V21.5325H21.5325V14.6812C21.5325 14.4217 21.6356 14.1727 21.8192 13.9892C22.0027 13.8056 22.2517 13.7025 22.5112 13.7025C22.7708 13.7025 23.0198 13.8056 23.2033 13.9892C23.3869 14.1727 23.49 14.4217 23.49 14.6812ZM11.0525 15.3737C11.1434 15.4647 11.2514 15.5369 11.3702 15.5862C11.489 15.6354 11.6164 15.6608 11.745 15.6608C11.8736 15.6608 12.001 15.6354 12.1198 15.5862C12.2386 15.5369 12.3466 15.4647 12.4375 15.3737L17.3312 10.48C17.4222 10.389 17.4943 10.2811 17.5435 10.1623C17.5927 10.0434 17.618 9.9161 17.618 9.7875C17.618 9.6589 17.5927 9.53155 17.5435 9.41274C17.4943 9.29393 17.4222 9.18597 17.3312 9.09503C17.2403 9.0041 17.1323 8.93196 17.0135 8.88275C16.8947 8.83354 16.7674 8.80821 16.6388 8.80821C16.5101 8.80821 16.3828 8.83354 16.264 8.88275C16.1452 8.93196 16.0372 9.0041 15.9463 9.09503L12.7238 12.3188V0.97875C12.7238 0.719169 12.6206 0.470221 12.4371 0.286669C12.2535 0.103118 12.0046 0 11.745 0C11.4854 0 11.2365 0.103118 11.0529 0.286669C10.8694 0.470221 10.7662 0.719169 10.7662 0.97875V12.3188L7.54372 9.09503C7.36006 8.91138 7.11097 8.80821 6.85125 8.80821C6.59153 8.80821 6.34244 8.91138 6.15878 9.09503C5.97513 9.27869 5.87196 9.52777 5.87196 9.7875C5.87196 10.0472 5.97513 10.2963 6.15878 10.48L11.0525 15.3737Z"
        fill="#00BF63"
      />
    </svg>
  )
}

export default Import
