import { FC } from "react";
import { SearchBarWrapper } from "./SearchBar.styled";
import Search from "static/svgs/Search";

interface SearchBarProps {
  updateFunction: Function;
  submitFunction?: Function;
  value: string;
}

const SearchBar: FC<SearchBarProps> = ({
  value,
  updateFunction,
  submitFunction,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFunction(event.target.value);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (submitFunction) {
        submitFunction();
      }
    }
  };
  return (
    <SearchBarWrapper className="search-bar">
      <div className="svg-wrapper">
        <Search />
      </div>
      <input
        value={value}
        onChange={(e) => handleChange(e)}
        onKeyDown={handleKeyPress}
        className="search-input"
        placeholder="Search"
      ></input>
    </SearchBarWrapper>
  );
};

export default SearchBar;
