import styled, { keyframes } from 'styled-components';
import { colors } from 'colors';

const animate = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

export const ErrorModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

export const MatrixCode = styled.div`
  color: ${colors.mainGreen}; /* Green text color */
  font-family: monospace;
  font-size: 16px;
  line-height: 1.4;
  white-space: pre;
  animation: ${animate} 3s linear infinite;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  text-align: left;
  z-index:100001;
`;

export const ErrorModalDiv = styled.div`
  height: 50%;
  position: relative;
  width: 50%;
  margin: 0 auto;
  max-height: 335px;
  padding-top: 30px;
  background-color: ${colors.darkGray};
  border: 1px solid ${colors.mainGreen};
  color: ${colors.mainGreen};
  max-width: 640px;
  z-index: 100001;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  h1 {
    width: 100%;
    text-align: center;
  }
  h4 {
    margin-top: 15px;
    text-align: center;
  }
  button {
    margin-top: 20px;
  }
`;