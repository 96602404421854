import styled, { keyframes } from "styled-components";
import { colors } from "colors";

export const DealsTable = styled.table`
  width: 100%;
  height: fit-content;
  border-collapse: separate;
  border-spacing: 0 30px;
  thead {
    display: table-header-group;
    border-spacing: 0;
  }
  h3 {
    padding-left: 40px;
    padding-top: 40px;
    font-size: 20px;
  }
  .stage {
    min-width: 120px;
  }
  .stage-select {
    select {
      background-color: ${colors.black};
      color: ${colors.white};
      border: 0;
      height: 30px;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      padding: 0px 10px;
      cursor: pointer;
      appearance: none; // Remove default stylingSize of the arrow
      width: 83%; // Ensure the select takes full width of its container
      max-width: 200px; // Adjust this value as needed
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Deal = styled.tr`
  max-height: 50px; /* Set max height for table rows */
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-top: 10px;
  margin-bottom: 10px;
  td {
    max-height: 20px;
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
  }
  &:hover {
    background-color: ${colors.middleGray};
    z-index: 11;
  }
  &.processing {
    pointer-events: none;
    opacity: 0.4;
  }
  .spin-wrapper {
    height: fit-content;
    width: fit-content;
    svg {
      width: 27px;
      height: 27px;
      margin-left: 8px;
      animation: ${spin} 2s linear infinite; /* Applies the spin animation */
    }
  }
  .name {
    width: 15%;
    span {
      cursor: pointer;
    }
  }
  .svg-wrapper {
    cursor: pointer;
    padding: 0 10px;
    svg {
      width: 25px;
      height: 25px;
      path {
        stroke: ${colors.grayWhite};
      }
    }
  }
  .score {
    padding: 0 25px;
  }
  .lead {
    padding: 0 5px;
    .text {
      width: 100%;
      margin-left: 4px;
      display: none;
      p {
        width: 100%;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
      }
      .role {
        font-size: 10px;
        font-weight: 300;
        margin: 0;
        margin-top: 5px;
      }
    }
    width: 7%;
    .pfp {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 100%;
      margin-left: 2px;
      cursor: pointer;
      position: relative;
      &:hover {
        .text {
          display: block;
          position: absolute;
          width: 100px;
          min-height: 40px;
          right: -110px;
          top: 6px;
          background-color: ${colors.black};
          padding: 10px;
          border-radius: 5px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    .letter-wrapper {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 100%;
      background-color: ${colors.mainGreen};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
    .user-wrapper {
      width: 100%;
      height: 48px;
      display: flex;
      border-radius: 100px;
      margin-right: 4px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 7px;
      }
    }
  }
  .last-call {
    width: 11%;
  }
  .scores-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 290px;
  }
  div {
    margin: 0;
    margin-right: 2px;
  }
`;

export const DealsWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  border: 1px solid #707277;
  border-radius: 3px;
  text-align: left;
  min-width: 1200px;
  box-shadow: ${colors.boxShadow};
  position: relative;
  hr {
    width: 100%;
    position: absolute;
    top: 67px;
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
  }
  p {
    width: 20%;
  }
`;

export const DealsHeader = styled.thead`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${colors.middleGray};
  position: relative;
  th {
    margin-bottom: 10px;
  }
  tr {
  }
  .title-wrapper {
    p {
      width: fit-content;
      position: relative;
      margin-top: 0px;
      margin-bottom: -20px;
    }
  }
  .score {
    padding-left: 25px;
  }
  .filter-wrapper {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: -20px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const SearchWrapper = styled.div`
  width: 200px;
  margin-bottom: 25px;
`;

const skeletonLoading = keyframes`
0% {
  background-position: -2000px 0;
}
100% {
  background-position: 1500px 0;
}
`;

export const DealsLoadWrapper = styled.div`
  width: 591%;
  margin-bottom: 25px;
  height: 600px;
  background: radial-gradient(
    farthest-side,
    ${colors.deepGray} 0%,
    #c0c0c0 -54%,
    ${colors.deepGray} 75%
  );
  background: linear-gradient(
    90deg,
    ${colors.deepGray} 25%,
    #7b7b7b 50%,
    ${colors.deepGray} 75%
  );
  animation: ${skeletonLoading} 2s infinite;
  background-repeat: no-repeat;
  background-size: 200% 100%; /* Stretch horizontally */
  background-position: left center;
`;

export const DealLoad = styled.tr`
  width: 100%;
  background: linear-gradient(90deg, ${colors.deepGray} 25%, #c0c0c0 50%, 75%);
  background-size: 900px 100%;
  animation: ${skeletonLoading} 3s infinite linear;
  height: 50px;
  padding: 0 20px;
`;

export const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #e0f7fa;
  border-radius: 50px;
  padding: 4px;
  width: 275px;
  margin-bottom: 25px;
`;

export const ToggleBackground = styled.div<{
  activeOption: "my-deals" | "team-deals";
}>`
  position: absolute;
  height: 81%;
  width: 50%;
  background-color: ${colors.mainGreen};
  border-radius: 50px;
  transition: transform 0.3s;
  box-shadow: ${colors.boxShadow};
  transform: ${({ activeOption }) =>
    activeOption === "my-deals" ? "translateX(0%)" : "translateX(93%)"};
`;

export const ToggleButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px 20px;
  background-color: transparent;
  color: ${({ active }) => (active ? "#ffffff" : "#000000")};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
  transition: color 0.3s;

  &:focus {
    outline: none;
  }
`;
