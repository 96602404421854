import { LOGIN_SUCCESS, LOGOUT_SUCCESS, SET_USER_DATA } from "./authActions"
import { User } from "types"

interface AuthState {
  isAuthenticated: boolean
  user?: User
}

// Check if token exists in local storage
const authorizeValidtoken = () =>
  localStorage.getItem("authAccess") ? true : false

// Define the initial state
const initialState: AuthState = {
  isAuthenticated: authorizeValidtoken(),
  user: {
    company: "",
    email: "",
    first_name: "",
    industry: "",
    last_name: "",
    role: "",
    user_type: "",
    username: "",
    image_url: "",
    linkedin_url: "",
    completed_intro_tour: true,
    _id: "",
    registered: true,
    profile_pic_url: "",
    user_role: "rep",
    team_id: "",
    user_id: ""
  },
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        isAuthenticated: false,
      }
    case SET_USER_DATA:
      return {
        ...state,
        user: action.user,
      }
    default:
      return state
  }
}

export default authReducer
