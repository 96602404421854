import React from "react";

const Search = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57324 17.2964C5.08991 17.2964 1.44824 13.6547 1.44824 9.17139C1.44824 4.68805 5.08991 1.04639 9.57324 1.04639C14.0566 1.04639 17.6982 4.68805 17.6982 9.17139C17.6982 13.6547 14.0566 17.2964 9.57324 17.2964ZM9.57324 2.29639C5.78158 2.29639 2.69824 5.37972 2.69824 9.17139C2.69824 12.9631 5.78158 16.0464 9.57324 16.0464C13.3649 16.0464 16.4482 12.9631 16.4482 9.17139C16.4482 5.37972 13.3649 2.29639 9.57324 2.29639Z"
      fill="#979797"
    />
    <path
      d="M17.2058 18.9965C17.1392 18.9965 17.0725 18.9882 17.0142 18.9799C16.6225 18.9299 15.9142 18.6632 15.5142 17.4715C15.3058 16.8465 15.3808 16.2215 15.7225 15.7465C16.0642 15.2715 16.6392 15.0049 17.2975 15.0049C18.1475 15.0049 18.8142 15.3299 19.1142 15.9049C19.4142 16.4799 19.3308 17.2132 18.8558 17.9215C18.2642 18.8132 17.6225 18.9965 17.2058 18.9965ZM16.7058 17.0799C16.8475 17.5132 17.0475 17.7299 17.1808 17.7465C17.3142 17.7632 17.5642 17.6049 17.8225 17.2299C18.0642 16.8715 18.0808 16.6132 18.0225 16.4965C17.9642 16.3799 17.7308 16.2549 17.2975 16.2549C17.0392 16.2549 16.8475 16.3382 16.7392 16.4799C16.6392 16.6215 16.6225 16.8382 16.7058 17.0799Z"
      fill="#979797"
    />
  </svg>
);

export default Search;
