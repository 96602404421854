import { Stage } from "types";

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const startLoading = () => ({
    type: START_LOADING
});

export const endLoading = () => ({
    type: END_LOADING
});

export const setStages = (value: Stage[]) => ({
    type: "SET_STAGES",
    value
});

export const setError = (value: string) => ({
    type: "SET_ERROR",
    value
});

export const setPageTitle = (value: string) => ({
    type: "SET_PAGE_TITLE",
    value
});

export const setLoadingText = (value: string) => ({
    type: "SET_LOADING_TEXT",
    value
});

export const setLoadingSubText = (value: string) => ({
    type: "SET_LOADING_SUB_TEXT",
    value
});

export const setCallModal = (value: boolean) => ({
    type: "SET_CALL_MODAL",
    value
});

export const setAddDealModal = (value: boolean) => ({
    type: "SET_ADD_DEAL_MODAL",
    value
});

export const setDarkMode = (darkMode: boolean) => {
    localStorage.setItem('darkMode', darkMode.toString());
    return {
      type: 'SET_DARK_MODE',
      payload: darkMode
    };
  };