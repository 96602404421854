import { colors } from 'colors';
import styled from 'styled-components';

export const SalesAutomationWrapper = styled.div`
@media (max-width: 500px) {
  padding: 100px 20px;
}
height: fit-content;
width: 100vw;
padding: 150px 50px;
background-color: ${colors.black};
h1 {
  @media (max-width: 500px) {
    font-size: 38px;
    margin-bottom: 30px;
  }
  text-align: center;
  width: 100%;
  /* background-color: ${colors.mainGreen}; */
  color: ${colors.mainGreen};
  padding: 30px 0;
  font-weight: 700;
  font-size: 52px;
  margin-bottom: 50px;
}
a {
  color: ${colors.mainGreen};
}
.section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  padding: 40px;
  @media (max-width: 1000px) {
    height: auto;
    text-align: center !important;
  }
  background: linear-gradient(#353747, #2f2f2f);
  border-radius: 10px;
}
.section-wrapper-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  @media (max-width: 1000px) {
    height: auto;
    text-align: center !important;
  }
  background: radial-gradient(
    48.75% 38.31% at 47.83% 47.24%,
    #00bf6373 -7%,
    #2f2f2f 97.39%
  );
}
.flex-wrapper {
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    max-width: 500px;
    margin-bottom: 100px;
  }
  h2 {
    @media (max-width: 500px) {
      font-size: 30px;
    }
    font-size: 48px;
    @media (max-width: 1000px) {
      text-align: center !important;
    }
  }
  p {
    @media (max-width: 500px) {
      font-size: 18px;
      width: 310px;
    }
    font-size: 24px;
  }
  .text {
    margin-right: 80px;
    @media (max-width: 1000px) {
      margin: 0;
      margin-bottom: 20px;
    }
  }
  .text-right {
    margin-left: 90px;
    @media (max-width: 1000px) {
      margin: 0;
    }
  }
  img {
    @media (max-width: 500px) {
      height: 90%;
      width: 98%;
    }
    border-radius: 5px;
    height: 440px;
    width: 580px;
  }
}
.flex-wrapper-right {
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    max-width: 500px;
    margin-bottom: 100px;
    flex-direction: column-reverse;
  }
  h2 {
    @media (max-width: 500px) {
      font-size: 30px;
    }
    font-size: 48px;
    @media (max-width: 1000px) {
      text-align: center !important;
    }
  }
  p {
    @media (max-width: 500px) {
      font-size: 18px;
      width: 310px;
    }
    font-size: 24px;
  }
  .text {
    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    margin-right: 80px;
    @media (max-width: 1000px) {
      margin: 0;
      margin-bottom: 20px;
    }
  }
  .text-right {
    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    margin-left: 90px;
    @media (max-width: 1000px) {
      margin: 0;
    }
  }
  img {
    border-radius: 5px;
    height: 440px;
    width: 580px;
    @media (max-width: 500px) {
      height: 90%;
      width: 98%;
    }
    @media (max-width: 1000px) {
      margin-top: 20px;
    }
  }
}
`;
