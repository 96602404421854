import React from "react";

const Notification = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3575 17.949C8.41587 17.949 6.47421 17.6407 4.63254 17.024C3.93254 16.7824 3.39921 16.2907 3.16587 15.649C2.92421 15.0074 3.00754 14.299 3.39087 13.6657L4.34921 12.074C4.54921 11.7407 4.73254 11.074 4.73254 10.6824V8.27405C4.73254 5.17405 7.25754 2.64905 10.3575 2.64905C13.4575 2.64905 15.9825 5.17405 15.9825 8.27405V10.6824C15.9825 11.0657 16.1659 11.7407 16.3659 12.0824L17.3159 13.6657C17.6742 14.2657 17.7409 14.9907 17.4992 15.649C17.2575 16.3074 16.7325 16.8074 16.0742 17.024C14.2409 17.6407 12.2992 17.949 10.3575 17.949ZM10.3575 3.89905C7.94921 3.89905 5.98254 5.85738 5.98254 8.27405V10.6824C5.98254 11.2907 5.73254 12.1907 5.42421 12.7157L4.46587 14.3074C4.28254 14.6157 4.23254 14.9407 4.34087 15.2157C4.44087 15.499 4.69087 15.7157 5.03254 15.8324C8.51587 16.999 12.2075 16.999 15.6909 15.8324C15.9909 15.7324 16.2242 15.5074 16.3325 15.2074C16.4409 14.9074 16.4159 14.5824 16.2492 14.3074L15.2909 12.7157C14.9742 12.174 14.7325 11.2824 14.7325 10.674V8.27405C14.7325 5.85738 12.7742 3.89905 10.3575 3.89905Z"
      fill="#707277"
    />
    <path
      d="M11.9074 4.12397C11.8491 4.12397 11.7908 4.11564 11.7324 4.09897C11.4908 4.03231 11.2574 3.98231 11.0324 3.94897C10.3241 3.85731 9.64075 3.90731 8.99909 4.09897C8.76575 4.17397 8.51575 4.09897 8.35742 3.92397C8.19909 3.74897 8.14909 3.49897 8.24075 3.27397C8.58242 2.39897 9.41575 1.82397 10.3658 1.82397C11.3158 1.82397 12.1491 2.39064 12.4908 3.27397C12.5741 3.49897 12.5324 3.74897 12.3741 3.92397C12.2491 4.05731 12.0741 4.12397 11.9074 4.12397Z"
      fill="#707277"
    />
    <path
      d="M10.3574 19.8495C9.53242 19.8495 8.73242 19.5162 8.14909 18.9328C7.56575 18.3495 7.23242 17.5495 7.23242 16.7245H8.48242C8.48242 17.2162 8.68242 17.6995 9.03242 18.0495C9.38242 18.3995 9.86576 18.5995 10.3574 18.5995C11.3908 18.5995 12.2324 17.7578 12.2324 16.7245H13.4824C13.4824 18.4495 12.0824 19.8495 10.3574 19.8495Z"
      fill="#707277"
    />
  </svg>
);

export default Notification;
