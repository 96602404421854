import React, { FC, useEffect, useState } from "react"
import { MarketingFAQsPageWrapper } from "./MarketingFAQsPage.styled"
import MarketingSectionLabel from "components/MarketingSectionLabel/MarketingSectionLabel"
import { colors } from "colors"
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled"
import FAQ from "./FAQ"
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection"

interface MarketingFAQsPageProps {}

interface FAQ {
  icon?: string
  question: string
  answer: string
}

const MarketingFAQsPage: FC<MarketingFAQsPageProps> = () => {
  const [FAQs, setFAQs] = useState<FAQ[] | []>([])
  useEffect(() => {
    async function getFAQs() {
      try {
        const data = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
          },
          body: JSON.stringify({
            query: `
            {
              allFaqs {
                question
                answer
                icon {
                  url
                }
              }
            }
            
         `,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setFAQs(res.data.allFaqs)
          })
      } catch (error) {
        console.log(error)
      }
    }
    getFAQs()
  }, [])

  return (
    <MarketingFAQsPageWrapper>
      <div className="inner-wrapper">
        <MarketingSectionLabel fontSize="20" text="FAQ" fontWeight={500} />
        <h2>Ask us anything</h2>
        <h4>Have any questions? We're here to assist you.</h4>
        {/* <input type="search" /> */}
        <hr />
        <div className="faqs-wrapper">
          <div className="faqs-inner-wrapper">
            {FAQs?.map((faq) => {
              return (
                <FAQ
                  icon={faq.icon}
                  question={faq.question}
                  answer={faq.answer}
                />
              )
            })}
          </div>
          <div className="message-wrapper">
            <div className="message">
              <div className="text-wrapper">
                <p>Still have questions?</p>
                <p>
                  Can’t find the answer you’re looking for? Please chat to our
                  friendly team.
                </p>
              </div>
              <div className="button-wrapper">
                <MarketingGreenButtonWrapper width={150}>
                  Get in touch
                </MarketingGreenButtonWrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="step-up">
          <StepUpSection />
        </div>
      </div>
    </MarketingFAQsPageWrapper>
  )
}

export default MarketingFAQsPage
