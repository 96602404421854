import { colors } from 'colors';
import styled from 'styled-components';

export const ShareWrapper = styled.div`
  color: white;
  padding: 50px 60px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${colors.black};
  height: 100%;
  border-radius: 10px;
  h2 {
    text-align: center;
    width: 100%;
    color: ${colors.mainGreen};
  }
  h4 {
    text-align: center;
  }
  max-height: 500px;
  min-height: 660px;
  &.success {
    min-height: 300px !important;
  }
  position: relative;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
  width: 700px;
  .dual-input-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .small-input {
    width: 200px;
  }
  h2 {
    margin-bottom: 30px;
  }
  textarea {
    padding: 10px;
  }
`;
