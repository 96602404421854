import React from "react";

const AddBox = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.12963"
      y="1.12963"
      width="28.2407"
      height="28.2407"
      rx="3.38889"
      stroke="#EEF7F2"
      strokeWidth="2.25926"
    />
    <path
      d="M19.7686 16.9446H10.7316C9.80526 16.9446 9.03711 16.1764 9.03711 15.2501C9.03711 14.3238 9.80526 13.5557 10.7316 13.5557H19.7686C20.6949 13.5557 21.463 14.3238 21.463 15.2501C21.463 16.1764 20.7175 16.9446 19.7686 16.9446Z"
      fill="#EEF7F2"
    />
    <path
      d="M15.25 21.463C14.3238 21.463 13.5556 20.6949 13.5556 19.7686V10.7316C13.5556 9.80526 14.3238 9.03711 15.25 9.03711C16.1763 9.03711 16.9445 9.80526 16.9445 10.7316V19.7686C16.9445 20.6949 16.1989 21.463 15.25 21.463Z"
      fill="#EEF7F2"
    />
  </svg>
);

export default AddBox;
