import React from "react";

const Upload = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50052 14.7917C7.15885 14.7917 6.87552 14.5084 6.87552 14.1667V10.6751L6.27552 11.2751C6.03385 11.5167 5.63385 11.5167 5.39219 11.2751C5.15052 11.0334 5.15052 10.6334 5.39219 10.3917L7.05885 8.72507C7.23385 8.55007 7.50885 8.49174 7.74219 8.59174C7.97552 8.68341 8.12552 8.91674 8.12552 9.16674V14.1667C8.12552 14.5084 7.84219 14.7917 7.50052 14.7917Z"
      fill="#2F2F2F"
    />
    <path
      d="M9.16458 11.4576C9.00625 11.4576 8.84792 11.3992 8.72292 11.2742L7.05625 9.60755C6.81458 9.36588 6.81458 8.96589 7.05625 8.72422C7.29792 8.48255 7.69792 8.48255 7.93958 8.72422L9.60625 10.3909C9.84792 10.6326 9.84792 11.0326 9.60625 11.2742C9.48125 11.3992 9.32292 11.4576 9.16458 11.4576Z"
      fill="#2F2F2F"
    />
    <path
      d="M12.5013 18.9577H7.5013C2.9763 18.9577 1.04297 17.0243 1.04297 12.4993V7.49935C1.04297 2.97435 2.9763 1.04102 7.5013 1.04102H11.668C12.0096 1.04102 12.293 1.32435 12.293 1.66602C12.293 2.00768 12.0096 2.29102 11.668 2.29102H7.5013C3.65964 2.29102 2.29297 3.65768 2.29297 7.49935V12.4993C2.29297 16.341 3.65964 17.7077 7.5013 17.7077H12.5013C16.343 17.7077 17.7096 16.341 17.7096 12.4993V8.33268C17.7096 7.99102 17.993 7.70768 18.3346 7.70768C18.6763 7.70768 18.9596 7.99102 18.9596 8.33268V12.4993C18.9596 17.0243 17.0263 18.9577 12.5013 18.9577Z"
      fill="#2F2F2F"
    />
    <path
      d="M18.3346 8.95841H15.0013C12.1513 8.95841 11.043 7.85007 11.043 5.00007V1.66674C11.043 1.41674 11.193 1.18341 11.4263 1.09174C11.6596 0.991739 11.9263 1.05007 12.1096 1.22507L18.7763 7.89174C18.9513 8.06674 19.0096 8.34174 18.9096 8.57507C18.8096 8.8084 18.5846 8.95841 18.3346 8.95841ZM12.293 3.17507V5.00007C12.293 7.15007 12.8513 7.70841 15.0013 7.70841H16.8263L12.293 3.17507Z"
      fill="#2F2F2F"
    />
  </svg>
);

export default Upload;
