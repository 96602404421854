import styled from 'styled-components';

export const DealCoachWrapper = styled.div`
  .flex {
    display: flex;
    height: 100%;
  }
  .top-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    .search-wrapper {
      width: 250px;
      margin-right: 270px;
    }
  }
  .chat-box {
    width: 745px;
  }
`;


export const PrepromptsWrapper = styled.div`
`;

export const Preprompt = styled.div`
`;

export const SideBar = styled.div`
  width: 28%;
  height: 100%;
`;
