import React, { FC, useEffect, useState } from "react"
import { MarketingTeamPageWrapper } from "./MarketingTeamPage.styled"
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection"
import TeamMember from "./TeamMember"
import { colors } from "colors"

interface MarketingTeamPageProps {}
type Headshot = {
  url: string
}
interface TeamMember {
  headshot: Headshot
  name: string
  companyPosition: string
  linkedinUrl: string
  email: string
}

const MarketingTeamPage: FC<MarketingTeamPageProps> = () => {
  const [team, setTeam] = useState<TeamMember[] | []>()
  useEffect(() => {
    async function getTeam() {
      try {
        const data = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
          },
          body: JSON.stringify({
            query: `
              {
                  allTeamMembers {
                      name
                      linkedinUrl
                      headshot {
                          url
                      }
                      companyPosition
                      email
                  }
              }
         `,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setTeam(res.data.allTeamMembers)
          })
      } catch (error) {
        console.log(error)
      }
    }
    getTeam()
  }, [])

  console.log(team && team[0].headshot.url)

  return (
    <MarketingTeamPageWrapper>
      <div className="inner-wrapper">
        <div className="heading-wrapper">
          <h2 className="team-header">The Team</h2>
          <h4 className="team-subheader">
            The Leadoff.ai team, with its robust expertise in sales, AI, and
            software development, is committed to transforming sales coaching.
            Our diverse experience fuels our innovation, making Leadoff.ai a
            pioneer in elevating sales performance through AI.
          </h4>
        </div>
        <div className="team-members-wrapper">
          <div className="team-members-inner-wrapper">
            {team?.map((teamMember) => {
              return (
                <TeamMember
                  headshot={teamMember.headshot.url}
                  name={teamMember.name}
                  companyPosition={teamMember.companyPosition}
                  linkedinUrl={teamMember.linkedinUrl}
                  email={teamMember.email}
                />
              )
            })}
          </div>
        </div>
        <StepUpSection />
      </div>
    </MarketingTeamPageWrapper>
  )
}

export default MarketingTeamPage
