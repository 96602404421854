import { User } from '../types';
import { setAmplitudeUserId, setAmplitudeUserProperties } from 'amplitude';
import { logout as logoutHelper } from 'api/helpers';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER_DATA = 'SET_USER_DATA';

export const login = (str?: string) => {
  localStorage.setItem('authAccess', "true");
  return ({
    type: LOGIN_SUCCESS,
  });
}

export const logout = () => {
  localStorage.removeItem('authAccess');
  logoutHelper();
  return ({
    type: LOGOUT_SUCCESS,
  });
}

export const setUserData = (user: User) => {
  setAmplitudeUserId(user.email);
  setAmplitudeUserProperties(user);
  if (!user.completed_intro_tour) {
    user.completed_intro_tour = false
  }
  return ({
    type: SET_USER_DATA,
    user
  });
}
