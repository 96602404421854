import React from "react";

const Filter = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.48915 13.2126C6.20915 13.2126 5.93499 13.1426 5.67832 13.0026C5.16499 12.7168 4.85582 12.1976 4.85582 11.6143V8.5226C4.85582 8.23093 4.66332 7.79343 4.48249 7.57176L2.30082 5.26177C1.93332 4.89426 1.65332 4.26426 1.65332 3.79176V2.4501C1.65332 1.51676 2.35915 0.787598 3.25749 0.787598H10.9575C11.8442 0.787598 12.5617 1.5051 12.5617 2.39176V3.6751C12.5617 4.2876 12.1942 4.98176 11.85 5.32593L9.32415 7.5601C9.07915 7.76426 8.88665 8.21343 8.88665 8.5751V11.0834C8.88665 11.6026 8.55999 12.2034 8.15165 12.4484L7.34665 12.9676C7.08415 13.1309 6.78665 13.2126 6.48915 13.2126ZM3.25749 1.6626C2.84915 1.6626 2.52832 2.00676 2.52832 2.4501V3.79176C2.52832 4.0076 2.70332 4.4276 2.92499 4.64926L5.14749 6.98843C5.44499 7.35593 5.73665 7.96843 5.73665 8.51676V11.6084C5.73665 11.9876 5.99915 12.1743 6.10999 12.2326C6.35499 12.3668 6.65249 12.3668 6.87999 12.2268L7.69082 11.7076C7.85415 11.6084 8.01749 11.2934 8.01749 11.0834V8.5751C8.01749 7.95093 8.32082 7.2626 8.75832 6.8951L11.255 4.68426C11.4533 4.48593 11.6925 4.01343 11.6925 3.66926V2.39176C11.6925 1.98926 11.3658 1.6626 10.9633 1.6626H3.25749Z"
      fill="white"
    />
    <path
      d="M3.60756 6.27107C3.52589 6.27107 3.45006 6.24774 3.37423 6.2069C3.17006 6.07857 3.10589 5.8044 3.23423 5.60023L6.11006 0.991902C6.23839 0.787735 6.50673 0.723568 6.71089 0.851902C6.91506 0.980235 6.97923 1.24857 6.85089 1.45274L3.97506 6.06107C3.89339 6.19523 3.75339 6.27107 3.60756 6.27107Z"
      fill="white"
    />
  </svg>
);

export default Filter;
