import { FC, useEffect } from "react";
import { LoadingWrapper, Spinner } from "./Loading.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  endLoading,
  setLoadingText,
  setCallModal,
  setAddDealModal,
  setLoadingSubText,
} from "state/appActions";
import LeadoffRobot from "static/svgs/LeadoffRobot";

interface LoadingProps {}

const Loading: FC<LoadingProps> = () => {
  let dispatch = useDispatch();
  const loadingText = useSelector((state: any) => state.app.loadingText);
  const loadingSubText = useSelector((state: any) => state.app.loadingSubText);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }, 100);
    return () => {
      dispatch(setLoadingText(""));
      document.body.style.overflow = "auto";
      window.scrollTo(0, 0);
    };
  }, []);
  const closeOut = () => {
    dispatch(setLoadingText(""));
    dispatch(setLoadingSubText(""));
    dispatch(endLoading());
    dispatch(setCallModal(false));
    dispatch(setAddDealModal(false));
  };
  return (
    <LoadingWrapper onClick={closeOut}>
      <div className="content-wrapper">
        <div className="svg-wrapper">
          <LeadoffRobot />
        </div>
        {loadingText && <h2>{loadingText}</h2>}
        {loadingSubText && <h4>{loadingSubText}</h4>}
      </div>
    </LoadingWrapper>
  );
};

export default Loading;
