import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { User } from 'types'; 


const initSentry = (): void => {
  const { REACT_APP_ENV } = process.env;

  if (REACT_APP_ENV === 'staging') {
    Sentry.init({
      dsn: "https://1743867e03488dc123904b618db7a482@o4507507282149376.ingest.us.sentry.io/4507507283918848",
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: 'staging',
      tracesSampleRate: 1.0,
    });
  } else if (REACT_APP_ENV === 'production') {
    Sentry.init({
      dsn: "https://1743867e03488dc123904b618db7a482@o4507507282149376.ingest.us.sentry.io/4507507283918848",
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: 'production',
      tracesSampleRate: 1.0,
    });
  }
};

export const setSentryUser = (user: User) => {
  const { REACT_APP_ENV } = process.env;
  if (REACT_APP_ENV === 'staging' || REACT_APP_ENV === 'production') {
    Sentry.setUser(user);
  }
};

export default initSentry;