import React from "react"

const BarChart = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3617 18.6618H20.6278V3.98343C20.6278 3.78878 20.5504 3.60211 20.4128 3.46447C20.2752 3.32684 20.0885 3.24951 19.8938 3.24951H14.7564C14.5617 3.24951 14.3751 3.32684 14.2374 3.46447C14.0998 3.60211 14.0225 3.78878 14.0225 3.98343V7.65303H9.61895C9.4243 7.65303 9.23763 7.73036 9.09999 7.86799C8.96236 8.00563 8.88503 8.1923 8.88503 8.38695V12.0566H5.21543C5.02078 12.0566 4.83411 12.1339 4.69647 12.2715C4.55884 12.4091 4.48151 12.5958 4.48151 12.7905V18.6618H3.74759C3.55294 18.6618 3.36627 18.7392 3.22863 18.8768C3.091 19.0144 3.01367 19.2011 3.01367 19.3958C3.01367 19.5904 3.091 19.7771 3.22863 19.9147C3.36627 20.0524 3.55294 20.1297 3.74759 20.1297H21.3617C21.5563 20.1297 21.743 20.0524 21.8806 19.9147C22.0183 19.7771 22.0956 19.5904 22.0956 19.3958C22.0956 19.2011 22.0183 19.0144 21.8806 18.8768C21.743 18.7392 21.5563 18.6618 21.3617 18.6618ZM15.4903 4.71735H19.1599V18.6618H15.4903V4.71735ZM10.3529 9.12087H14.0225V18.6618H10.3529V9.12087ZM5.94935 13.5244H8.88503V18.6618H5.94935V13.5244Z"
        fill="#34D399"
      />
    </svg>
  )
}

export default BarChart
