import React, { FC, useEffect, useState } from "react";
import { SolutionsLeadersWrapper } from "./SolutionsLeaders.styled";
import { getLeaderSolutionsData } from "cms/helpers";
import { StructuredText } from "react-datocms";
import { useLocation } from "react-router-dom";
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";

interface SolutionsLeadersProps {}

interface Section {
  title: string;
  description: {
    value: any;
  };
  image: {
    url: string;
  };
}
interface SolutionsLeadersData {
  title: string;
  sections: Section[];
}

const SolutionsLeaders: FC<SolutionsLeadersProps> = () => {
  const [data, setData] = useState<SolutionsLeadersData>();
  const { hash } = useLocation();

  useEffect(() => {
    const getData = async () => {
      let response = await getLeaderSolutionsData();
      console.log(response);
      setData(response?.solutionsSalesLeader);
    };
    getData();
  }, []);

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' from the hash
    if (hash) {
      const sectionElement = document.getElementById(hash);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data, hash]);

  return (
    <SolutionsLeadersWrapper>
      <h1>{data?.title}</h1>
      <div className="sections-wrapper">
        {data?.sections?.map((section: Section, index: number) => {
          //if index is odd, reverse the order
          if (index % 2 === 1) {
            return (
              <div
                className="section-wrapper-right"
                id={`section-${index}`}
                key={index}
              >
                <div className="flex-wrapper-right">
                  <img src={section.image?.url} />
                  <div className="text-right">
                    <h2>{section.title}</h2>
                    <StructuredText data={section.description?.value} />
                    <MarketingGreenButtonWrapper
                      onClick={() => {
                        window.location.assign(
                          "/contact-us"
                        );
                      }}
                      width={250}
                    >
                      Contact Sales
                    </MarketingGreenButtonWrapper>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="section-wrapper"
                id={`section-${index}`}
                key={index}
              >
                <div className="flex-wrapper">
                  <div className="text">
                    <h2>{section.title}</h2>
                    <StructuredText data={section.description?.value} />
                    <MarketingGreenButtonWrapper
                      onClick={() => {
                        window.location.assign(
                          "/contact-us"
                        );
                      }}
                      width={250}
                    >
                      Contact Sales
                    </MarketingGreenButtonWrapper>
                  </div>
                  <img src={section.image?.url} />
                </div>
              </div>
            );
          }
        })}
      </div>
    </SolutionsLeadersWrapper>
  );
};

export default SolutionsLeaders;
