import { useEffect, useState, FC } from "react";
import { InsightsWrapper } from "./Insights.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import ScoreChart from "components/ScoreChart/ScoreChart";

interface InsightsProps {}

const Insights: FC<InsightsProps> = () => {
  return (
    <PageWrapper pageTitle="Insights">
      <InsightsWrapper>
        <ScoreChart />
      </InsightsWrapper>
    </PageWrapper>
  );
};

export default Insights;
