import React from "react";

const LightningRobot = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1437252887">
        <g id="SvgjsG2102">
          <g id="Group 1437252885">
            <g id="Vector" filter="url(#filter0_b_1768_17665)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.8709 1.80657C23.0726 1.80657 23.2498 1.94024 23.3052 2.13413L23.7949 3.84808C24.0092 4.59828 24.5956 5.18468 25.3458 5.39903L27.0598 5.88872C27.2537 5.94412 27.3873 6.12134 27.3873 6.32299C27.3873 6.52464 27.2537 6.70186 27.0598 6.75725L25.3458 7.24695C24.5956 7.46129 24.0092 8.0477 23.7949 8.7979L23.3052 10.5118C23.2498 10.7057 23.0726 10.8394 22.8709 10.8394C22.6693 10.8394 22.492 10.7057 22.4366 10.5118L21.9469 8.7979C21.7326 8.0477 21.1462 7.46129 20.396 7.24695L18.6821 6.75725C18.4882 6.70186 18.3545 6.52464 18.3545 6.32299C18.3545 6.12134 18.4882 5.94412 18.6821 5.88872L20.396 5.39903C21.1462 5.18468 21.7326 4.59828 21.9469 3.84808L22.4366 2.13413C22.492 1.94024 22.6693 1.80657 22.8709 1.80657Z"
                fill="#EEF7F2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.2906 0C28.4979 0 28.6785 0.141047 28.7288 0.342103L28.8847 0.965647C29.0263 1.53201 29.4685 1.97422 30.0348 2.11581L30.6584 2.2717C30.8594 2.32196 31.0005 2.50261 31.0005 2.70985C31.0005 2.9171 30.8594 3.09775 30.6584 3.14801L30.0348 3.3039C29.4685 3.44549 29.0262 3.8877 28.8847 4.45406L28.7288 5.0776C28.6785 5.27866 28.4979 5.41971 28.2906 5.41971C28.0834 5.41971 27.9027 5.27866 27.8525 5.0776L27.6966 4.45406C27.555 3.8877 27.1128 3.44549 26.5464 3.3039L25.9229 3.14801C25.7218 3.09775 25.5808 2.9171 25.5808 2.70985C25.5808 2.50261 25.7218 2.32196 25.9229 2.2717L26.5464 2.11581C27.1128 1.97422 27.555 1.53201 27.6966 0.965647L27.8525 0.342103C27.9027 0.141047 28.0834 0 28.2906 0Z"
                fill="#EEF7F2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3873 8.12956C27.5817 8.12956 27.7543 8.25395 27.8158 8.43838L28.0532 9.15061C28.1431 9.42033 28.3548 9.63198 28.6245 9.72189L29.3367 9.9593C29.5211 10.0208 29.6455 10.1934 29.6455 10.3878C29.6455 10.5822 29.5211 10.7548 29.3367 10.8162L28.6245 11.0536C28.3548 11.1436 28.1431 11.3552 28.0532 11.6249L27.8158 12.3372C27.7543 12.5216 27.5817 12.646 27.3873 12.646C27.1929 12.646 27.0203 12.5216 26.9589 12.3372L26.7215 11.6249C26.6315 11.3552 26.4199 11.1436 26.1502 11.0536L25.4379 10.8162C25.2535 10.7548 25.1291 10.5822 25.1291 10.3878C25.1291 10.1934 25.2535 10.0208 25.4379 9.9593L26.1502 9.72189C26.4199 9.63198 26.6315 9.42033 26.7215 9.15061L26.9589 8.43838C27.0203 8.25395 27.1929 8.12956 27.3873 8.12956Z"
                fill="#EEF7F2"
              />
            </g>
            <g id="Group 1437252886">
              <path
                id="Vector_2"
                d="M8.49058 14.8391C9.43893 14.8391 10.2077 14.0703 10.2077 13.122C10.2077 12.1736 9.43893 11.4048 8.49058 11.4048C7.54223 11.4048 6.77344 12.1736 6.77344 13.122C6.77344 14.0703 7.54223 14.8391 8.49058 14.8391Z"
                fill="#EEF7F2"
              />
              <path
                id="Vector_3"
                d="M17.9349 14.8391C18.8833 14.8391 19.6521 14.0703 19.6521 13.122C19.6521 12.1736 18.8833 11.4048 17.9349 11.4048C16.9866 11.4048 16.2178 12.1736 16.2178 13.122C16.2178 14.0703 16.9866 14.8391 17.9349 14.8391Z"
                fill="#EEF7F2"
              />
              <path
                id="Vector_4"
                d="M13.7955 22.0915C14.4421 21.9788 15.043 21.6832 15.5269 21.2397C16.0108 20.7961 16.3574 20.2232 16.5257 19.5887C16.6972 18.9656 16.6823 18.306 16.4828 17.6913C16.4142 17.486 16.2828 17.3075 16.1071 17.1812C15.9314 17.0548 15.7204 16.9869 15.504 16.9873H10.9192C10.7021 16.986 10.4901 17.0535 10.3136 17.1799C10.137 17.3063 10.0049 17.4853 9.93618 17.6913C9.83058 18.0186 9.77698 18.3605 9.77735 18.7044C9.77694 19.205 9.886 19.6997 10.0969 20.1538C10.3078 20.6079 10.6154 21.0103 10.9982 21.333C11.381 21.6557 11.8297 21.8907 12.3129 22.0218C12.7961 22.1528 13.3021 22.1766 13.7955 22.0915Z"
                fill="#EEF7F2"
              />
            </g>
          </g>
        </g>
        <path
          id="Vector 3"
          d="M15.4256 3.26538H5.04262C5.04262 3.26538 5.04262 3.26538 5.04262 3.26538C5.04262 3.26538 3.58986 3.26538 2.15846 4.99587C1.46442 5.83494 1.16968 6.97914 1.06054 7.54077C1.01677 7.76606 1.0048 7.99506 1.0048 8.22457V23.2541C1.0048 23.3877 0.999929 23.5207 1 23.6542C1.0003 24.219 1.09834 25.8554 2.15812 26.9155C3.27003 28.0276 5.4405 28.0809 6.03976 28.0733C6.14514 28.072 6.249 28.0691 6.35439 28.0691H21.1939C21.1939 28.0691 21.1939 28.0691 21.1939 28.0691C21.1939 28.0691 22.9244 28.0691 24.6549 26.3386C25.8085 25.185 25.8085 23.4545 25.8085 23.4545C25.8085 23.4545 25.8085 23.4545 25.8085 23.4545V18.8398"
          stroke="#EEF7F2"
          strokeWidth="2"
          fill="none"
          style={{ fill: "none" }}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1768_17665"
          x="-6.41348"
          y="-24.768"
          width="62.1815"
          height="62.1819"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12.384" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1768_17665"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1768_17665"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LightningRobot;
