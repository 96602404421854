import React, { FC, useEffect, useState } from "react";
import { PrivacyWrapper, TextWrapper } from "./Privacy.styled";
import { StructuredText } from "react-datocms";
import { getPrivacyData } from "cms/helpers";
import useWebSocket, { ReadyState } from "react-use-websocket";

interface Privacy {
  value: any;
}

const Privacy: FC = () => {
  const [text, setText] = useState<Privacy | null>(null);

  useEffect(() => {
    const getPrivacy = async () => {
      let response = await getPrivacyData();
      setText(response.privacy.text);
    };
    getPrivacy();
  }, []);
  return (
    <PrivacyWrapper>
      <TextWrapper>
        <StructuredText data={text?.value} />
      </TextWrapper>
    </PrivacyWrapper>
  );
};

export default Privacy;
