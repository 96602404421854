import { colors } from "colors"

import styled from "styled-components"

export const TailoredSectionWrapper = styled.div`
  padding-bottom: 145px;
  position: relative;
  padding-top: 0px;
  background: radial-gradient(
    48.75% 47.31% at 46.83% 47.24%,
    rgba(201, 241, 83, 0.2) 1%,
    #2f2f2f 97.39%
  );
  div {
    &:focus-visible {
      outline: none;
    }
    .carrousel-image-wrapper {
      display: flex !important;
      justify-content: center;
      align-items: center;
      transition: transform ease 0.3s;
      &:hover {
        transform: scale(1.03);
      }
      img {
        object-fit: cover;
        height: inherit;
        width: inherit;
        aspect-ratio: 1/1;
      }
    }
  }
  mark {
    color: ${colors.mainGreen};
  }

  .label {
    position: relative !important;
    z-index: 2 !important;
  }
  .gradient {
    position: absolute;
    top: 15px;
    width: 500px;
    height: 270px;
    background-color: green;
    z-index: 0;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
    .section-left {
      display: flex;
      flex-direction: column;

      h2 {
        z-index: 2;
        position: relative;
        span {
          color: ${colors.green};
          font-size: inherit;
        }
      }
    }
    .section-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
      .white-border-button {
        background-color: transparent;
        border: 1px solid white;
        color: white;
        width: 135px;
        height: 47px;
        border-radius: 5px;
      }
    }
  }
  @media (min-width: 768px) {
    .row {
      flex-direction: row;
      .section-right {
        align-items: flex-end;
        margin-top: unset;
      }
    }
  }
`
