import React from "react";

const Logout = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0497 27.8375H18.8872C13.3372 27.8375 10.6622 25.65 10.1997 20.75C10.1497 20.2375 10.5247 19.775 11.0497 19.725C11.5622 19.675 12.0247 20.0625 12.0747 20.575C12.4372 24.5 14.2872 25.9625 18.8997 25.9625H19.0622C24.1497 25.9625 25.9497 24.1625 25.9497 19.075V10.925C25.9497 5.83748 24.1497 4.03748 19.0622 4.03748H18.8997C14.2622 4.03748 12.4122 5.52498 12.0747 9.52498C12.0122 10.0375 11.5872 10.425 11.0497 10.375C10.5247 10.3375 10.1497 9.87498 10.1872 9.36248C10.6122 4.38748 13.2997 2.16248 18.8872 2.16248H19.0497C25.1872 2.16248 27.8122 4.78748 27.8122 10.925V19.075C27.8122 25.2125 25.1872 27.8375 19.0497 27.8375Z"
      fill="#DEE2D4"
    />
    <path
      d="M18.6 15.9375H2.5C1.9875 15.9375 1.5625 15.5125 1.5625 15C1.5625 14.4875 1.9875 14.0625 2.5 14.0625H18.6C19.1125 14.0625 19.5375 14.4875 19.5375 15C19.5375 15.5125 19.125 15.9375 18.6 15.9375Z"
      fill="#DEE2D4"
    />
    <path
      d="M15.8123 20.125C15.5748 20.125 15.3373 20.0375 15.1498 19.85C14.7873 19.4875 14.7873 18.8875 15.1498 18.525L18.6748 15L15.1498 11.475C14.7873 11.1125 14.7873 10.5125 15.1498 10.15C15.5123 9.78749 16.1123 9.78749 16.4748 10.15L20.6623 14.3375C21.0248 14.7 21.0248 15.3 20.6623 15.6625L16.4748 19.85C16.2873 20.0375 16.0498 20.125 15.8123 20.125Z"
      fill="#DEE2D4"
    />
  </svg>
);

export default Logout;
