import React, { FC, useEffect, useState } from "react";
import {
  DealSummaryWrapper,
  TabsWrapper,
  Tab,
  InfoWrapper,
  ScoreBox,
  ContactsWrapper,
  ContactDiv,
  StageWrapper,
  Point,
  Quote,
  PointWrapper,
} from "./DealSummary.styled";
import Message from "static/svgs/Message";
import Lightning2 from "static/svgs/Lightning2";
import {
  Category,
  Contact,
  Feedback,
  GeneralFeedback,
  HubspotDeal,
  Opportunity,
  Recommendation,
  Stage,
  User,
} from "types";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import Sparkle from "static/svgs/Sparkle";
import { useSelector } from "react-redux";
import Cycle from "static/svgs/Cycle";
import { FloatingBox } from "components/DealPage/DealPage.styled";
import Currency from "static/svgs/Currency";

interface DeaslSummaryProps {
  categories: Category[] | undefined;
  contacts: Contact[] | undefined;
  stage: Stage | undefined;
  stages: Stage[];
  handleMessageSend: Function;
  generalFeedback: GeneralFeedback[];
  opportunityData?: Opportunity;
  hubspotDealData?: HubspotDeal;
  user: User
}

const DealSummary: FC<DeaslSummaryProps> = ({
  stage,
  categories,
  contacts,
  stages,
  handleMessageSend,
  generalFeedback,
  opportunityData,
  hubspotDealData,
  user
}) => {
  const [activeTab, setTab] = useState(-2);
  const [progressLengthPrecentage, setPLP] = useState(0);
  const [currentStage, setCurrentStage] = useState(0);
  const [tipsForImprovement, setTipsForImprovement] = useState<string[]>([]);
  const isLoading = useSelector((state: any) => state.app.isLoading);

  function addLineBreaks(text: string): string {
    const pattern = /(\d+\.)/g;
    let modifiedText = text.replace(pattern, "\n$1");
    modifiedText = modifiedText.replace(/^\n/, "");
    return modifiedText;
  }

  const tabs =
    categories &&
    categories.map((category: Category) => category.category_name);
  useEffect(() => {
    if (stage) {
      let stageIndex = stage.sortOrder
      setCurrentStage(stageIndex);
      setPLP(Math.round(100 / (stages.length - 1)));
    }
  }, [stage]);

  return (
    <DealSummaryWrapper showStage={!opportunityData && !hubspotDealData}>
      {/* <ContactsWrapper>
        <ContactDiv>
          <p className="name bold">Name</p>
          <p className="position bold">Position</p>
          <p className="focus bold">Focus</p>
        </ContactDiv>
        {contacts &&
          contacts.map((contact: Contact, index: number) => {
            if (contact.speaker_type === "buyer") {
              return (
                <ContactDiv key={index}>
                  <p className="name">{contact.speaker_name}</p>
                  <p className="position">{contact.speaker_position}</p>
                  <p className="focus">{contact.speaker_primary_focus}</p>
                </ContactDiv>
              );
            }
          })}
      </ContactsWrapper> */}
      {!opportunityData && !hubspotDealData ? (
        <>
          <StageWrapper
            splice={progressLengthPrecentage}
            activeIndex={currentStage}
          >
            <div className="bar-background">
              <div className="progress-bar"></div>
              <div className="points-wrapper">
                {stages &&
                  stages?.map((stage: Stage, index: number) => (
                    <PointWrapper
                      className=".point-wrapper"
                      start={index === 0}
                      end={index === stages.length - 1}
                    >
                      <Point checked={currentStage >= index}>
                        <Quote checked={currentStage >= index}>
                          <div className="point"></div>
                          {stage.label}
                        </Quote>
                      </Point>
                    </PointWrapper>
                  ))}
              </div>
            </div>
          </StageWrapper>
          <hr />
        </>
      ) : (
        <div
          className="flex"
          // ref={crmContainerRef}
          style={{
            cursor: user.organization_info?.salesforce_token ? "" : "pointer",
            marginBottom: '20px'
          }}
        >
          {/* <FloatingBox style={{ marginRight: '20px' }}>
            <div className="title-wrapper">
              <div className="svg-wrapper">
                <Currency />
              </div>
              <h4>Deal Value</h4>
            </div>
            {opportunityData ? (
              <p>${opportunityData.Amount}</p>
            ) : (
              <p>_________</p>
            )}
          </FloatingBox>
          <FloatingBox className="deal-cycle">
            <div className="title-wrapper">
              <div className="svg-wrapper">
                <Cycle />
              </div>
              <h4>Close Date</h4>
            </div>
            {opportunityData ? (
              <p>{opportunityData.CloseDate}</p>
            ) : (
              <p>_________</p>
            )}
          </FloatingBox> */}
          {/* {connectCRM && (
            <ToolTip
              ref={crmTipRef}
              boldText={
                "Integrate your favorite tools to get the most out of your deal coach!"
              }
              text={
                "We will automatically retrieve deal information to help you stay ahead. "
              }
              buttonText="Integrate CRM"
              pointSide="left"
              buttonFunction={() => navigate("/app/settings/integrations")}
            />
          )} */}
        </div>
      )}
      <TabsWrapper>
        <>
          <Tab active={activeTab === -2} onClick={() => setTab(-2)}>
            {activeTab === -2 ? "General Feedback" : "GF"}
          </Tab>
          <hr />
          <Tab active={activeTab === -1} onClick={() => setTab(-1)}>
            {activeTab === -1 ? "All Categories" : "All"}
          </Tab>
          <hr />
        </>
        {tabs &&
          tabs?.map((tab: string, index: number) => (
            <>
              <Tab
                active={activeTab === index}
                key={index}
                onClick={() => setTab(index)}
              >
                {activeTab === index ? tab : tab[0]}
              </Tab>
              {index !== tabs.length - 1 && <hr />}
            </>
          ))}
      </TabsWrapper>
      {categories &&
        (activeTab === -2 ? (
          <>
            <div className="general-feedback">
              <h3>General Feedback</h3>
              {generalFeedback &&
                generalFeedback.map((feedback: GeneralFeedback) => (
                  <>
                    <h5>{feedback.title}</h5>
                    {Array.isArray(feedback.value) ? (
                      <>
                        {feedback.value.map((value: string, index: number) => (
                          <p key={index}>- {value}</p>
                        ))}
                      </>
                    ) : (
                      <p>
                        {feedback.value.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    )}
                  </>
                ))}
            </div>
          </>
        ) : activeTab !== -1 ? (
          <InfoWrapper>
            {/* <div className="flex">
              <h4>{categories[activeTab].category_name}</h4>
              <ScoreBox score={categories[activeTab].highest_score}>
                {categories[activeTab].highest_score}
                <span>/10</span>
              </ScoreBox>
            </div> */}
            <div className="width-wrapper">
              <p className="title">Notes</p>
              <div className="scroll">
                {categories[activeTab].feedback.map((feedback: Feedback) => (
                  <>
                    <div className="feedback-flex">
                      <Message />
                      <p>{feedback.feedback_text}</p>
                    </div>
                    {feedback.quote && (
                      <p className="cited-text">
                        Reference: "{feedback.quote}"
                      </p>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="width-wrapper-2">
              <p className="title">Recommendations</p>
              {categories[activeTab].recommendations &&
                categories[activeTab].recommendations.map(
                  (recommendation: Recommendation, index: number) => (
                    <div className="feedback-flex" key={index}>
                      <Lightning2 />
                      <div>
                        <p>{recommendation.text}</p>
                      </div>
                    </div>
                  )
                )}
              <GradientButton
                width={"90%"}
                height={"60px"}
                fontSize={16}
                top={1}
              >
                <Sparkle />
                <GradientText
                  onClick={() => {
                    handleMessageSend(
                      `Please generate a talk track for the deal regarding the category ${categories[activeTab].category_name}`
                    );
                  }}
                >
                  Generate Talk Track
                </GradientText>
              </GradientButton>
            </div>
          </InfoWrapper>
        ) : (
          <div className="all-wrapper">
            {categories.map((category: Category, index: number) => (
              <div className="full-wrapper">
                <h3>{category.category_name}</h3>
                <InfoWrapper>
                  <div className="width-wrapper-all">
                    <p className="title">Notes</p>
                    {category.feedback.map((feedback: Feedback) => (
                      <>
                        <div className="feedback-flex">
                          <Message />
                          <p>{feedback.feedback_text}</p>
                        </div>
                        {feedback.quote && (
                          <p className="cited-text">
                            Reference: "{feedback.quote}"
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                  <div className="width-wrapper-2">
                    <p className="title">Recommendations</p>
                    {category.recommendations &&
                      category.recommendations.map(
                        (recommendation: Recommendation, index: number) => (
                          <div className="feedback-flex" key={index}>
                            <Lightning2 />
                            <div>
                              <p>{recommendation.text}</p>
                            </div>
                          </div>
                        )
                      )}
                    <GradientButton
                      width={"90%"}
                      height={"60px"}
                      fontSize={16}
                      top={1}
                    >
                      <Sparkle />
                      <GradientText
                        onClick={() => {
                          handleMessageSend(
                            `Please generate a talk track for the deal regarding the category ${category.category_name}`
                          );
                        }}
                      >
                        Generate Talk Track
                      </GradientText>
                    </GradientButton>
                  </div>
                </InfoWrapper>
              </div>
            ))}
          </div>
        ))}
    </DealSummaryWrapper>
  );
};

export default DealSummary;
