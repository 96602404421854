import React from "react"
import { StepUpSectionWrapper } from "./StepUpSection.styled"
import Stars from "static/svgs/Stars"
import { MarketingGreenButtonWrapper } from "../MarketingHomepage.styled"
import { useNavigate } from "react-router-dom"
type Props = {}

const StepUpSection = (props: Props) => {
  const navigate = useNavigate()
  return (
    <StepUpSectionWrapper>
      <div>
        <Stars />
        <h2>Step Up Your Sales Game</h2>
        <MarketingGreenButtonWrapper onClick={() => {window.location.assign("/contact-us")}}>
          Get Started
        </MarketingGreenButtonWrapper>
      </div>
    </StepUpSectionWrapper>
  )
}

export default StepUpSection
