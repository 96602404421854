import React, { FC, useEffect, useState } from "react";
import { DPAWrapper, TextWrapper } from "./DPA.styled";
import { StructuredText } from "react-datocms";
import { getDPAData } from "cms/helpers";

interface DPA {
  value: any;
}

const DPA: FC = () => {
  const [text, setText] = useState<DPA | null>(null);
  useEffect(() => {
    const getDPA = async () => {
      let response = await getDPAData();
      setText(response.dpa.text);
    };
    getDPA();
  }, []);
  return (
    <DPAWrapper>
      <TextWrapper>
        <StructuredText data={text?.value} />
      </TextWrapper>
    </DPAWrapper>
  );
};

export default DPA;
