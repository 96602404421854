import React from "react";

const Refresh = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9125 17.315C10.665 17.315 10.4325 17.15 10.365 16.895C10.2825 16.595 10.4625 16.2875 10.77 16.205C13.815 15.4025 15.9375 12.6425 15.9375 9.4925C15.9375 5.6675 12.825 2.555 9 2.555C5.7525 2.555 3.6225 4.4525 2.625 5.6H4.83C5.1375 5.6 5.3925 5.855 5.3925 6.1625C5.3925 6.47 5.145 6.7325 4.83 6.7325H1.5075C1.47 6.7325 1.4025 6.725 1.35 6.71C1.2825 6.6875 1.2225 6.6575 1.17 6.62C1.1025 6.575 1.05 6.515 1.0125 6.4475C0.975 6.38 0.945 6.2975 0.9375 6.215C0.9375 6.1925 0.9375 6.1775 0.9375 6.155V2.75C0.9375 2.4425 1.1925 2.1875 1.5 2.1875C1.8075 2.1875 2.0625 2.4425 2.0625 2.75V4.5425C3.285 3.23 5.5875 1.4375 9 1.4375C13.4475 1.4375 17.0625 5.0525 17.0625 9.5C17.0625 13.16 14.595 16.37 11.055 17.3C11.01 17.3075 10.9575 17.315 10.9125 17.315Z"
        fill="#DEE2D4"
      />
      <path
        d="M8.4675 17.5475C8.4525 17.5475 8.4375 17.54 8.43 17.54C7.62 17.4875 6.825 17.3075 6.075 17.015C5.8575 16.9325 5.7075 16.715 5.715 16.4825C5.715 16.415 5.73 16.3475 5.7525 16.2875C5.865 16.0025 6.2025 15.86 6.48 15.965C7.1325 16.22 7.815 16.37 8.505 16.4225C8.7975 16.4375 9.03 16.6925 9.03 16.9925L9.0225 17.0225C9.0075 17.315 8.76 17.5475 8.4675 17.5475ZM4.335 15.935C4.2075 15.935 4.0875 15.89 3.9825 15.815C3.3525 15.305 2.7975 14.7125 2.3475 14.0525C2.28 13.955 2.2425 13.85 2.2425 13.7375C2.2425 13.55 2.3325 13.3775 2.49 13.2725C2.7375 13.1 3.0975 13.1675 3.27 13.4075C3.27 13.415 3.27 13.415 3.27 13.415C3.2775 13.4225 3.285 13.4375 3.2925 13.445C3.6825 14.0075 4.155 14.51 4.6875 14.93C4.815 15.035 4.8975 15.1925 4.8975 15.365C4.8975 15.4925 4.86 15.62 4.7775 15.725C4.665 15.86 4.5075 15.935 4.335 15.935ZM1.83 12.275C1.5825 12.275 1.365 12.1175 1.2975 11.885C1.0575 11.1125 0.9375 10.31 0.9375 9.5V9.4925C0.945 9.185 1.1925 8.9375 1.5 8.9375C1.8075 8.9375 2.0625 9.1925 2.0625 9.5C2.0625 10.205 2.1675 10.895 2.37 11.5475C2.385 11.6075 2.3925 11.66 2.3925 11.72C2.3925 11.96 2.235 12.1775 1.995 12.2525C1.9425 12.2675 1.89 12.275 1.83 12.275Z"
        fill="#DEE2D4"
      />
      <path
        d="M10.4588 5.99853C10.5148 6.07835 10.5524 6.17109 10.555 6.27922L10.7543 8.97942C10.8035 9.65032 10.4289 10.4353 9.88241 10.8186L7.36504 12.5846C7.1133 12.7612 6.7581 12.6988 6.58151 12.4471C6.40492 12.1954 6.46723 11.8402 6.71897 11.6636L9.23635 9.89766C9.45738 9.7426 9.65359 9.33013 9.63059 9.06226L9.43127 6.36206C9.40516 6.05058 9.64022 5.78491 9.9517 5.75879C10.1637 5.74752 10.3512 5.84503 10.4588 5.99853Z"
        fill="#DEE2D4"
      />
    </svg>
  );
};

export default Refresh;
