import React from "react";

const CheckWithCircle = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1000002791">
      <path
        id="Vector"
        d="M10 20.3086C7.75238 20.3086 5.58094 19.5467 3.82856 18.1562C3.79047 18.1181 3.73334 18.0991 3.69524 18.061C2.8 17.3372 2.03808 16.4801 1.44761 15.4705C0.495228 13.9086 0 12.1181 0 10.3086C0 7.22288 1.37142 4.38483 3.77142 2.49912C5.5238 1.08959 7.73333 0.308594 10 0.308594C13.0286 0.308594 15.8476 1.64191 17.7333 3.98477C19.1809 5.73715 20 7.98478 20 10.3086C20 12.1181 19.5048 13.8896 18.5524 15.4705C18 16.3848 17.3333 17.1658 16.5714 17.8324C14.7619 19.4324 12.4381 20.3086 10 20.3086ZM5.37144 15.7371C5.42859 15.7752 5.46665 15.8133 5.5238 15.8514C6.78094 16.899 8.36191 17.4705 10 17.4705C11.7524 17.4705 13.3714 16.861 14.6857 15.6991C15.2381 15.2229 15.7143 14.6515 16.1143 14.0229C16.781 12.8991 17.1429 11.6229 17.1429 10.3467C17.1429 8.68958 16.5714 7.08959 15.5238 5.83245C14.1714 4.15626 12.1524 3.20387 10 3.20387C8.38095 3.20387 6.80001 3.75626 5.54286 4.76579C3.82858 6.11817 2.85714 8.1372 2.85714 10.3467C2.85714 11.6229 3.21903 12.8991 3.8857 14.0229C4.2857 14.6515 4.78097 15.2419 5.37144 15.7371Z"
        fill="#00BF63"
      />
      <path
        id="Vector_2"
        d="M8.76075 13.8535C8.39885 13.8535 8.03694 13.7202 7.75123 13.4345L5.63694 11.3202C5.08456 10.7679 5.08456 9.85346 5.63694 9.30108C6.18932 8.74869 7.1036 8.74869 7.65599 9.30108L8.79882 10.444L12.3607 7.1488C12.9322 6.61547 13.8465 6.65352 14.3798 7.22495C14.9131 7.79638 14.875 8.71066 14.3036 9.24399L9.73217 13.4725C9.4655 13.7201 9.12266 13.8535 8.76075 13.8535Z"
        fill="#00BF63"
      />
    </g>
  </svg>
);

export default CheckWithCircle;
