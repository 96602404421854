import { colors } from "colors";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  background-color: #2f2f2f;
  color: #333;
  padding-top: 130px;
  min-height: 911px;
  svg {
    rect:first-of-type {
      fill: ${colors.white};
    }
  }
`;

export const LogoWrapper = styled.div`
  width: 247px;
  height: 65px;
  margin: 0 auto;
  margin-bottom: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const FormWrapper = styled.div`
  flex: 0 1 100%;
  padding: 100px 50px;
  flex-wrap: wrap;
  background-color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  .horizontal-divider {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px; /* Adjust as needed */
    height: 30px;
    margin: 40px 0;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #dee2d4;
  }

  .or-text {
    padding: 0 10px;
    font-size: 14px;
  }
  @media (min-width: 950px) {
    flex: 0 1 50%;
  }
`;

export const ContentWrapper = styled.div`
  display: none;
  /* border-radius: 40px 0 0 40px; */

  h1 {
    margin-bottom: 15px;
  }
  h2 {
    margin-bottom: 15px;
  }
  h3:first-of-type {
    margin-top: 30px;
  }
  h3 {
    margin: 5px 0;
  }
  button {
    margin-top: 50px;
  }
  @media (min-width: 950px) {
    flex: 0 1 50%;
    color: white;
    background: ${colors.black};
    display: block;
    background: radial-gradient(
      113.75% 51.31% at -37.17% 58.24%,
      #5b5f64 0%,
      ${colors.black} 97.39%
    );
  }
`;

export const LoginForm = styled.form`
  width: 100%;
  .forgot {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const OAuthWrappr = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 1230px) {
    flex-direction: row;
  }
`;

export const OAuthLink = styled.div`
  transition: color 0.3s ease, background-color 0.3s ease;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  height: 62px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 0 10px 0 10px;
  white-space: nowrap;
  &:hover {
    background-color: #cabfbf;
  }
  svg {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    margin-right: 25px;
  }
  @media (min-width: 1230px) {
    width: 290px;
    margin-bottom: unset;
  }
`;
