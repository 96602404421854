import React from "react";

const Send = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33656 13.5211C8.62364 13.5211 7.61469 13.0196 6.81719 10.6211L6.38219 9.31609L5.07719 8.88109C2.68469 8.08359 2.18323 7.07464 2.18323 6.36172C2.18323 5.65484 2.68469 4.63984 5.07719 3.8363L10.2066 2.12651C11.4874 1.69755 12.5568 1.82443 13.2153 2.47693C13.8739 3.12943 14.0007 4.20484 13.5718 5.48568L11.862 10.6151C11.0584 13.0196 10.0495 13.5211 9.33656 13.5211ZM5.36114 4.70026C3.68156 5.26214 3.08344 5.92672 3.08344 6.36172C3.08344 6.79672 3.68156 7.4613 5.36114 8.01714L6.88364 8.52464C7.01656 8.56693 7.12531 8.67568 7.1676 8.80859L7.6751 10.3311C8.23094 12.0107 8.90156 12.6088 9.33656 12.6088C9.77156 12.6088 10.4361 12.0107 10.998 10.3311L12.7078 5.20172C13.0159 4.2713 12.9616 3.51005 12.5689 3.11734C12.1761 2.72464 11.4149 2.6763 10.4905 2.98443L5.36114 4.70026Z"
      fill="white"
    />
    <path
      d="M6.85347 9.15329C6.73868 9.15329 6.62389 9.111 6.53326 9.02037C6.35805 8.84516 6.35805 8.55516 6.53326 8.37995L8.69618 6.211C8.87139 6.03579 9.16139 6.03579 9.3366 6.211C9.5118 6.3862 9.5118 6.6762 9.3366 6.85141L7.17368 9.02037C7.0891 9.111 6.96826 9.15329 6.85347 9.15329Z"
      fill="white"
    />
  </svg>
);

export default Send;
