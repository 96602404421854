import React from "react"
import { IntegrationSectionWrapper } from "./IntegrationSection.styled"
import Google from "static/svgs/Google"
import Slack from "static/svgs/Slack"
import Zoom from "static/svgs/Zoom"
import Teams from "static/svgs/Teams"
import Salesforce from "static/svgs/Salesforce"
type Props = {}

const IntegrationSection = (props: Props) => {
  return (
    <IntegrationSectionWrapper>
      <div className="inner-wrapper">
        <div>
          <h2>
            Most popular <br /> integration apps
          </h2>
          <h4>Streamline call analysis by integrating your tools easily</h4>
        </div>
        <div className="item-row">
          <div className="item">
            <Google />
          </div>
          <div className="item">
            <Slack />
          </div>
          <div className="item">
            <Zoom />
          </div>
          <div className="item">
            <Teams />
          </div>
          <div className="item sf">
            <Salesforce />
          </div>
        </div>
      </div>
    </IntegrationSectionWrapper>
  )
}

export default IntegrationSection
