import React from "react"

const Wheel = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3165 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9969 9.08356 21.837 6.28746 19.7748 4.22523C17.7125 2.16299 14.9164 1.00308 12 1ZM16.1356 14.9393C16.7478 14.0815 17.0768 13.0539 17.0768 12C17.0768 10.9461 16.7478 9.91852 16.1356 9.06067L19.15 6.04731C20.5441 7.71763 21.3078 9.82431 21.3078 12C21.3078 14.1757 20.5441 16.2824 19.15 17.9527L16.1356 14.9393ZM8.61539 12C8.61539 11.3306 8.81389 10.6762 9.1858 10.1196C9.5577 9.56301 10.0863 9.12919 10.7048 8.87302C11.3232 8.61685 12.0038 8.54982 12.6603 8.68042C13.3169 8.81101 13.9199 9.13337 14.3933 9.60671C14.8666 10.0801 15.189 10.6831 15.3196 11.3397C15.4502 11.9962 15.3832 12.6768 15.127 13.2952C14.8708 13.9137 14.437 14.4423 13.8804 14.8142C13.3238 15.1861 12.6694 15.3846 12 15.3846C11.1023 15.3846 10.2415 15.028 9.60672 14.3933C8.97198 13.7585 8.61539 12.8977 8.61539 12ZM17.9527 4.85L14.9393 7.86442C14.0815 7.25222 13.0539 6.92315 12 6.92315C10.9461 6.92315 9.91852 7.25222 9.06068 7.86442L6.04731 4.85C7.71764 3.45585 9.82431 2.6922 12 2.6922C14.1757 2.6922 16.2824 3.45585 17.9527 4.85ZM4.85 6.04731L7.86443 9.06067C7.25223 9.91852 6.92316 10.9461 6.92316 12C6.92316 13.0539 7.25223 14.0815 7.86443 14.9393L4.85 17.9527C3.45586 16.2824 2.69221 14.1757 2.69221 12C2.69221 9.82431 3.45586 7.71763 4.85 6.04731ZM6.04731 19.15L9.06068 16.1356C9.91852 16.7478 10.9461 17.0768 12 17.0768C13.0539 17.0768 14.0815 16.7478 14.9393 16.1356L17.9527 19.15C16.2824 20.5441 14.1757 21.3078 12 21.3078C9.82431 21.3078 7.71764 20.5441 6.04731 19.15Z"
        fill="#00BF63"
      />
    </svg>
  )
}

export default Wheel
