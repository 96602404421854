import { colors, scoreToColor } from "colors"
import styled from "styled-components"

export const DashboardWrapper = styled.div`
  padding: 20px;
  h1 {
    color: #00bf63;
    width: 90%;
    margin: 0 auto;
  }
`

export const ChartsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 1350px;
  .schedule {
    width: 450px;
  }
`

export const ScrollChart = styled.div`
  margin-top: 40px;
  border-radius: 5px;
  width: 390px;
  height: 520px;
  padding: 20px;
  margin-right: 40px;
  overflow: hidden;
  border: 1px solid ${colors.darkGray2};
  transition: transform ease 0.3s;
  .sort-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  &:hover {
    box-shadow: 0px 12px 26px 0px #0000001a, 0px 48px 48px 0px #00000017,
      0px 107px 64px 0px #0000000d, 0px 191px 76px 0px #00000003,
      0px 298px 83px 0px #00000000;
    transform: scale(1.01);
  }
  .sort {
    cursor: pointer;
    margin-right: 38px;
    p {
      margin: 0;
    }
  }
  .filter-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    p {
      margin-right: 20px;
    }
    .tab {
      margin: 0;
      margin-left: 8px;
      width: 50px;
      height: 12px;
      font-size: 11px;
      line-height: 11px;
      padding: 10px 6px;
      gap: 10px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      background-color: ${colors.middleGray};
    }
  }
  .scroll-wrapper {
    overflow-y: auto;
    height: 80%;
    padding-top: 20px;
    h4 {
      margin: 0;
      margin-bottom: 35px;
    }
  }
  .scroll-wrapper-2 {
    overflow-y: auto;
    height: 90%;
    padding-top: 20px;
    h4 {
      margin: 0;
      margin-bottom: 35px;
    }
  }
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    p {
      margin: 0;
      font-size: 13px;
    }
  }
  .category-box {
    padding: 5px;
    background-color: ${colors.darkGray};
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 10px;
  }
`

interface ScrollItemProps {
  borderValue: number
  colorValue: number
}

export const ScrollItem = styled.div<ScrollItemProps>`
  width: 100%;
  height: 110px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .image-circle-border {
    background-color: ${colors.deepGray};
    width: 70px;
    height: 70px;
    min-height: 70px;
    min-width: 70px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-circle {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    .letter-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: ${colors.mainGreen};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .score {
    margin: 0;
    font-size: 14px;
    span {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .circular-border {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    margin-bottom: 10px;
    ${(props) => {
      let { background, text } = scoreToColor(props.colorValue)
      return `
    background: conic-gradient(
      ${background} 0%,
      ${background} ${props.borderValue}%,
      /* This percentage controls how much of the border is colored */
        transparent ${props.borderValue}% /* This makes the rest of the circle transparent */
    );
    `
    }};
  }
`

export const DateCard = styled.div`
  width: 100%;
  background-color: white;
  height: fit-content;
  padding: 20px 20px;
  color: ${colors.black};
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  .date {
    margin: 0;
    margin-bottom: 15px;
    color: ${colors.mainGreen};
    font-size: 14px;
    font-weight: 700;
  }
  h5 {
    margin: 0;
    font-weight: 400;
  }
  h4 {
    margin: 0;
  }
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    margin-right: 10px;
  }
  p {
    margin: 0;
  }
  button {
    left: 77%;
    padding-left: 0px;
    svg {
      margin-right: 5px;
    }
  }
  .next-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 25px;
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  .red-circle {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    color: white;
    background-color: red;
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  .green-circle {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    color: white;
    background-color: ${colors.mainGreen};
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
`

export const PromptCard = styled.div`
  width: 100%;
  background-color: white;
  height: fit-content;
  padding: 20px 20px;
  color: ${colors.black};
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 70px;
  .date {
    margin: 0;
    margin-bottom: 15px;
    color: ${colors.mainGreen};
    font-size: 14px;
    font-weight: 700;
  }
  h5 {
    margin: 0;
    font-weight: 400;
  }
  h4 {
    margin: 0;
  }
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    margin-right: 10px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
  button {
    left: 25%;
    padding-left: 0px;
    svg {
      margin-right: 5px;
    }
  }
`

export const ModalWrapper = styled.div`
    background-color: ${colors.black};
    width: 600px;
    border-radius: 5px;
    height: 500px;
    position: relative;
    display: flex;
    padding: 30px;
    padding-top: 70px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    h3 {
      width: 100%;
      text-align: center;
      margin: 0;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    h4 {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    svg {
      height: 80px;
      width: 80px;
      path {
        fill: ${colors.mainGreen};
      }
    }
`

export const AdditionalQuestions = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.loadingBackground};
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  .modal-wrapper {
    width: 500px;
    position: relative;
    height: fit-content;
    background-color: ${colors.darkGray};
    padding: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding-bottom: 120px;
    h3 {
      width: 100%;
      text-align: center;
      margin: 0;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    h4 {
      width: 100%;
      margin: 0;
      text-align: center;
    }
    svg {
      height: 80px;
      width: 80px;
      path {
        fill: ${colors.mainGreen};
      }
    }
  }
`