import { colors } from "colors";
import styled from "styled-components";

export const InquiryFormWrapper = styled.div`
  height: 90vh;
  padding: 100px 35px;
  padding-top: 100px;
  .form-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    label {
      color: ${colors.white} !important;
    }
  }
`;
