import React from "react"

const Instagram = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.627 2.19824H6.61298C5.5282 2.1994 4.48819 2.63084 3.72114 3.39789C2.95408 4.16495 2.52264 5.20496 2.52148 6.28973V13.3037C2.52264 14.3885 2.95408 15.4285 3.72114 16.1956C4.48819 16.9626 5.5282 17.394 6.61298 17.3952H13.627C14.7117 17.394 15.7517 16.9626 16.5188 16.1956C17.2859 15.4285 17.7173 14.3885 17.7185 13.3037V6.28973C17.7173 5.20496 17.2859 4.16495 16.5188 3.39789C15.7517 2.63084 14.7117 2.1994 13.627 2.19824ZM10.12 13.3037C9.42635 13.3037 8.74831 13.098 8.17159 12.7127C7.59487 12.3273 7.14537 11.7796 6.87993 11.1388C6.61449 10.498 6.54504 9.79284 6.68036 9.11255C6.81568 8.43226 7.14969 7.80737 7.64015 7.31691C8.13061 6.82645 8.7555 6.49244 9.43579 6.35712C10.1161 6.2218 10.8212 6.29125 11.462 6.55669C12.1029 6.82212 12.6506 7.27162 13.0359 7.84834C13.4213 8.42507 13.627 9.10311 13.627 9.79673C13.626 10.7265 13.2562 11.618 12.5987 12.2755C11.9412 12.933 11.0498 13.3028 10.12 13.3037ZM14.5037 6.28973C14.3303 6.28973 14.1608 6.23831 14.0166 6.14197C13.8724 6.04564 13.7601 5.90871 13.6937 5.7485C13.6273 5.5883 13.61 5.41201 13.6438 5.24194C13.6776 5.07187 13.7611 4.91565 13.8838 4.79303C14.0064 4.67041 14.1626 4.58691 14.3327 4.55308C14.5027 4.51925 14.679 4.53662 14.8392 4.60298C14.9994 4.66933 15.1364 4.78171 15.2327 4.92589C15.329 5.07007 15.3805 5.23958 15.3805 5.41299C15.3805 5.64551 15.2881 5.86852 15.1237 6.03294C14.9592 6.19736 14.7362 6.28973 14.5037 6.28973ZM12.458 9.79673C12.458 10.2591 12.3208 10.7112 12.0639 11.0956C11.807 11.4801 11.4419 11.7798 11.0147 11.9568C10.5875 12.1337 10.1174 12.18 9.66385 12.0898C9.21032 11.9996 8.79373 11.7769 8.46676 11.4499C8.13978 11.123 7.91711 10.7064 7.8269 10.2528C7.73668 9.79932 7.78298 9.32923 7.95994 8.90201C8.1369 8.4748 8.43657 8.10966 8.82105 7.85275C9.20553 7.59585 9.65756 7.45873 10.12 7.45873C10.74 7.45873 11.3347 7.70505 11.7732 8.14351C12.2116 8.58197 12.458 9.17665 12.458 9.79673Z"
        fill="#98A2B3"
      />
    </svg>
  )
}

export default Instagram
