import styled, { keyframes, css } from "styled-components";
import { colors } from "colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #4b4b4b;
  padding: 20px;
  width: 48%;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h3`
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin: 0;
  margin-bottom: 10px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface DotProps {
  $isConsistent: boolean;
  $loading?: boolean;
}

const pulseKeyframes = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $isConsistent }) =>
    $isConsistent ? colors.mainGreen : 'white'};
  flex-shrink: 0;
  
  ${props => props.$loading && css`
    animation: ${pulseKeyframes} 1.5s ease-in-out infinite;
    background: #e0e0e0;
  `}
`;

interface ItemTextProps {
  $loading?: boolean;
}

export const ItemText = styled.div<ItemTextProps>`
  font-size: 18px;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #3d42475e;
  
  ${props => props.$loading && css`
    width: 80%;
    height: 16px;
    background: #333333;
    border-radius: 4px;
    animation: ${pulseKeyframes} 1.5s ease-in-out infinite;
  `}
`;