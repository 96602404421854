import React, { FC } from "react";
import { InquiryFormWrapper } from "./InquiryForm.styled";
import HubSpotForm from "components/HubspotForm/HubspotForm";

interface InquiryFormProps {}

const InquiryForm: FC<InquiryFormProps> = () => (
  <InquiryFormWrapper>
    <div className="form-wrapper">
      <h1>Contact Us</h1>
      <HubSpotForm />
    </div>
  </InquiryFormWrapper>
);

export default InquiryForm;
