import React from "react"
import { TeamMemberWrapper } from "./MarketingTeamPage.styled"
import LinkedIn from "static/svgs/LinkedIn"
import { Link } from "react-router-dom"
type TeamMemberProps = {
  headshot: string
  name: string
  companyPosition: string
  linkedinUrl: string
  email: string
}

const TeamMember = (props: TeamMemberProps) => {
  return (
    <TeamMemberWrapper>
      <div className="headshot-wrapper">
        <img src={`${props.headshot}`} alt={`${props.name} Headshot`} />
      </div>
      <div className="info-wrapper">
        <h4>{props.name}</h4>
        <p className="position">{props.companyPosition}</p>
        <a href={props.linkedinUrl} target="_blank">
          <LinkedIn />
        </a>
        <a href={`mailto:${props.email}`}>
          <div className="email-wrapper">
            <p className="email">{props.email}</p>
          </div>
        </a>
      </div>
    </TeamMemberWrapper>
  )
}

export default TeamMember
