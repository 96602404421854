import { colors } from "colors"
import styled from "styled-components"

export const IntegrationSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    padding-top: 100 !important;
    div {
      margin-bottom: 75px;
      h2 {
        margin-bottom: 25px;
        text-align: center;
      }
      h4 {
        text-align: center;
        color: ${colors.lightGray};
      }
    }
    .item-row {
      display: flex;
      justify-content: center;
      width: inherit;
      margin-bottom: 100px;
      flex-wrap: wrap;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background-color: transparent;
        margin: 0 5px 0 5px;
        border-radius: 30px;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        svg {
        }
      }
      .sf {
          background-color: ${colors.black};
        }
      .item:hover {
        background-color: black;
      }
    }
  }
`
