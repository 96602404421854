import React from "react";
import { Container, Title, Item, Dot, ItemText } from "./ComparisonList.styled";

interface ComparisonItem {
  text: string;
}

interface ComparisonListProps {
  title: string;
  items: ComparisonItem[];
  isConsistent: boolean;
  loading: boolean;
}

const ComparisonList: React.FC<ComparisonListProps> = ({
  title,
  items,
  isConsistent,
  loading,
}) => {
  if (loading) {
    return (
      <Container>
        <Title>{title}</Title>
        {[...Array(5)].map((_, index) => (
          <Item key={index}>
            <Dot $isConsistent={true} $loading />
            <ItemText $loading>
              <div className="skeleton-text" />
            </ItemText>
          </Item>
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      {items.map((item: ComparisonItem, index: number) => (
        <Item key={index}>
          <Dot $isConsistent={isConsistent} />
          <ItemText>{item.text}</ItemText>
        </Item>
      ))}
    </Container>
  );
};

export default ComparisonList;
