import { Colors } from "types"
export const colors = {
  mainGreen: "#00BF63",
  middleGray: "#707277",
  darkGray: "#35373E",
  offWhite: "#DEE2D4",
  deepGray: "#505357",
  white: "#EEF7F2",
  white2: "#E4E7E5",
  textWhite: "#EEF7F2",
  lightGray: "#C6C8CF",
  grayWhite: "#D9D9D9",
  loadingBackground: "#ddd7d780",
  red: "#F56565",
  darkGray2: "#5b5f64",
  black: "#2F2F2F",
  green: "#00BF63",
  deepGreen: "#05391F",
  limeGreen: "#00BF63",
  neonYellow: "#C9F153",
  offMainGreen: "#5ECD97",
  softGreen: "#30DF8B",
  neonGreen: "#AFF58D",
  keyLime: "#9EEF78",
  brightLime: "#48FDA6",
  softYellow: "#E1F7A2",
  yellow: "#E1F7A1",
  mint: "#34D399",
  fadedGreen: "#00bf6361",
  boxShadow:
    "0px 12px 27px 0px #0000001A, 0px 49px 49px 0px #00000017, 0px 109px 66px 0px #0000000D, 0px 195px 78px 0px #00000003, 0px 304px 85px 0px #00000000",
  boxShadow2:
    "0px 12px 27px 0px #0000001A, 0px 49px 49px 0px #00000017, 0px 109px 66px 0px #0000000D, 0px 195px 78px 0px #00000003, 0px 304px 85px 0px #00000000",
  boxShadow3:
    "0px 24px 53px 0px #0000001A, 0px 97px 97px 0px #00000017, 0px 219px 131px 0px #0000000D, 0px 389px 155px 0px #00000003, 0px 607px 170px 0px #00000000",
  boxShadow4:
    "0px 6px 11px 0px #0000001A, 0px 10px 97px 0px #00000017, 0px 15px 131px 0px #0000000D, 0px 389px 155px 0px #00000003, 0px 607px 170px 0px #00000000",
}

export const scoreToColor = (score: number): Colors => {
  switch (Math.floor(score)) {
    case 0:
      return { background: colors.white2, text: colors.black }
    case 1:
      return { background: colors.white2, text: colors.black }
    case 2:
      return { background: colors.white2, text: colors.black }
    case 3:
      return { background: colors.white2, text: colors.black }
    case 4:
      return { background: colors.yellow, text: colors.black }
    case 5:
      return { background: colors.yellow, text: colors.black }
    case 6:
      return { background: colors.yellow, text: colors.black }
    case 7:
      return { background: colors.mainGreen, text: colors.black }
    case 8:
      return { background: colors.mainGreen, text: colors.black }
    case 9:
      return { background: colors.mainGreen, text: colors.black }
    case 10:
      return { background: colors.mainGreen, text: colors.black }
    default:
      return { background: colors.mainGreen, text: colors.black }
  }
}
