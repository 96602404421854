import React from "react";

const Edit = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1_7493)">
      <path
        d="M4.80355 9.71719L5.76603 9.43954L11.5612 3.57499C11.6069 3.52815 11.6323 3.46521 11.632 3.39976C11.6317 3.33431 11.6057 3.27161 11.5595 3.2252L11.2125 2.87431C11.19 2.85126 11.1632 2.83292 11.1337 2.82034C11.1041 2.80776 11.0723 2.80121 11.0401 2.80105C11.0079 2.8009 10.9761 2.80715 10.9464 2.81945C10.9167 2.83175 10.8897 2.84984 10.867 2.87267L5.08721 8.72191L4.80355 9.71719ZM11.8995 2.17909L12.2466 2.53052C12.7253 3.01532 12.7297 3.79744 12.2553 4.27732L6.28362 10.3211L4.22638 10.9136C4.10078 10.9488 3.96637 10.9327 3.85263 10.8688C3.73889 10.805 3.65512 10.6986 3.61971 10.5731C3.59335 10.4829 3.59297 10.387 3.61861 10.2965L4.21709 8.19777L10.1729 2.1698C10.2862 2.05576 10.421 1.96542 10.5695 1.90404C10.7181 1.84265 10.8773 1.81146 11.038 1.81227C11.1987 1.81309 11.3577 1.84589 11.5056 1.90878C11.6535 1.97166 11.7874 2.06391 11.8995 2.17909ZM5.60371 2.80927C5.8748 2.80927 6.09451 3.03172 6.09451 3.30609C6.09495 3.37095 6.08259 3.43525 6.05815 3.49533C6.03371 3.55541 5.99767 3.61009 5.95209 3.65623C5.90651 3.70237 5.85227 3.73907 5.7925 3.76424C5.73272 3.78941 5.66857 3.80255 5.60371 3.80291H3.64048C3.09829 3.80291 2.65886 4.2478 2.65886 4.796V10.7567C2.65886 11.3055 3.09829 11.7504 3.64048 11.7504H9.53017C10.0724 11.7504 10.5123 11.3055 10.5123 10.7567V8.77001C10.5123 8.49564 10.732 8.27319 11.0031 8.27319C11.2742 8.27319 11.4939 8.49564 11.4939 8.77056V10.7567C11.4939 11.8542 10.6145 12.744 9.53017 12.744H3.64048C2.55611 12.744 1.67725 11.8542 1.67725 10.7567V4.796C1.67725 3.69906 2.55611 2.80927 3.64048 2.80927H5.60371Z"
        fill="#E4E7E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_7493">
        <rect
          width="13.1174"
          height="13.1174"
          fill="white"
          transform="translate(0.587402 0.722412)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Edit;
