import { colors } from "colors";
import styled from "styled-components";

export const PrivacyWrapper = styled.div`
  height: fit-content;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  a {
    color: ${colors.mainGreen}
  }
`;

export const TextWrapper = styled.div`
  height: 100%;
  width: 70%;
`;
