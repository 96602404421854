import React from "react"

const Minus = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M19.8503 11.3331C19.8503 11.5173 19.7771 11.6939 19.6469 11.8242C19.5166 11.9544 19.34 12.0276 19.1558 12.0276H3.87804C3.69386 12.0276 3.51723 11.9544 3.38699 11.8242C3.25676 11.6939 3.18359 11.5173 3.18359 11.3331C3.18359 11.1489 3.25676 10.9723 3.38699 10.8421C3.51723 10.7118 3.69386 10.6387 3.87804 10.6387H19.1558C19.34 10.6387 19.5166 10.7118 19.6469 10.8421C19.7771 10.9723 19.8503 11.1489 19.8503 11.3331Z"
          fill="#98A2B3"
        />
      </g>
    </svg>
  )
}

export default Minus
