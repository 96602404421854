import { colors } from 'colors';
import styled from 'styled-components';

export const UserTableWrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.middleGray};
  hr {
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
    width: 100%;
    margin: 5px auto;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .trash {
    width: 16%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  svg {
    width: 25px;
    height: 25px;
    path {
      stroke: ${colors.offWhite};
    }
  }
`;