import React, { useState, useEffect, useRef } from "react";
import {
  DropdownWrapper,
  SearchInput,
  DropdownList,
  DropdownItem,
  SelectedItem,
  ClearButton,
  SelectedItemText,
} from "./SearchableDropdown.styled";

interface SearchableDropdownProps<T> {
  items: T[];
  displayProperty: keyof T | ((item: T) => string);
  idProperty: keyof T;
  onSelect: (item: T | null) => void;
  placeholder?: string;
  selectedItem?: T | null;
}

function SearchableDropdown<T>({
  items,
  displayProperty,
  idProperty,
  onSelect,
  placeholder = "Search...",
  selectedItem = null,
}: SearchableDropdownProps<T>) {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getDisplayValue = (item: T): string => {
    if (typeof displayProperty === "function") {
      return displayProperty(item);
    }
    return String(item[displayProperty]);
  };

  useEffect(() => {
    setFilteredItems(
      items.filter((item) =>
        getDisplayValue(item).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, items, displayProperty]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (item: T) => {
    onSelect(item);
    setSearchTerm("");
    setOpen(false);
  };

  const clearSelection = () => {
    onSelect(null);
    setSearchTerm("");
    setOpen(true);
  };

  return (
    <DropdownWrapper ref={wrapperRef}>
      {selectedItem ? (
        <SelectedItem onClick={() => setOpen(!open)}>
          <SelectedItemText>{getDisplayValue(selectedItem)}</SelectedItemText>
          <ClearButton onClick={(e) => { e.stopPropagation(); clearSelection(); }}>&times;</ClearButton>
        </SelectedItem>
      ) : (
        <SearchInput
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
        />
      )}
      {open && (
        <DropdownList>
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <DropdownItem
                key={String(item[idProperty])}
                onClick={() => handleSelect(item)}
              >
                {getDisplayValue(item)}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>No matching items found</DropdownItem>
          )}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
}

export default SearchableDropdown;