import { colors } from 'colors';
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: ${colors.black};
  max-width: 200px;
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  width: 156px;
  margin: 0;
  color: ${colors.black};
`;

export const DropdownItem = styled.li`
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SelectedItem = styled.div`
  width: 100%;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const SelectedItemText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #888;
  width: 30px !important;
  height: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;
