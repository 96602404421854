import React, { FC, useEffect, useState } from "react";
import { SLAWrapper, TextWrapper } from "./SLA.styled";
import { StructuredText } from "react-datocms";
import { getSLAData } from "cms/helpers";

interface SLA {
  value: any;
}

const SLA: FC = () => {
  const [text, setText] = useState<SLA | null>(null);
  useEffect(() => {
    const getDPA = async () => {
      let response = await getSLAData();
      setText(response.sla.text);
    };
    getDPA();
  }, []);
  return (
    <SLAWrapper>
      <TextWrapper>
        <StructuredText data={text?.value} />
      </TextWrapper>
    </SLAWrapper>
  );
};

export default SLA;
