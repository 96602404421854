import { colors } from "colors";
import styled from "styled-components";

export const SaveTimeSectionWrapper = styled.div`
  margin-bottom: 150px;
  .row {
    display: flex;
    align-items: center;
    min-height: 250px;
    margin-bottom: 90px;
    .section-left {
      display: flex;
      flex-direction: column;
      flex: 0 1 100%;
      h2:nth-of-type(1) {
        margin-top: 20px;
      }
      h2:nth-of-type(2) {
        color: ${colors.green};
        margin-bottom: 25px;
      }
      h4 {
        color: ${colors.lightGray};
        margin-top: 20px;
        max-width: 820px;
      }
      mark {
        color: ${colors.mainGreen};
      }
    }
    .section-right {
      flex: 0 1 0%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      min-height: inherit;

      svg {
        position: absolute;
        top: -20%;
      }
    }
  }
  @media (min-width: 768px) {
    .section-left {
      flex: 0 1 65% !important;
    }
    .section-right {
      flex: 0 1 35% !important;
    }
  }
`;

export const MarketingFeaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;
  h3 {
    font-weight: 500;
    margin: 20px 0 20px 0;
  }
`;
interface MarketingFeatureProps {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  backgroundColor?: string;
}
export const MarketingFeature = styled.div<MarketingFeatureProps>`
  flex: 0 1 100%;
  padding: 25px;
  position: relative;
  background: ${(props) =>
    `${
      props.backgroundColor && props.backgroundColor === "black"
        ? "#141418"
        : ""
    }${
      props.backgroundColor && props.backgroundColor === "gradient"
        ? `linear-gradient(#353747, #2f2f2f)`
        : ""
    }`};
  border-radius: 30px;
  margin-bottom: ${(props) => props?.marginBottom && `${props.marginBottom}px`};
  margin-top: ${(props) => props?.marginTop && `${props.marginTop}px`};
  margin-right: ${(props) => props?.marginRight && `${props.marginRight}px`};
  margin-left: ${(props) => props?.marginLeft && `${props.marginLeft}px`};
  li {
    font-size: 24px;
    margin-bottom: 20px;
  }
  button {
    margin-top: 15px;
    margin-left: 25%;
  }
  p {
    font-size: 21px;
    margin: 0;
  }
  @media (min-width: 906px) {
    flex: 0 1 32% !important;
  }
`;
