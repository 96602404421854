import { colors } from "colors";
import styled from "styled-components";

export const CallModalWrapper = styled.div`
  position: relative;
  min-height: 700px;
  height: 700px;
  background-color: ${colors.deepGray};
  z-index: 1000;
  width: 800px;
  padding: 30px;
  text-align: center;
  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
    background: ${colors.middleGray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.black};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #000;
  }

  ::-webkit-scrollbar-track {
    height: 100px; /* Fixed height of the scrollbar track */
  }
  h2 {
    text-align: center;
    color: ${colors.mainGreen};
  }
  .table-wrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 60%;
  }
  p {
    width: 100%;
  }
`;

export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #3c3c3c;
  color: white;
`;

export const TableHeader = styled.th`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #444;
  border-bottom: 1px solid #555;
`;

export const TableHeaderCell = styled.th`
  flex: 1;
  text-align: left;
  padding: 10px;
  font-weight: bold;

  &:last-child {
    text-align: right;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #555;

  &:nth-child(odd) {
    background-color: #686868;
  }

  &:nth-child(even) {
    background-color: ${colors.deepGray};
  }
`;

export const TableCell = styled.td`
  flex: 1;
  text-align: left;
  padding: 10px;
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &:last-child {
    text-align: right;
  }
  p {
    margin: 0;
    width: 100%;
  }
  .label-wrapper {
    margin-top: 10px;
  }
  label {}
  input {
    color: ${colors.white2};
    background-color: ${colors.black};
    border: none;
    border-radius: 3px;
    font-size: 14px;
    height: 25px;
    margin-top: 5px;
    width: 250px;
    padding-left: 8px;
  }
`;

export const ActionSelect = styled.select`
  background-color: #444;
  color: white;
  border: 1px solid #555;
  padding: 5px;
  border-radius: 3px;
  outline: none;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-right: 10px;
`;
