import React, { FC, useEffect } from "react";
import { TabsWrapper } from "./Settings.styled";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";

interface SettingsProps {}

const SettingsPage: FC<SettingsProps> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      !pathname.includes("profile") &&
      !pathname.includes("company") &&
      !pathname.includes("integrations")
    ) {
      navigate("/app/settings/profile");
    }
  }, []);
  return (
    <PageWrapper pageTitle="Settings">
      <TabsWrapper>
        <Link to="/app/settings/profile">
          <button className={pathname.includes("profile") ? "selected" : ""}>
            My Profile
          </button>
        </Link>
        {/* <hr />
      <Link to="/app/settings/reports">
        <button>My Reports</button>
      </Link>
      <hr />
      <Link to="/app/settings/notifications">
        <button>Notifications</button>
      </Link> */}
        <hr />
        <Link to="/app/settings/company-account">
          <button className={pathname.includes("company") ? "selected" : ""}>
            Company Account
          </button>
        </Link>
        <hr />
        {/* <Link to="/app/settings/coaching">
        <button>Coaching Materials</button>
      </Link>
      <hr /> */}
        <Link to="/app/settings/integrations">
          <button
            className={pathname.includes("integrations") ? "selected" : ""}
          >
            Integrations
          </button>
        </Link>
        {/* <hr />
        <Link to="/app/settings/billing">
          <button className={pathname.includes("billing") ? "selected" : ""}>Billing</button>
        </Link> */}
      </TabsWrapper>
      <Outlet />
    </PageWrapper>
  );
};

export default SettingsPage;
