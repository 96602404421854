import React, { FC } from "react";
import { UserRowWrapper } from "./UserRow.styled";
import { User } from "types";
import { useDrag } from "react-dnd";
import { DropDown } from "shared/shared_styled_comps/forms.styled";
import { putSpecificUser } from "api/helpers";

interface UserRowProps {
  user: User;
  isCurrentUser?: boolean;
  teamId: string;
}

const UserRow: FC<UserRowProps> = ({ teamId, user, isCurrentUser }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "USER",
    item: { user, teamId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    putSpecificUser(user.user_id, {"user_role": event.target.value});
  };

  return (
    <UserRowWrapper
      isCurrentUser={isCurrentUser}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="profile-icon">
        {user.profile_pic_url ? (
          <img src={user.profile_pic_url} />
        ) : (
          <h2>
            {user.first_name && user.first_name[0]}
            {user.last_name && user.last_name[0]}
          </h2>
        )}
      </div>
      <p className="name">
        {user.first_name} {user.last_name}
      </p>
      <p className="role">
        <DropDown value={user.user_role ? user.user_role : "rep"} onChange={handleChange}>
          <option value={"admin"}>Admin</option>
          <option value="rep">Rep</option>
        </DropDown>
      </p>
      <p className="email">{user.email}</p>
      <p className="active">{user.registered ? "Active" : "Invited"}</p>
      <p className="team"></p>
      <div className="close-wrapper" style={{ cursor: "grab" }}>
        <div className="space">⇅</div>
      </div>
    </UserRowWrapper>
  );
};

export default UserRow;
