import React from "react"

const LinkedinBlack = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11376 3.26275C6.11354 3.68576 5.9453 4.09137 5.64604 4.39033C5.34677 4.68929 4.941 4.85713 4.51799 4.85692C4.09498 4.85671 3.68938 4.68846 3.39042 4.3892C3.09145 4.08994 2.92362 3.68417 2.92383 3.26116C2.92404 2.83815 3.09228 2.43255 3.39155 2.13358C3.69081 1.83462 4.09658 1.66678 4.51959 1.66699C4.9426 1.6672 5.3482 1.83545 5.64717 2.13471C5.94613 2.43397 6.11397 2.83974 6.11376 3.26275ZM6.1616 6.03799H2.97168V16.0225H6.1616V6.03799ZM11.2017 6.03799H8.02771V16.0225H11.1698V10.783C11.1698 7.86422 14.9738 7.59308 14.9738 10.783V16.0225H18.1238V9.69843C18.1238 4.77797 12.4936 4.96139 11.1698 7.37776L11.2017 6.03799Z"
        fill="#2F2F2F"
      />
    </svg>
  )
}

export default LinkedinBlack
