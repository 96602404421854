import { colors } from "colors";
import styled from "styled-components";

export const MarketingWaitlistPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.black};
  padding-bottom: 211px;
  background: radial-gradient(
      65.75% 76.31% at 100.83% 28.24%,
      rgba(0, 191, 99, 0.3) 1%,
      #2f2f2f 97.39%
    ),
    radial-gradient(
      113.75% 51.31% at -37.17% 58.24%,
      #5b5f64 0%,
      ${colors.black} 97.39%
    );

  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 211px 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 68px;
      font-weight: 700;
    }
    h2 {
      font-size: 53px;
      color: white;
      color: ${colors.green};
      margin-bottom: 30px;
    }
    .team-header {
      color: ${colors.green};
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 30px;
      max-width: 720px;
      text-align: center;
    }
    input {
    }
    hr {
      width: 100%;
      margin-top: 70px;
      margin-bottom: 70px;
      border-bottom: transparent;
      border-top: 1px solid #707277;
    }

    .step-up {
      h2 {
        color: white;
      }
    }
  }
  @media (min-width: 650px) {
    .inner-wrapper {
      .faqs-wrapper {
        .message-wrapper {
          .message {
            flex-direction: row;
            .text-wrapper {
              flex: 0 1 70%;
              padding-right: 15px;
            }
            .button-wrapper {
              flex: 0 1 30%;
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 694px) {
    .inner-wrapper {
      h2 {
        font-size: 53px;
      }
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 211px 78px 0 78px;
    }
  }
`;
export const WaitlistFormWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .faqs-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  form {
    background-color: ${colors.darkGray};
    flex: 0 1 100%;
    padding: 40px;
    margin-bottom: 15px;
    min-height: inherit;
    border-radius: 5px;
    p {
      text-align: center;
      margin-top: 40px;
      font-size: 22px;
      color: ${colors.mainGreen};
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .form-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 40px;
      border-radius: 5px;
      min-height: inherit;
      /* box-shadow: 0px 3px 7px 0px #0000001a, 0px 12px 12px 0px #00000017,
        0px 27px 16px 0px #0000000d, 0px 48px 19px 0px #00000003,
        0px 75px 21px 0px #00000000; */

      margin-bottom: 20px;
      div {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        label {
          color: ${colors.white2};
          margin-bottom: 10px;
          font-weight: bold;
          //styleName: Paragraph 1;
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          text-align: left;
        }
        input {
          padding: 5px 10px 5px 10px;
          font-size: 18px;
          border-radius: 2px;
          border: 1px solid ${colors.white2};
          margin-top: 0;
          background: ${colors.black};
          color: ${colors.white2}
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
        flex: 0 1 100%;
      }
    }
  }
`;
