import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deal = queryParams.get("deal");

  const redirectDealShare = () => {
    const path = '/signup';
    return <Navigate to={`${path}?redirect=${location.pathname}`} />;
  };

  const redirectWithParams = () => {
    const newPath = '/login';
    return <Navigate to={`${newPath}${location.search}`} />;
  };

  if (isAuthenticated) {
    return <Component {...rest} />;
  } else if (deal === "share") {
    return redirectDealShare()
  } else {
    return redirectWithParams()
  }
};

export default ProtectedRoute;
