import React from "react"

const Twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3829 6.11905L16.1983 8.30361C15.757 13.4194 11.4427 17.3955 6.27937 17.3955C5.2185 17.3955 4.34395 17.2274 3.67981 16.8957C3.14426 16.6276 2.92508 16.3405 2.87028 16.2586C2.82142 16.1854 2.78975 16.102 2.77764 16.0148C2.76552 15.9275 2.77328 15.8387 2.80033 15.7549C2.82738 15.671 2.87302 15.5944 2.93384 15.5307C2.99466 15.467 3.06909 15.4179 3.15157 15.387C3.17057 15.3797 4.9226 14.7068 6.03534 13.426C5.41824 12.9186 4.87955 12.3228 4.43674 11.6579C3.53076 10.3128 2.51666 7.97629 2.82937 4.48464C2.83928 4.37369 2.88069 4.26789 2.94871 4.17968C3.01674 4.09148 3.10856 4.02455 3.21335 3.98678C3.31814 3.94901 3.43154 3.94197 3.5402 3.96649C3.64886 3.99101 3.74825 4.04606 3.82667 4.12517C3.85224 4.15075 6.25818 6.54354 9.19967 7.31946V6.87451C9.19855 6.40793 9.2908 5.94584 9.47099 5.51546C9.65117 5.08507 9.91565 4.69509 10.2488 4.36847C10.5724 4.04534 10.9575 3.79029 11.3812 3.61837C11.805 3.44646 12.2589 3.36116 12.7162 3.36752C13.3296 3.37357 13.931 3.53823 14.4619 3.8455C14.9928 4.15276 15.4352 4.59217 15.7461 5.12101H17.9693C18.085 5.12092 18.1981 5.15515 18.2943 5.21937C18.3905 5.28359 18.4655 5.37491 18.5098 5.48177C18.5541 5.58863 18.5656 5.70623 18.543 5.81967C18.5205 5.93311 18.4647 6.0373 18.3829 6.11905Z"
        fill="#98A2B3"
      />
    </svg>
  )
}

export default Twitter
