import styled from "styled-components";

export const AdminLoginWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
