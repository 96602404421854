import { colors } from "colors"
import styled from "styled-components"

export const FAQSectionWrapper = styled.div`
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .faq-inner-wrapper {
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    h3 {
      text-align: center;
      margin-bottom: 30px;
      color: ${colors.mainGreen};
      font-weight: 600;
    }
    h4 {
      text-align: center;
      margin-bottom: 50px;
      color: ${colors.lightGray};
    }
  }
`

export const FAQAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .faq {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: none;
    color: white;
    padding: 10px;
    margin-bottom: 5px;
    .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      svg {
        margin-right: 20px;
      }
      h3 {
        margin: 0;
        font-size: 20px;
        text-align: left;
      }
    }
    .answer-wrapper {
      margin-left: 43px;
      h4 {
        font-size: 16px;
        text-align: left;
      }
    }
  }
`
