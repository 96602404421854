import React from "react"

const Lightning2 = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0495 22.1307C39.9944 21.8974 39.8825 21.6812 39.7237 21.5015C39.5649 21.3217 39.3642 21.184 39.1394 21.1005L28.653 17.167L31.3215 3.81915C31.3819 3.50902 31.3398 3.18765 31.2016 2.90353C31.0633 2.61942 30.8364 2.38798 30.5551 2.24413C30.2738 2.10029 29.9533 2.05185 29.6421 2.10611C29.3308 2.16038 29.0456 2.31442 28.8296 2.54498L8.44291 24.3878C8.27733 24.5623 8.15755 24.7751 8.09427 25.0071C8.03099 25.2392 8.02617 25.4833 8.08026 25.7177C8.13435 25.9521 8.24565 26.1694 8.40422 26.3503C8.56279 26.5311 8.7637 26.6699 8.98898 26.7542L19.479 30.6877L16.8178 44.0209C16.7574 44.3311 16.7995 44.6524 16.9377 44.9365C17.076 45.2207 17.3029 45.4521 17.5842 45.5959C17.8655 45.7398 18.186 45.7882 18.4973 45.734C18.8085 45.6797 19.0937 45.5257 19.3097 45.2951L39.6964 23.4522C39.859 23.2777 39.9762 23.066 40.0378 22.8355C40.0994 22.6051 40.1034 22.3631 40.0495 22.1307ZM20.6786 39.5741L22.5843 30.0397C22.6526 29.7015 22.5985 29.3502 22.4318 29.0481C22.2652 28.7461 21.9967 28.5131 21.6742 28.3905L12.0561 24.7774L27.4589 8.27509L25.555 17.8095C25.4868 18.1476 25.5408 18.499 25.7075 18.801C25.8742 19.1031 26.1426 19.3361 26.4651 19.4586L36.076 23.0627L20.6786 39.5741Z"
        fill="#34D399"
      />
    </svg>
  )
}

export default Lightning2
