import styled from "styled-components";
import { colors } from "colors";

interface QuickPitchWrapperProps {
  signup?: boolean;
}

export const QuickPitchWrapper = styled.div<QuickPitchWrapperProps>`
  padding: 100px 80px;
  padding-top: 40px;
  ${(props) =>
    props.signup &&
    `
    padding: 100px 80px;
    padding-top: 210px;
    color: white;
    position: relative;
    z-index: 22;
`}
  .pitch-inner-wrapper {
    width: 100%;
    max-width: 650px;
    .driven-wrapper {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
      h1 {
        margin-bottom: 0;
        font-size: 72px;
      }
    }
    .coaching {
      color: ${colors.green} !important;
      font-size: 48px;
    }
    h4 {
      font-weight: 400;
      margin-bottom: 30px;
    }
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        svg {
          margin-right: 10px;
        }
        p {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .next-steps {
      display: flex;
      align-items: baseline;
      margin-top: 20px;
      h4 {
        margin-right: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        a {
          color: white;
          transition: 0.2s ease-in-out;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
`;
