import styled from 'styled-components';

export const AddCallWrapper = styled.div`
  color: white;
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    text-align: center;
  }
  .submit-button {
    color: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    background: none;
    width: 90%;
    margin: 0 auto;
    height: 50px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient colors */
    border-image-slice: 1; /* Ensure the gradient is applied to the entire border */
    padding: 10px 20px; /* Add padding to make the button visible */
    background-color: transparent; /* Transparent background to see the gradient border */
    color: white; /* Text color */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    border-radius: 5px;
    background-clip: padding-box;
    background: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient background */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent */
    color: transparent;
    &:hover {
      -webkit-background-clip: unset; /* Clip the gradient to the text */
      -webkit-text-fill-color: unset;
      color: #333;
    }
  }
`;

export const AddCallForm = styled.form`
  width: 100%;
`;
