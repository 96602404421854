import { colors } from "colors"
import styled from "styled-components"

export const MarketingFAQsPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.black};
  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 211px 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 68px;
      font-weight: 700;
    }
    h2 {
      font-size: 53px;
      color: white;
      color: ${colors.green};
      margin-bottom: 30px;
    }
    .team-header {
      color: ${colors.green};
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 30px;
      color: ${colors.lightGray};
    }
    input {
    }
    hr {
      width: 100%;
      margin-top: 70px;
      margin-bottom: 70px;
      border-bottom: transparent;
      border-top: 1px solid #707277;
    }
    .faqs-wrapper {
      width: 100%;
      max-width: 1350px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .faqs-inner-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
      .message-wrapper {
        background-color: #505357;
        flex: 0 1 100%;
        padding: 40px;
        margin-bottom: 15px;
        .message {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 40px;
          border-radius: 5px;
          .text-wrapper {
            flex: 0 1 100%;

            p {
              color: black;
              &:nth-child(1) {
                font-weight: 600;
              }
            }
          }
          .button-wrapper {
            display: flex;
            justify-content: flex-end;
            flex: 0 1 100%;
          }
        }
      }
    }
    .step-up {
      h2 {
        color: white;
      }
    }
  }
  @media (min-width: 650px) {
    .inner-wrapper {
      .faqs-wrapper {
        .message-wrapper {
          .message {
            flex-direction: row;
            .text-wrapper {
              flex: 0 1 70%;
              padding-right: 15px;
            }
            .button-wrapper {
              flex: 0 1 30%;
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 694px) {
    .inner-wrapper {
      h2 {
        font-size: 53px;
      }
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 211px 78px 0 78px;
    }
  }
`

export const FAQWrapper = styled.div`
  flex: 0 1 100%;
  background-color: ${colors.deepGray};
  padding: 40px;
  min-width: 300px;
  margin-bottom: 20px;
  .icon {
    height: 35px;
    width: 35px;
    border-radius: 5000px;
    background-color: #2f2f2f;
  }
  .question {
    font-size: 15px;
    font-weight: 700;
    min-height: calc(35px);
  }
  .answer {
    font-size: 12px;
    font-weight: 500;
  }

  @media (min-width: 650px) {
    flex: 0 1 50%;
    margin-bottom: unset;
  }
  @media (min-width: 1056px) {
    flex: 0 1 33.33333333333333%;
  }
`
