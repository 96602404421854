import styled from "styled-components";
import { colors } from "colors";

interface ChatWrapperProps {
  height?: string;
  width?: string;
}
export const DealCoachChatWrapper = styled.div<ChatWrapperProps>`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  h3 {
    color: ${colors.mainGreen};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
`;

export const ChatWrapper = styled.div`
  width: 625px;
  height: 100%;
  padding: 0;

  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
    background: ${colors.middleGray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.black};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #000;
  }

  ::-webkit-scrollbar-track {
    height: 100px; /* Fixed height of the scrollbar track */
  }
`;

export const ChatBox = styled.div`
  width: 100%;
  height: 90%;
  padding-right: 0;
  padding-bottom: 20px;
  overflow: auto;
  overflow-x: hidden;
  .load-more {
    text-decoration: underline;
    width: 100%;
    text-align: center;
  }
  .no-more {
    width: 100%;
    text-align: center;
  }
  .full-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .deal-box {
    width: 530px;
    height: 200px;
    padding: 15px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 5px;
      padding: 1px; /* control the border thickness */
      background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
    font-size: 20px;
    text-align: center;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333; /* Change color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .no-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px;
    h3 {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 20px;
      width: 100%;
    }
    .svg-wrapper {
      margin-bottom: 30px;
      margin-top: 30px;
      svg {
        width: 100px;
        height: 100px;
        path {
          fill: url(#gradient);
        }
      }
    }
  }
`;

export const ChatBotMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.white};
  .text {
    width: 86%;
    margin: 0;
    margin-bottom: 20px;
  }
  p {
    color: ${colors.mainGreen};
    font-size: 9px;
    font-weight: 900;
    margin: 0;
  }
  svg {
    width: 9px;
    height: 9px;
    path {
      fill: url(#gradient);
    }
  }
  .inner-svg {
    svg {
      height: inherit;
      width: inherit;
    }
  }
  .icon-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .icon {
    width: 18px;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border-radius: 100%;
    margin-right: 5px;
    /* border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient colors */
    /* border-radius: 100%;
    border-image-slice: 1; /* Adjust the slice value to control the size of the border */
    /* border-image-repeat: round; */
  }
`;

export const UserMessage = styled.div`
  width: 95%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  p {
    font-size: 12px;
  }

  .text {
    border: 1px solid ${colors.middleGray};
    background-color: ${colors.lightGray};
    color: ${colors.black};
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    line-height: 17px;
    letter-spacing: 0px;
  }
  .pfp {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 100%;
    margin-left: 0px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
`;

export const TextInput = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: center;
  input {
    flex-grow: 1;
    margin-right: 10px;
    width: 90%;
    height: 36.01px;
    padding: 3.6px 50px 3.6px 12px;
    border-radius: 20px;
    justify-content: space-between;
    border: none;
    box-shadow: 0px 4.801233291625977px 14.403700828552246px -2.4006166458129883px
      #00000014;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    white-space: pre-wrap;
    transition: transform 0.2s ease;
    &:focus {
      outline: none;
      transform: scale(1.06);
    }
  }
  button {
    position: absolute;
    padding: 6px;
    background-color: ${colors.mainGreen};
    color: white;
    border: none;
    top: 50%;
    right: 14%;;
    transform: translate(50%, -50%);
    box-shadow: 0px 2.4006166458129883px 4.801233291625977px 0px #5661f640;
    /* border-radius: 3px; */
    cursor: pointer;
    border-radius: 100%;
    font-weight: 700;
    letter-spacing: 1.2px;
    height: 28px;
    width: 28px;
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
    }
    &:hover {
      transform: translate(50%, -50%) scale(1.1);
      box-shadow: 0px 4.801233291625977px 14.403700828552246px -2.4006166458129883px
        #00000014;
    }
  }
`;

export const PrePromptsWrapper = styled.div`
  width: 364px;
  height: 100%;
  overflow: hidden;
  ::-webkit-scrollbar {
    height: 14px;
    width: 14px;
    background: ${colors.middleGray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.black};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #000;
  }

  ::-webkit-scrollbar-track {
    height: 100px; /* Fixed height of the scrollbar track */
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: ${colors.lightGray};
    opacity: 0.7;
  }
  .preprompts-scroll {
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 50px;
    padding-top: 0px;
    padding-top: 20px;
    margin-top: 20px;
  }
  h5 {
    color: ${colors.white};
    font-size: 10px;
    font-weight: 400;
    line-height: 8px;
    text-align: center;
    margin: 0;
  }
  .preprompt {
    .inner-prompt {
      width: 260px;
      position: relative;
      height: fit-content;
      min-height: 80px;
      padding: 10px 20px 10px 20px;
      background-color: ${colors.white};
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 20px;
      opacity: 0px;
      cursor: pointer;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.black};
    }
    .shape {
      height: 5px;
      width: 100%;
      border-radius: 10px;
      background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    }
  }
  .prompt-title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.mainGreen};
    }
    svg {
      height: 17px;
      width: 17px;
      margin-right: 5px;
    }
  }
`;
