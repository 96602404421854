import styled, { keyframes } from "styled-components";
import { colors } from "colors";
import LightningRobot from "static/svgs/LightningRobot";

// Keyframes for pulsing animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.08);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Keyframes for fill color animation
const strokeColor = keyframes`
  0% {
    fill: ${colors.mainGreen};
  }
  50% {
    fill: white;
  }
  100% {
    fill: ${colors.mainGreen};
  }
`;


// Define keyframe animation for spinning loader
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled Loading component
export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 1000000;
  background-color: ${colors.loadingBackground};
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    /* background-color: ${colors.black}; */
    padding: 50px;
    border-radius: 6px;
    width: auto;
    max-width: 500px;
    color: ${colors.black}
  }
  .closeout {
    position: absolute;
    right: 15px;
    top: 10px;
    margin: 0;
    width: fit-content;
    cursor: pointer;
    color: ${colors.black};
  }
  p {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  h2 {
    max-width: 460px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
  }
  h4 {
    width: 100%;
    text-align: center;
  }
  .svg-wrapper {
    svg {
      width: 120px;
      height: 120px;
      animation: ${pulse} 2s infinite ease-in-out;
      path {
        animation: ${strokeColor} 2.5s infinite ease-in-out;
        fill: ${colors.mainGreen};
      }
    }
  }
`;

export const Spinner = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid ${colors.mainGreen}; /* Blue */
  border-radius: 100%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite; /* Apply animation */
`;

