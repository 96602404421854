import React from "react";

const Message = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Deal Summary - call log/vuesax/outline/message">
        <g id="vuesax/outline/message">
          <g id="message">
            <path
              id="Vector"
              d="M12.5 22.9643C11.81 22.9643 11.16 22.6143 10.7 22.0043L9.2 20.0043C9.17 19.9643 9.05 19.9143 9 19.9043H8.5C4.33 19.9043 1.75 18.7743 1.75 13.1543V8.1543C1.75 3.7343 4.08 1.4043 8.5 1.4043H16.5C20.92 1.4043 23.25 3.7343 23.25 8.1543V13.1543C23.25 17.5743 20.92 19.9043 16.5 19.9043H16C15.92 19.9043 15.85 19.9443 15.8 20.0043L14.3 22.0043C13.84 22.6143 13.19 22.9643 12.5 22.9643ZM8.5 2.9043C4.92 2.9043 3.25 4.5743 3.25 8.1543V13.1543C3.25 17.6743 4.8 18.4043 8.5 18.4043H9C9.51 18.4043 10.09 18.6943 10.4 19.1043L11.9 21.1043C12.25 21.5643 12.75 21.5643 13.1 21.1043L14.6 19.1043C14.93 18.6643 15.45 18.4043 16 18.4043H16.5C20.08 18.4043 21.75 16.7343 21.75 13.1543V8.1543C21.75 4.5743 20.08 2.9043 16.5 2.9043H8.5Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M12.5 12.1543C11.94 12.1543 11.5 11.7043 11.5 11.1543C11.5 10.6043 11.95 10.1543 12.5 10.1543C13.05 10.1543 13.5 10.6043 13.5 11.1543C13.5 11.7043 13.06 12.1543 12.5 12.1543Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M16.5 12.1543C15.94 12.1543 15.5 11.7043 15.5 11.1543C15.5 10.6043 15.95 10.1543 16.5 10.1543C17.05 10.1543 17.5 10.6043 17.5 11.1543C17.5 11.7043 17.06 12.1543 16.5 12.1543Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M8.5 12.1543C7.94 12.1543 7.5 11.7043 7.5 11.1543C7.5 10.6043 7.95 10.1543 8.5 10.1543C9.05 10.1543 9.5 10.6043 9.5 11.1543C9.5 11.7043 9.06 12.1543 8.5 12.1543Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Message;
