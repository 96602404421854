import React, { FC, useState, useEffect, useRef } from "react"
import { MarketingBlogWrapper } from "./MarketingBlog.styled"
import ArrowsPointLeft from "static/svgs/ArrowsPointLeft"
import { BlogPostListItemWrapper } from "./MarketingBlog.styled"
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled"
import { useNavigate } from "react-router-dom"
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection"
import { BlogPost } from "marketingTypes"

interface MarketingBlogProps {}

const MarketingBlog: FC<MarketingBlogProps> = () => {
  const descriptionTopRef = useRef<HTMLHeadingElement>(null)
  const [topRef, setTopRef] = useState<number | null>(null)
  const [blogPosts, setBlogPosts] = useState<BlogPost[] | []>([])
  const navigate = useNavigate()

  useEffect(() => {
    async function getBlogPosts() {
      try {
        const data = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
          },
          body: JSON.stringify({
            query: `
            {
                allBlogPosts {
                  title
                  id
                  description
                  date
                  blogPostAuthor {
                    authorImage {
                      url
                    }
                    authorName
                  }
                }
              }              
           `,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setBlogPosts(res.data.allBlogPosts)
          })
      } catch (error) {
        console.log(error)
      }
    }
    getBlogPosts()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (descriptionTopRef.current) {
        setTopRef(descriptionTopRef.current.offsetTop)
      }
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  function convertDateToUTC(date: Date) {
    let _date = new Date(date)
    return new Date(
      _date.getUTCFullYear(),
      _date.getUTCMonth(),
      _date.getUTCDate()
    )
  }

  const handleNavigate = (postId: string) => {
    navigate(`/blog/${postId}/`)
  }

  return (
    <MarketingBlogWrapper topRef={topRef}>
      <div className="inner-wrapper">
        <div className="heading-wrapper">
          <div className="heading-left">
            <h1>
              Leadoff <span>Blog</span>
            </h1>
            <h4 ref={descriptionTopRef}>
              a treasure trove of insights on enhancing your sales strategy with
              AI. Here, we share cutting-edge tips, success stories, and expert
              advice to turbocharge your sales performance. Whether you're new
              to AI sales coaching or looking to refine your techniques, our
              blog is your go-to resource for all things sales and technology.
            </h4>
          </div>
          <div className="heading-right">
            <ArrowsPointLeft />
          </div>
        </div>
        <hr />
        <div className="blog-posts-wrapper">
          {blogPosts &&
            blogPosts.map((post) => {
              const date = convertDateToUTC(post.date)
              return (
                <BlogPostListItemWrapper key={post.id}>
                  <h4 className="title">{post.title}</h4>
                  <p className="date">
                    {date?.toLocaleDateString("default", { month: "long" })}{" "}
                    {date?.getDate()}, {date?.getFullYear()}{" "}
                  </p>
                  <p className="description">
                    {post.description.length > 225
                      ? post.description.substring(0, 225 - 3) + "..."
                      : post.description}
                  </p>
                  <div className="author-wrapper">
                    <p className="author">Author</p>
                    <div>
                      <div className="image-wrapper">
                        <img
                          className="author-image"
                          src={post.blogPostAuthor?.authorImage?.url}
                        />
                      </div>
                      <p className="author-name">
                        {post.blogPostAuthor?.authorName}
                      </p>
                    </div>
                  </div>
                  <MarketingGreenButtonWrapper
                    onClick={() => handleNavigate(post.id)}
                    width={150}
                  >
                    Read More
                  </MarketingGreenButtonWrapper>
                </BlogPostListItemWrapper>
              )
            })}
        </div>
        <StepUpSection />
      </div>
    </MarketingBlogWrapper>
  )
}

export default MarketingBlog
