import React, { FC } from "react";
import { UserTableWrapper } from "./UserTable.styled";
import { User } from "types";
import { useDrop } from "react-dnd";
import { UserRowWrapper } from "components/Settings/Settings.styled";
import UserRow from "components/UserRow/UserRow";
import Trash from "static/svgs/Trash";

interface UserTableProps {
  user: User;
  users: User[];
  teamId: string;
  onMoveUser: (userId: string, newTeamId: string) => void;
  teamDelete: (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    teamId: string
  ) => void;
}

const UserTable: FC<UserTableProps> = ({
  teamId,
  user,
  users,
  onMoveUser,
  teamDelete,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "USER",
    drop: (item: {
      user: { user_id: string; name: string };
      team_id: string;
    }) => {
      if (item.team_id !== teamId) {
        console.log("item: ", item);
        onMoveUser(item.user.user_id, teamId);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  return (
    <UserTableWrapper
      className="user-table"
      ref={drop}
      style={{ border: isOver ? "2px solid green" : "" }}
    >
      <UserRowWrapper header={true} className="edit">
        <div className="profile-icon"></div>
        <p className="name">Name</p>
        <p className="role">Role</p>
        <p className="email">Email</p>
        <p className="active">Status</p>
        {teamId && (
          <p className="trash" onClick={(e) => teamDelete(e, teamId)}>
            <Trash />
          </p>
        )}
      </UserRowWrapper>
      <hr />
      {users &&
        users.map((teamUser: User, index: number) => (
          <UserRow
            teamId={teamId}
            key={index}
            user={teamUser}
            isCurrentUser={user.email === teamUser.email}
          />
        ))}
    </UserTableWrapper>
  );
};

export default UserTable;
